
export default [
	["Внешние малые острова США", ""],
	["Пуэрто-Рико", "1"],
	["Канада", "1"],
	["США", "1"],
	["Багамские Острова", "1-242"],
	["Барбадос", "1-246"],
	["Ангилья", "1-264"],
	["Антигуа и Барбуда", "1-268"],
	["Виргинские Острова (Великобритания)", "1-284"],
	["Виргинские Острова (США)", "1-340"],
	["Острова Кайман", "1-345"],
	["Бермуды", "1-441"],
	["Гренада", "1-473"],
	["Теркс и Кайкос", "1-649"],
	["Монтсеррат", "1-664"],
	["Северные Марианские Острова", "1-670"],
	["Гуам", "1-671"],
	["Американское Самоа", "1-684"],
	["Синт-Мартен", "1-721"],
	["Сент-Люсия", "1-758"],
	["Доминика", "1-767"],
	["Сент-Винсент и Гренадины", "1-784"],
	["Доминиканская Республика", "1-809"],
	["Тринидад и Тобаго", "1-868"],
	["Сент-Китс и Невис", "1-869"],
	["Ямайка", "1-876"],
	["Египет", "20"],
	["Южный Судан", "211"],
	["Марокко", "212"],
	["САДР", "212"],
	["Алжир", "213"],
	["Тунис", "216"],
	["Ливия", "218"],
	["Гамбия", "220"],
	["Сенегал", "221"],
	["Мавритания", "222"],
	["Мали", "223"],
	["Гвинея", "224"],
	["Кот-д’Ивуар", "225"],
	["Буркина-Фасо", "226"],
	["Нигер", "227"],
	["Того", "228"],
	["Бенин", "229"],
	["Маврикий", "230"],
	["Либерия", "231"],
	["Сьерра-Леоне", "232"],
	["Гана", "233"],
	["Нигерия", "234"],
	["Чад", "235"],
	["ЦАР", "236"],
	["Камерун", "237"],
	["Кабо-Верде", "238"],
	["Сан-Томе и Принсипи", "239"],
	["Экваториальная Гвинея", "240"],
	["Габон", "241"],
	["Республика Конго", "242"],
	["ДР Конго", "243"],
	["Ангола", "244"],
	["Гвинея-Бисау", "245"],
	["Британская Территория в Индийском Океане", "246"],
	["Сейшельские Острова", "248"],
	["Судан", "249"],
	["Руанда", "250"],
	["Эфиопия", "251"],
	["Сомали", "252"],
	["Джибути", "253"],
	["Кения", "254"],
	["Танзания", "255"],
	["Уганда", "256"],
	["Бурунди", "257"],
	["Мозамбик", "258"],
	["Замбия", "260"],
	["Мадагаскар", "261"],
	["Французские Южные и Антарктические территории", "262"],
	["Реюньон", "262"],
	["Майотта", "262"],
	["Зимбабве", "263"],
	["Намибия", "264"],
	["Малави", "265"],
	["Лесото", "266"],
	["Ботсвана", "267"],
	["Эсватини", "268"],
	["Коморы", "269"],
	["ЮАР", "27"],
	["Остров Святой Елены", "290n"],
	["Эритрея", "291"],
	["Аруба", "297"],
	["Фарерские острова", "298"],
	["Гренландия", "299"],
	["Греция", "30"],
	["Нидерланды", "31"],
	["Бельгия", "32"],
	["Франция", "33"],
	["Испания", "34"],
	["Гибралтар", "350"],
	["Португалия", "351"],
	["Люксембург", "352"],
	["Ирландия", "353"],
	["Исландия", "354"],
	["Албания", "355"],
	["Мальта", "356"],
	["Кипр", "357"],
	["Аландские острова", "358"],
	["Финляндия", "358"],
	["Болгария", "359"],
	["Венгрия", "36"],
	["Литва", "370"],
	["Латвия", "371"],
	["Эстония", "372"],
	["Молдавия", "373"],
	["Армения", "374"],
	["Белоруссия", "375"],
	["Андорра", "376"],
	["Монако", "377"],
	["Сан-Марино", "378"],
	["Украина", "380"],
	["Сербия", "381p"],
	["Черногория", "382"],
	["Хорватия", "385"],
	["Словения", "386"],
	["Босния и Герцеговина", "387"],
	["Северная Македония", "389"],
	["Италия", "39"],
	["Ватикан", "39-06"],
	["Румыния", "40"],
	["Швейцария", "41"],
	["Чехия", "420"],
	["Словакия", "421"],
	["Лихтенштейн", "423"],
	["Австрия", "43"],
	["Гернси", "44"],
	["Остров Мэн", "44"],
	["Великобритания", "44"],
	["Джерси", "44"],
	["Дания", "45"],
	["Швеция", "46"],
	["Остров Буве", "47"],
	["Шпицберген и Ян-Майен", "47"],
	["Норвегия", "47"],
	["Польша", "48"],
	["Германия", "49"],
	["Фолклендские острова", "500"],
	["Южная Георгия и Южные Сандвичевы Острова", "500"],
	["Белиз", "501"],
	["Гватемала", "502"],
	["Сальвадор", "503"],
	["Гондурас", "504"],
	["Никарагуа", "505"],
	["Коста-Рика", "506"],
	["Панама", "507"],
	["Сен-Пьер и Микелон", "508"],
	["Гаити", "509"],
	["Перу", "51"],
	["Мексика", "52"],
	["Куба", "53"],
	["Аргентина", "54"],
	["Бразилия", "55"],
	["Чили", "56"],
	["Колумбия", "57"],
	["Венесуэла", "58"],
	["Сен-Мартен", "590"],
	["Гваделупа", "590"],
	["Сен-Бартелеми", "590"],
	["Боливия", "591"],
	["Гайана", "592"],
	["Эквадор", "593"],
	["Гвиана", "594"],
	["Парагвай", "595"],
	["Мартиника", "596"],
	["Суринам", "597"],
	["Уругвай", "598"],
	["Бонайре, Синт-Эстатиус и Саба", "599"],
	["Кюрасао", "599"],
	["Малайзия", "60"],
	["Австралия", "61"],
	["Кокосовые острова", "61"],
	["Остров Рождества", "61"],
	["Индонезия", "62"],
	["Филиппины", "63"],
	["Новая Зеландия", "64"],
	["Сингапур", "65"],
	["Таиланд", "66"],
	["Восточный Тимор", "670"],
	["Херд и Макдональд", "672"],
	["Антарктика", "672"],
	["Остров Норфолк", "672"],
	["Бруней", "673"],
	["Науру", "674"],
	["Папуа — Новая Гвинея", "675"],
	["Тонга", "676"],
	["Соломоновы Острова", "677"],
	["Вануату", "678"],
	["Фиджи", "679"],
	["Палау", "680"],
	["Уоллис и Футуна", "681"],
	["Острова Кука", "682"],
	["Ниуэ", "683"],
	["Самоа", "685"],
	["Кирибати", "686"],
	["Новая Каледония", "687"],
	["Тувалу", "688"],
	["Французская Полинезия", "689"],
	["Токелау", "690"],
	["Микронезия", "691"],
	["Маршалловы Острова", "692"],
	["Россия", "7"],
	["Казахстан", "7"],
	["Япония", "81"],
	["Республика Корея", "82"],
	["Вьетнам", "84"],
	["КНДР (Корейская Народно-Демократическая Республика)", "850"],
	["Гонконг", "852"],
	["Макао", "853"],
	["Камбоджа", "855"],
	["Лаос", "856"],
	["Китай (Китайская Народная Республика)", "86"],
	["Острова Питкэрн", "870"],
	["Бангладеш", "880"],
	["Китайская Республика", "886"],
	["Турция", "90"],
	["Индия", "91"],
	["Пакистан", "92"],
	["Афганистан", "93"],
	["Шри-Ланка", "94"],
	["Мьянма", "95"],
	["Мальдивы", "960"],
	["Ливан", "961"],
	["Иордания", "962"],
	["Сирия", "963"],
	["Ирак", "964"],
	["Кувейт", "965"],
	["Саудовская Аравия", "966"],
	["Йемен", "967"],
	["Оман", "968"],
	["Государство Палестина", "970"],
	["ОАЭ", "971"],
	["Израиль", "972"],
	["Бахрейн", "973"],
	["Катар", "974"],
	["Бутан", "975"],
	["Монголия", "976"],
	["Непал", "977"],
	["Иран", "98"],
	["Таджикистан", "992"],
	["Туркменистан", "993"],
	["Азербайджан", "994"],
	["Грузия", "995"],
	["Кыргызстан", "996"],
	["Узбекистан", "998"],

]
