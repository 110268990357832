import styles from "./P3.module.scss"

// import BackgroundImage from './assets/images/p3.png'
import Image1 from './assets/images/p3-1-h.png'
import Image2 from './assets/images/p3-2-h.png'

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../swiper.css'
// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import { useContext, useEffect, useRef, useState } from "react";
import SwiperCore, { Swiper as SwiperType } from 'swiper';
import { LanguageContext } from "../../context/useLanguageContext";
import { HomepageContext } from "../homepage/Homepage";

export default () => {

    const {translate: t} = useContext(LanguageContext)
    const swiperRef = useRef(null)
    const {step} = useContext(HomepageContext)

    const [swiper, setSwiper] = useState<SwiperType|null>(null);

    const handleSwiper = (swiperInstance: any) => {
        setSwiper(swiperInstance);
    };

    useEffect(() => {
        if (step === 2) {
            //console.log('p3开始播放')
            swiper?.autoplay.start()
        } else {
            console.log('p3停止轮播')
            swiper?.autoplay.stop()
        }
    }, [step])
    let list = [
        {img: Image1, text: t("In Their Eyes, Worlds Unfold")},
        {img: Image2, text: t("In Their Eyes, Worlds Unfold")},
    ]
    return (
        <div data-step="3" className={styles['p3']}>
            <Swiper ref={swiperRef} pagination loop modules={[Pagination, Autoplay]}
            onSwiper={handleSwiper}
            >
            {
                list.map((item, idx) => {
                    return (
                        <SwiperSlide key={idx}>
                            <div className={styles['p3-container']} key={idx}>
                            <img src={item.img} className={styles['p3-container-img']}/>
                            <div className={styles['p3-container-content']}>
                                <span className={styles['p3-container-content-text']}>{item.text}</span>
                            </div>
                        </div>
                        </SwiperSlide>
                    )
                })
            }
            </Swiper>
        </div>
    )
}


