import styles from "./Detail.module.scss"

import Header from "../../header/Header"
import Footer from "../../footer/Footer"

import { useParams } from "react-router-dom"
import { useContext, useEffect, useState } from "react"

import Background from './assets/images/Background.png'
import Sunglasses from './assets/images/Sunglasses.png'
import Hat from './assets/images/Hat.png'
import Detail_1 from './assets/images/Detail-1.png'
import PurpleShellEarphone from './assets/images/PurpleShellEarphone.png'
import GoldShellEarrings from './assets/images/GoldShellEarrings.png'
import PinkLadyNecklace from './assets/images/PinkLadyNecklace.png'
import MacaronBag from './assets/images/MacaronBag.png'
import { LanguageContext } from "../../../context/useLanguageContext"

export default () => {

    const {translate: t} = useContext(LanguageContext)

    const [activate, setActivate] = useState<string>('All')
    let types = ['All', 'Sunglasses', 'Hat', 'Necklace', 'Earrings', 'Bag']

    const navClick = (type: string) => {
        setActivate(type)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getStyle = () => {
        return {
            marginTop: document.getElementById('header')?.offsetHeight
        }
    }

    useEffect(() => {
        let headerHeight = document.getElementById('header')?.clientHeight??0
        let nav = document.getElementById('nav')
        nav?.style.setProperty('top', headerHeight + 'px')
    }, [])

    return (
        <div>
            <Header white goback/>
            <img id="backgroundImg" className={styles['bg']} src={Background} style={getStyle()}/>
            <div id="nav" className={styles['nav']}>
            {
                types.map((item, idx) => {
                    return (
                        <div className={[styles['nav-item'], activate === item?styles['nav-activate']:''].join(" ")} key={idx} onClick={() => navClick(item)}>
                            <span className={styles['nav-item-text']}>{t(item)}</span>
                        </div>
                    )
                })
            }
            </div>
            <div className={styles['detail']}>
                {activate === 'All' && <DetailAll/>}
                {activate === 'Sunglasses' && <DetailSunglasses/>}
                {activate === 'Hat' && <DetailHat/>}
                {activate === 'Necklace' && <DetailNecklace/>}
                {activate === 'Earrings' && <DetailEarrings/>}
                {activate === 'Bag' && <DetailBag/>}
            </div>
            <Footer/>
        </div>
    )
}

export const DetailAll = () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['all']}>
            <div className={styles['all-1']}>
                <div className={styles['all-1-left']}>
                    <img src={Sunglasses} className={styles['all-1-left-img']}/>
                    <span className={styles['all-1-left-text']}>{t('Colored Sunglasses')}</span>
                </div>
                <div className={styles['all-1-right']}>
                    <img src={Hat} className={styles['all-1-right-img']}/>
                    <span className={styles['all-1-right-text']}>{t('Big-ear Hat')}</span>
                </div>
            </div>
            <div className={styles['all-2']}>
                <img src={Detail_1}/>
            </div>
            <div className={styles['all-3']}>
                <div className={styles['all-3-left']}>
                    <div className={styles['all-3-left-top']}>
                        <img src={PurpleShellEarphone} className={styles['all-3-left-top-img']}/>
                        <span className={styles['all-3-left-top-text']}>{t('Purple Shell Earphone')}</span>
                    </div>
                    <div className={styles['all-3-left-bottom']}>
                        <img src={GoldShellEarrings} className={styles['all-3-left-bottom-img']}/>
                        <span className={styles['all-3-left-bottom-text']}>{t('Gold Shell Earrings')}</span>
                    </div>
                </div>
                <div className={styles['all-3-right']}>
                    <div className={styles['all-3-right-top']}>
                        <img src={PinkLadyNecklace} className={styles['all-3-right-top-img']}/>
                        <span className={styles['all-3-right-top-text']}>{t('Pink Lady Necklace')}</span>
                    </div>
                    <div className={styles['all-3-right-bottom']}>
                        <img src={MacaronBag} className={styles['all-3-right-bottom-img']}/>
                        <span className={styles['all-3-right-bottom-text']}>{t('Macaron Bag')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const DetailSunglasses = () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['item']}>
            <div className={styles['item-1']}>
                <img src={Sunglasses} className={styles['item-1-img']}/>
                <span className={styles['item-1-text']}>{t('Colored Sunglasses')}</span>
            </div>
        </div>
    )
}

export const DetailHat = () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['item']}>
            <div className={styles['item-1']}>
                <img src={Hat} className={styles['item-1-img']}/>
                <span className={styles['item-1-text']}>{t('Big-ear Hat')}</span>
            </div>
        </div>
    )
}

export const DetailNecklace = () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['item']}>
            <div className={styles['item-1']}>
                <img src={PinkLadyNecklace} className={styles['item-1-img']}/>
                <span className={styles['item-1-text']}>{t('Pink Lady Necklace')}</span>
            </div>
        </div>
    )
}

export const DetailEarrings = () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['item']}>
            <div className={styles['item-1']}>
                <img src={PurpleShellEarphone} className={styles['item-1-img']}/>
                <span className={styles['item-1-text']}>{t('Purple Shell Earphone')}</span>
            </div>
            <div className={styles['item-2']}>
                <img src={GoldShellEarrings} className={styles['item-2-img']}/>
                <span className={styles['item-2-text']}>{t('Gold Shell Earrings')}</span>
            </div>
        </div>
    )
}

export const DetailBag = () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['item']}>
            <div className={styles['item-1']}>
                <img src={MacaronBag} className={styles['item-1-img']}/>
                <span className={styles['item-1-text']}>{t('Macaron Bag')}</span>
            </div>
        </div>
    )
}


