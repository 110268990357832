import { Http2ServerResponse } from "http2"

export type PaganitionRequest<T> = {
    page: number
    pageSize: number
    sortField?: string
    reverse?: boolean
} & T

export interface HttpResponse<T = any>{
    state?: string
    msg?: string
    data: T
}

export interface PaganitionResponse<T = any>{
    total: number
    data: T[]
}

const checkState = (res: HttpResponse) => {
    if(res.state !== "000000"){
        throw new Error(res.msg??res.state)
    }
}

export default {
    checkState
}

