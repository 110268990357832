
export default {
	"CATLY | Official Website": "CATLY｜公式ウェブサイト",
	"description": "美しく気高いネコたちでペットの概念が変わる。冒険、製作、ファッション、遊び、雪、スピード、ロボットの要素を詰め込んだ超リアルな旅。そのすべてをネコたちと共に。",
	"Email Address / Phone Number": "メールアドレス / 電話番号",
	"Register Now": "今すぐ登録",
	"English": "English",
	"简体中文": "简体中文",
	"繁體中文": "繁體中文",
	"日本語": "日本語",
	"한국어": "한국어",
	"Русский": "Русский",
	"Español": "Español",
	"Português": "Português",
	"Français": "Français",
	"Deutsch": "Deutsch",
	"Italiano": "Italiano",
	"Registered Successfully": "登録が完了しました！",
	"Confident": "パワー",
	"Strides": "ストライド",
	"Brave": "エレガンス",
	"and Boundless": "グレース",
	"Elegant": "トイ",
	"Nimble": "ディライト",
	"Temperamental": "テンパメンタル",
	"by Nature": "シック",
	"Fun and Lively": "プチプラッシュ",
	"Confident Strides": "パワーストライド",
	"Brave Sprit": "エレガンスグレース",
	"Elegant Nimble": "トイディライト",
	"Temperamental Chic": "テンパメンタルシック",
	"Fun and Lively detail": "プチプラッシュ",
	"In Their Eyes, Worlds Unfold": "ネコの目には世界が広がる",
	"Meta Essentials": "シック",
	"Discover All Accessories": "すべてチェック",
	"Sunglasses": "サングラス",
	"Hat": "帽子",
	"Earrings": "イヤリング",
	"Necklace": "ネックレス",
	"Bag": "バッグ",
	"Products Accessories": "商品",
	"All": "すべて",
	"Sunglasses detail": "サングラス",
	"Hat detail": "帽子",
	"Necklace detail": "イヤリング",
	"Earrings detail": "ネックレス",
	"Bag detail": "バッグ",
	"Colored Sunglasses": "カラーサングラス",
	"Big-ear Hat": "ビッグイヤーハット",
	"Gold Sunglasses": "ゴールドサングラス",
	"Gold Shell Earrings": "ゴールドシェルイヤリング",
	"Macaron Bag": "マカロンバッグ",
	"Purple Shell Earphone": "パープルシェルイヤホン",
	"Pink Lady Necklace": "ピンクレディネックレス",
	"A Canvas of Stories, Painted in Fur": "毛皮で描かれた物語のキャンバス",
	"A world of whispers, wonder, and infinite grace.": "ささやき、驚き、そして無限の優しさに満ちたネコの世界。",
	"Style Collection": "スタイルコレクション",
	"Discover All fashion": "すべてチェック",
	"Blossom": "ブロッサム",
	"Winterwarrior": "ウィンターウォーリアー",
	"Snowshield": "スノーシールド",
	"Macaron": "マカロン",
	"Combat": "コンバット",
	"Products": "商品",
	"Season 1": "シーズン 1",
	"Blossom Coat": "ブロッサムコート",
	"Winterwarrior Suit": "ウィンターウォーリアースーツ",
	"Snowshield Suit": "スノーシールドスーツ",
	"Blossom Tops": "ブロッサムトップス",
	"Macaron Coat": "マカロンコート",
	"Combat Coat": "コンバットコート",
	"Adventure Sports Suit": "アドベンチャースポーツスーツ",
	"Futuristic Jacket": "フューチャリスティックジャケット",
	"Born of Moonlight, Bound by Mystery.": "月光に生まれ、謎に縛られる。",
	"Music by Akira Senju": "楽曲：千住明",
	"Privacy Policy": "プライバシーポリシー",
	"NEWSLETTER": "ニュースレター",
	"Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.": "「Catly」のメールマガジンにご登録いただくと、オンライン限定先行発売や新作コレクションなどの最新情報をお届けします。",
	"FOLLOW US": "フォローする",
	"Email address": "メールアドレス",
	"OK": "OK",
	"Followed Successfully": "フォローが完了しました",
	"Power click": "パワー",
	"Elegance click": "エレガンス",
	"Toy click": "トイ",
	"Temperamental click": "テンパメンタル",
	"Petite click": "プチプラッシュ",
	"null": "電話番号またはメールアドレスは空にできません",
	"The Game Awards 2024": "The Game Awards 2024",
	"Showcase Game": "発表作品",

}
