import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

//移动端
import MHomepage from './mobile-pages/homepage/Homepage';
import MP2Detail from './mobile-pages/p2/detail/Detail'
import MP4Detail from './mobile-pages/p4/detail/Detail'
import MP7Detail from './mobile-pages/p7/detail/Detail'
import MPrivacyPolicy from './mobile-pages/privacy-policy/PrivacyPolicy';
import useLanguageContext, { LanguageContext } from './context/useLanguageContext';
import useBgmContext, { BgmContext, BmgPlayer } from './context/useBgmContext';

function App() {

  const languageContext = useLanguageContext()
  const bgmContext = useBgmContext()
  const router = createBrowserRouter([

    {
      path: "/",
      element: <MHomepage />,
    },

    {
      path: "/p2/detail/:id",
      element: <MP2Detail />,
    },

    {
      path: "/p4/detail",
      element: <MP4Detail />,
    },

    {
      path: "/p7/detail",
      element: <MP7Detail />,
    },

    {
      path: "/privacyPolicy",
      element: <MPrivacyPolicy />,
    },
  ]);

  

  return (
    <BgmContext.Provider value={{...bgmContext}}>
      <BmgPlayer/>
      <LanguageContext.Provider value={{...languageContext}}>
        <RouterProvider router={router} />
      </LanguageContext.Provider>
    </BgmContext.Provider>
  );
}

export default App;
