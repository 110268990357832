
export default [
	["阿爾巴尼亞", "355"],
	["阿爾及利亞", "213"],
	["阿富汗", "93"],
	["阿根廷", "54"],
	["愛爾蘭共和國", "353"],
	["埃及", "20"],
	["埃塞俄比亞", "251"],
	["愛沙尼亞", "372"],
	["阿拉伯聯合酋長國", "971"],
	["阿魯巴", "297"],
	["阿曼", "968"],
	["安道爾", "376"],
	["安哥拉", "244"],
	["安圭拉", "1-264"],
	["安提瓜巴布達", "1-268"],
	["奧地利", "43"],
	["奧蘭群島", "358"],
	["澳門", "853"],
	["澳洲", "61"],
	["阿塞拜疆", "994"],
	["巴巴多斯", "1-246"],
	["巴布亞新畿內亞", "675"],
	["巴哈馬", "1-242"],
	["白俄羅斯", "375"],
	["百慕達", "1-441"],
	["巴基斯坦", "92"],
	["巴拉圭", "595"],
	["巴勒斯坦國", "970"],
	["巴林", "973"],
	["巴拿馬", "507"],
	["保加利亞", "359"],
	["鮑威特島", "47"],
	["巴西", "55"],
	["北韓", "850"],
	["北馬利安納羣島", "1-670"],
	["北馬其頓", "389"],
	["貝寧", "229"],
	["比利時", "32"],
	["冰島", "354"],
	["波茨華拿", "267"],
	["波多黎各", "1"],
	["波蘭", "48"],
	["帛琉", "680"],
	["玻利維亞", "591"],
	["伯利茲", "501"],
	["波斯尼亞", "387"],
	["不丹", "975"],
	["布基納法索", "226"],
	["布隆迪", "257"],
	["赤道畿內亞", "240"],
	["丹麥", "45"],
	["德國", "49"],
	["東帝汶", "670"],
	["多哥", "228"],
	["多明尼加", "1-809"],
	["多米尼克", "1-767"],
	["厄瓜多爾", "593"],
	["厄立特里亞", "291"],
	["俄羅斯", "7"],
	["法國", "33"],
	["法羅群島", "298"],
	["梵蒂岡", "39-06"],
	["法屬波利尼西亞", "689"],
	["法屬圭亞那", "594"],
	["法屬南部同南極領地", "262"],
	["法屬聖馬丁", "590"],
	["斐濟", "679"],
	["菲律賓", "63"],
	["芬蘭", "358"],
	["福克蘭羣島", "500"],
	["甘比亞", "220"],
	["剛果", "242"],
	["剛果民主共和國", "243"],
	["格陵蘭", "299"],
	["格林納達", "1-473"],
	["格魯吉亞", "995"],
	["哥倫比亞", "57"],
	["根息島", "44"],
	["哥斯達黎加", "506"],
	["瓜德羅普", "590"],
	["關島", "1-671"],
	["古巴", "53"],
	["圭亞那", "592"],
	["海地", "509"],
	["韓國", "82"],
	["哈薩克", "7"],
	["黑山", "382"],
	["荷蘭", "31"],
	["荷屬聖馬丁", "1-721"],
	["洪都拉斯", "504"],
	["加勒比尼德蘭", "599"],
	["加納", "233"],
	["加拿大", "1"],
	["柬埔寨", "855"],
	["加蓬", "241"],
	["吉布提", "253"],
	["捷克", "420"],
	["吉爾吉斯", "996"],
	["基里巴斯", "686"],
	["津巴布韋", "263"],
	["畿內亞", "224"],
	["畿內亞比紹", "245"],
	["開曼羣島", "1-345"],
	["喀麥隆", "237"],
	["卡塔爾", "974"],
	["克島同麥當奴群島", "672"],
	["可可斯（基林）群島", "61"],
	["克羅地亞", "385"],
	["科摩羅", "269"],
	["肯雅", "254"],
	["科威特", "965"],
	["庫拉索", "599"],
	["萊索托", "266"],
	["老撾", "856"],
	["拉脫維亞", "371"],
	["冷岸群島同揚馬延島", "47"],
	["黎巴嫩", "961"],
	["利比利亞", "231"],
	["利比亞", "218"],
	["列支敦士登", "423"],
	["立陶宛", "370"],
	["留尼汪", "262"],
	["羅馬尼亞", "40"],
	["盧森堡", "352"],
	["盧旺達", "250"],
	["馬達加斯加", "261"],
	["馬爾代夫", "960"],
	["馬爾他", "356"],
	["馬來西亞", "60"],
	["馬拉維", "265"],
	["馬里", "223"],
	["毛厘士", "230"],
	["毛里塔尼亞", "222"],
	["馬紹爾群島", "692"],
	["馬提尼克", "596"],
	["馬約特", "262"],
	["美國", "1"],
	["美國邊遠島嶼", ""],
	["美屬處女羣島", "1-340"],
	["美屬薩摩亞", "1-684"],
	["萌島", "44"],
	["蒙古國", "976"],
	["孟加拉國", "880"],
	["蒙塞拉特島", "1-664"],
	["緬甸", "95"],
	["密克羅尼西亞", "691"],
	["秘魯", "51"],
	["摩爾多瓦", "373"],
	["摩洛哥", "212"],
	["摩納哥", "377"],
	["莫三鼻給", "258"],
	["墨西哥", "52"],
	["納米比亞", "264"],
	["南非", "27"],
	["南極洲", "672"],
	["南蘇丹", "211"],
	["南佐治亞與南三文治群島", "500"],
	["瑙魯", "674"],
	["尼泊爾", "977"],
	["尼加拉瓜", "505"],
	["尼日爾", "227"],
	["尼日利亞", "234"],
	["紐埃", "683"],
	["紐西蘭", "64"],
	["諾福島", "672"],
	["挪威", "47"],
	["皮卡恩群島", "870"],
	["葡萄牙", "351"],
	["千里達多巴哥", "1-868"],
	["曲克群島", "682"],
	["日本", "81"],
	["瑞典", "46"],
	["瑞士", "41"],
	["薩爾瓦多", "503"],
	["塞爾維亞", "381p"],
	["塞拉利昂", "232"],
	["塞內加爾", "221"],
	["塞浦路斯", "357"],
	["塞舌爾", "248"],
	["薩摩亞", "685"],
	["沙地阿拉伯", "966"],
	["聖巴多祿繆島", "590"],
	["聖誕島", "61"],
	["聖多美普林西比", "239"],
	["聖海倫娜島", "290n"],
	["聖傑氏尼維斯", "1-869"],
	["聖盧西亞", "1-758"],
	["聖馬連奴", "378"],
	["聖皮埃爾及密克隆群島", "508"],
	["聖文森", "1-784"],
	["斯里蘭卡", "94"],
	["斯洛伐克", "421"],
	["斯洛文尼亞", "386"],
	["斯威士蘭", "268"],
	["蘇丹", "249"],
	["蘇里南", "597"],
	["所羅門群島", "677"],
	["索馬里", "252"],
	["泰國", "66"],
	["臺灣", "886"],
	["塔吉克", "992"],
	["湯加", "676"],
	["坦桑尼亞", "255"],
	["特克斯與凱科斯群島", "1-649"],
	["土耳其", "90"],
	["土庫曼", "993"],
	["突尼西亞", "216"],
	["托克勞", "690"],
	["圖瓦盧", "688"],
	["瓦利斯及富圖納群島", "681"],
	["維德角", "238"],
	["危地馬拉", "502"],
	["委內瑞拉", "58"],
	["汶萊", "673"],
	["溫納圖", "678"],
	["烏干達", "256"],
	["烏克蘭", "380"],
	["烏拉圭", "598"],
	["烏茲別克", "998"],
	["香港", "852"],
	["象牙海岸", "225"],
	["西班牙", "34"],
	["希臘", "30"],
	["星加坡", "65"],
	["新喀里多尼亞", "687"],
	["匈牙利", "36"],
	["西撒哈拉", "212"],
	["敘利亞", "963"],
	["牙買加", "1-876"],
	["亞美尼亞", "374"],
	["也門", "967"],
	["意大利", "39"],
	["伊拉克", "964"],
	["伊朗", "98"],
	["印度", "91"],
	["英國", "44"],
	["英屬處女羣島", "1-284"],
	["英屬印度洋領地", "246"],
	["印尼", "62"],
	["以色列", "972"],
	["約旦", "962"],
	["越南", "84"],
	["贊比亞", "260"],
	["澤西島", "44"],
	["乍得", "235"],
	["直布羅陀", "350"],
	["智利", "56"],
	["中非", "236"],
	["中國", "86"],

]
