import { useContext } from "react"
import styles from "./P3.module.scss"
import { LanguageContext } from "../../context/useLanguageContext"

export default () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['p3']}>
            {/* <div className={styles['p3-img']}>
            <div className={styles['p3-img-left']}></div>
            <div className={styles['p3-img-right']}></div>
            </div> */}
            <div className={styles['p3__content__img__l']}></div>
            <div className={styles['p3__content__img__r']}></div>
            <div className={styles['p3__content__label']}>{t("In Their Eyes, Worlds Unfold")}</div>
            {/* <div className={styles['p3__content-wrapper']}>
                <div className={styles['p3__content__img__l']}></div>
                <div className={styles['p3__content__img__r']}></div>
                <div className={styles['p3__content__label']}>{t("In Their Eyes, Worlds Unfold")}</div>
            </div> */}
        </div>
    )
}


