import { createContext, useContext, useEffect, useState } from "react"
import Header from "../header/Header"
import P1 from "../p1/P1"
import P2 from "../p2/P2"
import Trailer from "../trailer/Trailer"
import P3 from "../p3/P3"
import P4 from "../p4/P4"
import P5 from "../p5/P5"
import P6 from "../p6/P6"
import P7 from "../p7/P7"
import P8 from "../p8/P8"
import Footer from "../footer/Footer"
import styles from "./Homepage.module.scss"
import { BgmContext } from "../../context/useBgmContext"

import maidian, {MaidianEventType} from "../../api/maidian"

interface HomepageContextDTO{
    step: number
    white?: boolean //强制白底
    showHeader?: boolean //
    setStep: (step: number) => void
    checkNewUser: () => void
    registered: boolean
    setRegistered: (registry: boolean) => void
  }
  interface PageDTO{
    idx: number //从1开始
    height: number
  }
  export const HomepageContext = createContext<HomepageContextDTO>({
    step: 0, white: false, showHeader: false,
    setStep: function (step: number): void {
      throw new Error("Function not implemented.")
    },
    checkNewUser: function (): void {
      throw new Error("Function not implemented.")
    },
    registered: false,
    setRegistered: function (registry: boolean): void {
      throw new Error("Function not implemented.")
    },
  })

  const getRegistered = () => {
    return parseInt(localStorage.getItem("user_registered")??'0') > 0
  }
  
  const saveRegistered = () => {
    localStorage.setItem("user_registered", '1')
  }
  
  const getNewUser = () => {
    return parseInt(localStorage.getItem("user_initial")??'0') > 0
  }
  
  const saveNewUser = () => {
    localStorage.setItem("user_initial", '1')
  }

export interface IndexPagesHeight {
  idx: number
  min: number
  max: number
}

export default () => {

  const {
    maidianOpen,
    maidianClose,
    maidianEventSend,
  } = maidian
    
    let [step, setStep] = useState<number>(0)
    let [showHeader, setShowHeader] = useState<boolean>()
    const {playing, play, pause, triggerPlay} = useContext(BgmContext)
    let [registered, setRegistered] = useState<boolean>(() => getRegistered())
    const [pagesHeight, setPagesHeight] = useState<IndexPagesHeight[]>([])

    const checkNewUser = () => {
      const initial = getNewUser()
      if(!initial){
        //初始化的用户，打开播放器
        saveNewUser()
      }
      triggerPlay()
    }

    const _setRegistered = () => {
      setRegistered(true)
      saveRegistered()
    }

    useEffect(() => {
      setTimeout(() => {
        setShowHeader(true)
      }, 3000);
    }, [])

    useEffect(() => {
      sessionStorage.setItem('step', step + "")
    }, [step])

    useEffect(() => {
      maidianOpen()
    }, [])

    useEffect(() => {
      var observer = new IntersectionObserver(function(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
              let dataStep = entry.target.getAttribute("data-step")
              if (dataStep) {
                setStep(parseInt(dataStep)-1)
              }
              console.log("进入页面", dataStep);
              // 可选：一旦元素进入视口，停止观察
              //observer.unobserve(entry.target);
            }
        });
      }, { threshold: [0.9, 1] });
    
      var children = document.getElementById('index')?.children??[] as any[]
      for (let i = 1; i < children.length-1; i ++) {
        if (children[i]) {
          observer.observe(children[i]);
        }
      }
    }, [])

    useEffect(() => {
      maidianEventSend(MaidianEventType.ENTER_PAGE, step)
    }, [step])

    const handleBeforeUnload = (event: any) => {
      maidianClose()
    }
  
    useEffect(() => {
      // 添加事件监听器
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      // 返回一个清除函数，在组件卸载时移除事件监听器
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, []);

    return (
        <HomepageContext.Provider value={{step, showHeader, setStep, checkNewUser, registered, setRegistered: _setRegistered}}>
        <div id="index" className={styles['homepage']}>
            <Header white/>
            <P1/>
            {/* <P2 step={step}/> */}
            <Trailer/>
            <P3/>
            <P4/>
            <P5/>
            {/* <P6/> */}
            <P7/>
            <P8/>
            <Footer/>
        </div>
        </HomepageContext.Provider>
    )
}


