
export default {
	"CATLY | Official Website": "CATLY | 공식 웹사이트",
	"description": "AAA급 고양이들과의 특별한 교감이 시작됩니다. 고양이들과 함께 초현실적인 여정을 떠나세요. 모험, 제작, 패션, 재미, 눈, 속도, 로봇까지! 고양이와 함께 무한한 가능성을 만들어 가세요.",
	"Email Address / Phone Number": "이메일 주소 / 휴대폰 번호",
	"Register Now": "지금 등록하기",
	"English": "English",
	"简体中文": "简体中文",
	"繁體中文": "繁體中文",
	"日本語": "日本語",
	"한국어": "한국어",
	"Русский": "Русский",
	"Español": "Español",
	"Português": "Português",
	"Français": "Français",
	"Deutsch": "Deutsch",
	"Italiano": "Italiano",
	"Registered Successfully": "등록 완료!",
	"Confident": "파워",
	"Strides": "성큼성큼",
	"Brave": "우아함",
	"and Boundless": "기품",
	"Elegant": "장난감",
	"Nimble": "기쁨",
	"Temperamental": "신경질적",
	"by Nature": "세련미",
	"Fun and Lively": "사랑스러운 봉제 인형",
	"Confident Strides": "힘찬 발걸음",
	"Brave Sprit": "우아하고 기품이 넘침",
	"Elegant Nimble": "장난감 애호가",
	"Temperamental Chic": "신경질적이고 세련됨",
	"Fun and Lively detail": "사랑스러운 봉제 인형",
	"In Their Eyes, Worlds Unfold": "고양이의 눈으로 세상을 펼쳐보다",
	"Meta Essentials": "세련미",
	"Discover All Accessories": "모두 보기",
	"Sunglasses": "선글라스",
	"Hat": "모자",
	"Earrings": "귀걸이",
	"Necklace": "목걸이",
	"Bag": "가방",
	"Products Accessories": "상품",
	"All": "모두",
	"Sunglasses detail": "선글라스",
	"Hat detail": "모자",
	"Necklace detail": "귀걸이",
	"Earrings detail": "목걸이",
	"Bag detail": "가방",
	"Colored Sunglasses": "컬러 선글라스",
	"Big-ear Hat": "큰 귀 모자",
	"Gold Sunglasses": "황금색 선글라스",
	"Gold Shell Earrings": "황금색 조개 귀걸이",
	"Macaron Bag": "마카롱 가방",
	"Purple Shell Earphone": "보라색 조개 귀걸이",
	"Pink Lady Necklace": "분홍색 여성용 목걸이",
	"A Canvas of Stories, Painted in Fur": "털로 그려낸 수많은 이야기",
	"A world of whispers, wonder, and infinite grace.": "속삭임과 경이로움, 무한한 우아함이 깃든 고양이들의 세계",
	"Style Collection": "스타일 컬렉션",
	"Discover All fashion": "모두 보기",
	"Blossom": "블로섬",
	"Winterwarrior": "윈터 워리어",
	"Snowshield": "스노우 쉴드",
	"Macaron": "마카롱",
	"Combat": "컴뱃",
	"Products": "상품",
	"Season 1": "시즌 1",
	"Blossom Coat": "블로섬 코트",
	"Winterwarrior Suit": "윈터 워리어 의상",
	"Snowshield Suit": "스노우 쉴드 의상",
	"Blossom Tops": "블로섬 상의",
	"Macaron Coat": "마카롱 코트",
	"Combat Coat": "컴뱃 코트",
	"Adventure Sports Suit": "어드벤처 스포츠 의상",
	"Futuristic Jacket": "퓨처리스틱 재킷",
	"Born of Moonlight, Bound by Mystery.": "달빛 속에서 태어난 신비로운 존재",
	"Music by Akira Senju": "음악: Akira Senju",
	"Privacy Policy": "개인정보 보호정책",
	"NEWSLETTER": "뉴스레터",
	"Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.": "Catly 이메일을 구독하고 온라인 선공개, 새로운 컬렉션 등 최신 소식을 가장 먼저 받아보세요.",
	"FOLLOW US": "팔로우하기",
	"Email address": "이메일 주소",
	"OK": "입력",
	"Followed Successfully": "팔로우 성공",
	"Power click": "파워",
	"Elegance click": "우아함",
	"Toy click": "장난감",
	"Temperamental click": "신경질적",
	"Petite click": "귀여운 인형",
	"null": "전화번호 또는 이메일을 비울 수 없습니다.",
	"The Game Awards 2024": "게임 어워드 2024",
	"Showcase Game": "쇼케이스 게임",

}
