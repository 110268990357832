
export default [
	["合衆国領有小離島", ""],
	["プエルトリコ", "1"],
	["カナダ", "1"],
	["アメリカ合衆国", "1"],
	["バハマ", "1-242"],
	["バルバドス", "1-246"],
	["アンギラ", "1-264"],
	["アンティグア・バーブーダ", "1-268"],
	["イギリス領ヴァージン諸島", "1-284"],
	["アメリカ領ヴァージン諸島", "1-340"],
	["ケイマン諸島", "1-345"],
	["バミューダ", "1-441"],
	["グレナダ", "1-473"],
	["タークス・カイコス諸島", "1-649"],
	["モントセラト", "1-664"],
	["北マリアナ諸島", "1-670"],
	["グアム", "1-671"],
	["アメリカ領サモア", "1-684"],
	["シント・マールテン（オランダ領）", "1-721"],
	["セントルシア", "1-758"],
	["ドミニカ国", "1-767"],
	["セントビンセント・グレナディーン", "1-784"],
	["ドミニカ共和国", "1-809"],
	["トリニダード・トバゴ", "1-868"],
	["セントクリストファー・ネイビス", "1-869"],
	["ジャマイカ", "1-876"],
	["エジプト", "20"],
	["南スーダン", "211"],
	["モロッコ", "212"],
	["西サハラ", "212"],
	["アルジェリア", "213"],
	["チュニジア", "216"],
	["リビア", "218"],
	["ガンビア", "220"],
	["セネガル", "221"],
	["モーリタニア", "222"],
	["マリ", "223"],
	["ギニア", "224"],
	["コートジボワール", "225"],
	["ブルキナファソ", "226"],
	["ニジェール", "227"],
	["トーゴ", "228"],
	["ベナン", "229"],
	["モーリシャス", "230"],
	["リベリア", "231"],
	["シエラレオネ", "232"],
	["ガーナ", "233"],
	["ナイジェリア", "234"],
	["チャド", "235"],
	["中央アフリカ共和国", "236"],
	["カメルーン", "237"],
	["カーボベルデ", "238"],
	["サントメ・プリンシペ", "239"],
	["赤道ギニア", "240"],
	["ガボン", "241"],
	["コンゴ共和国", "242"],
	["コンゴ民主共和国", "243"],
	["アンゴラ", "244"],
	["ギニアビサウ", "245"],
	["イギリス領インド洋地域", "246"],
	["セーシェル", "248"],
	["スーダン", "249"],
	["ルワンダ", "250"],
	["エチオピア", "251"],
	["ソマリア", "252"],
	["ジブチ", "253"],
	["ケニア", "254"],
	["タンザニア", "255"],
	["ウガンダ", "256"],
	["ブルンジ", "257"],
	["モザンビーク", "258"],
	["ザンビア", "260"],
	["マダガスカル", "261"],
	["フランス領南方・南極地域", "262"],
	["レユニオン", "262"],
	["マヨット", "262"],
	["ジンバブエ", "263"],
	["ナミビア", "264"],
	["マラウイ", "265"],
	["レソト", "266"],
	["ボツワナ", "267"],
	["エスワティニ", "268"],
	["コモロ", "269"],
	["南アフリカ", "27"],
	["セントヘレナ・アセンションおよびトリスタンダクーニャ", "290n"],
	["エリトリア", "291"],
	["アルバ", "297"],
	["フェロー諸島", "298"],
	["グリーンランド", "299"],
	["ギリシャ", "30"],
	["オランダ", "31"],
	["ベルギー", "32"],
	["フランス", "33"],
	["スペイン", "34"],
	["ジブラルタル", "350"],
	["ポルトガル", "351"],
	["ルクセンブルク", "352"],
	["アイルランド", "353"],
	["アイスランド", "354"],
	["アルバニア", "355"],
	["マルタ", "356"],
	["キプロス", "357"],
	["オーランド諸島", "358"],
	["フィンランド", "358"],
	["ブルガリア", "359"],
	["ハンガリー", "36"],
	["リトアニア", "370"],
	["ラトビア", "371"],
	["エストニア", "372"],
	["モルドバ共和国", "373"],
	["アルメニア", "374"],
	["ベラルーシ", "375"],
	["アンドラ", "376"],
	["モナコ", "377"],
	["サンマリノ", "378"],
	["ウクライナ", "380"],
	["セルビア", "381p"],
	["モンテネグロ", "382"],
	["クロアチア", "385"],
	["スロベニア", "386"],
	["ボスニア・ヘルツェゴビナ", "387"],
	["北マケドニア", "389"],
	["イタリア", "39"],
	["バチカン市国", "39-06"],
	["ルーマニア", "40"],
	["スイス", "41"],
	["チェコ", "420"],
	["スロバキア", "421"],
	["リヒテンシュタイン", "423"],
	["オーストリア", "43"],
	["ガーンジー", "44"],
	["マン島", "44"],
	["イギリス", "44"],
	["ジャージー", "44"],
	["デンマーク", "45"],
	["スウェーデン", "46"],
	["ブーベ島", "47"],
	["スヴァールバル諸島およびヤンマイエン島", "47"],
	["ノルウェー", "47"],
	["ポーランド", "48"],
	["ドイツ", "49"],
	["フォークランド（マルビナス）諸島", "500"],
	["サウスジョージア・サウスサンドウィッチ諸島", "500"],
	["ベリーズ", "501"],
	["グアテマラ", "502"],
	["エルサルバドル", "503"],
	["ホンジュラス", "504"],
	["ニカラグア", "505"],
	["コスタリカ", "506"],
	["パナマ", "507"],
	["サンピエール島・ミクロン島", "508"],
	["ハイチ", "509"],
	["ペルー", "51"],
	["メキシコ", "52"],
	["キューバ", "53"],
	["アルゼンチン", "54"],
	["ブラジル", "55"],
	["チリ", "56"],
	["コロンビア", "57"],
	["ベネズエラ・ボリバル共和国", "58"],
	["サン・マルタン（フランス領）", "590"],
	["グアドループ", "590"],
	["サン・バルテルミー", "590"],
	["ボリビア多民族国", "591"],
	["ガイアナ", "592"],
	["エクアドル", "593"],
	["フランス領ギアナ", "594"],
	["パラグアイ", "595"],
	["マルティニーク", "596"],
	["スリナム", "597"],
	["ウルグアイ", "598"],
	["ボネール、シント・ユースタティウスおよびサバ", "599"],
	["キュラソー", "599"],
	["マレーシア", "60"],
	["オーストラリア", "61"],
	["ココス（キーリング）諸島", "61"],
	["クリスマス島", "61"],
	["インドネシア", "62"],
	["フィリピン", "63"],
	["ニュージーランド", "64"],
	["シンガポール", "65"],
	["タイ", "66"],
	["東ティモール", "670"],
	["ハード島とマクドナルド諸島", "672"],
	["南極", "672"],
	["ノーフォーク島", "672"],
	["ブルネイ・ダルサラーム", "673"],
	["ナウル", "674"],
	["パプアニューギニア", "675"],
	["トンガ", "676"],
	["ソロモン諸島", "677"],
	["バヌアツ", "678"],
	["フィジー", "679"],
	["パラオ", "680"],
	["ウォリス・フツナ", "681"],
	["クック諸島", "682"],
	["ニウエ", "683"],
	["サモア", "685"],
	["キリバス", "686"],
	["ニューカレドニア", "687"],
	["ツバル", "688"],
	["フランス領ポリネシア", "689"],
	["トケラウ", "690"],
	["ミクロネシア連邦", "691"],
	["マーシャル諸島", "692"],
	["ロシア連邦", "7"],
	["カザフスタン", "7"],
	["日本", "81"],
	["大韓民国", "82"],
	["ベトナム", "84"],
	["朝鮮民主主義人民共和国", "850"],
	["香港", "852"],
	["マカオ", "853"],
	["カンボジア", "855"],
	["ラオス人民民主共和国", "856"],
	["中華人民共和国", "86"],
	["ピトケアン", "870"],
	["バングラデシュ", "880"],
	["台湾（中華民国）", "886"],
	["トルコ", "90"],
	["インド", "91"],
	["パキスタン", "92"],
	["アフガニスタン", "93"],
	["スリランカ", "94"],
	["ミャンマー", "95"],
	["モルディブ", "960"],
	["レバノン", "961"],
	["ヨルダン", "962"],
	["シリア・アラブ共和国", "963"],
	["イラク", "964"],
	["クウェート", "965"],
	["サウジアラビア", "966"],
	["イエメン", "967"],
	["オマーン", "968"],
	["パレスチナ", "970"],
	["アラブ首長国連邦", "971"],
	["イスラエル", "972"],
	["バーレーン", "973"],
	["カタール", "974"],
	["ブータン", "975"],
	["モンゴル", "976"],
	["ネパール", "977"],
	["イラン・イスラム共和国", "98"],
	["タジキスタン", "992"],
	["トルクメニスタン", "993"],
	["アゼルバイジャン", "994"],
	["ジョージア", "995"],
	["キルギス", "996"],
	["ウズベキスタン", "998"],

]
