
export default {
	"CATLY | Official Website": "CATLY | Offizielle Website",
	"description": "Gefährten neu definiert mit AAA-KATZEn. Eine hyperrealistische Reise volle Abenteuer, Basteleien, Mode, Spaß, Schnee, Tempo, Robotern – alles mit und dank KATZEn.",
	"Email Address / Phone Number": "E-Mail-Adresse/Telefonnummer",
	"Register Now": "Jetzt registrieren",
	"English": "English",
	"简体中文": "简体中文",
	"繁體中文": "繁體中文",
	"日本語": "日本語",
	"한국어": "한국어",
	"Русский": "Русский",
	"Español": "Español",
	"Português": "Português",
	"Français": "Français",
	"Deutsch": "Deutsch",
	"Italiano": "Italiano",
	"Registered Successfully": "Miausgezeichnet registriert!",
	"Confident": "Kräftige",
	"Strides": "Pfoten",
	"Brave": "Elegant und",
	"and Boundless": "Anmutig",
	"Elegant": "Verspielte",
	"Nimble": "Frohnatur",
	"Temperamental": "Temperamentvoll und",
	"by Nature": "Schick",
	"Fun and Lively": "Zierliches Fellknäuel",
	"Confident Strides": "Kräftige Pfoten",
	"Brave Sprit": "Elegant und anmutig",
	"Elegant Nimble": "Verspielte Frohnatur",
	"Temperamental Chic": "Temperamentvoll und schick",
	"Fun and Lively detail": "Zierliches Fellknäuel",
	"In Their Eyes, Worlds Unfold": "Katzenaugen sehen die ganze Welt",
	"Meta Essentials": "Schick",
	"Discover All Accessories": "Alle entdecken",
	"Sunglasses": "Sonnenbrille",
	"Hat": "Kopfbedeckung",
	"Earrings": "Ohrringe",
	"Necklace": "Halskette",
	"Bag": "Tasche",
	"Products Accessories": "Artikel",
	"All": "Alle",
	"Sunglasses detail": "Sonnenbrille",
	"Hat detail": "Kopfbedeckung",
	"Necklace detail": "Ohrringe",
	"Earrings detail": "Halskette",
	"Bag detail": "Tasche",
	"Colored Sunglasses": "Gefärbte Sonnenbrille",
	"Big-ear Hat": "Mütze mit großen Ohren",
	"Gold Sunglasses": "Goldene Sonnenbrille",
	"Gold Shell Earrings": "Goldmuschel-Ohrringe",
	"Macaron Bag": "Macaron-Tasche",
	"Purple Shell Earphone": "Purpurmuschel-Kopfhörer",
	"Pink Lady Necklace": "Pinke Damenhalskette",
	"A Canvas of Stories, Painted in Fur": "Eine Leinwand voller pelziger Geschichten",
	"A world of whispers, wonder, and infinite grace.": "Eine Katzenwelt voller Schnurren, Wunder und Anmut.",
	"Style Collection": "Stil-Sammlung",
	"Discover All fashion": "Alle entdecken",
	"Blossom": "Blüte",
	"Winterwarrior": "Winterkrieger",
	"Snowshield": "Schneeschild",
	"Macaron": "Macaron",
	"Combat": "Schlacht",
	"Products": "Artikel",
	"Season 1": "Saison 1",
	"Blossom Coat": "Blütenmantel",
	"Winterwarrior Suit": "Winterkrieger-Anzug",
	"Snowshield Suit": "Schneeschild-Anzug",
	"Blossom Tops": "Blütenoberteil",
	"Macaron Coat": "Macaron-Mantel",
	"Combat Coat": "Schlachtumhang",
	"Adventure Sports Suit": "Abenteuer-Trainingsanzug",
	"Futuristic Jacket": "Futuristische Jacke",
	"Born of Moonlight, Bound by Mystery.": "Rätselhaftes Mondlicht",
	"Music by Akira Senju": "Musik von Akira Senju",
	"Privacy Policy": "Datenschutzrichtlinie",
	"NEWSLETTER": "NEWSLETTER",
	"Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.": "Melde dich für E-Mail-Benachrichtigungen von Catly an und erhalte Neuigkeiten, inklusive exklusiver Online-Vorabveröffentlichungen und neuer Kollektionen.",
	"FOLLOW US": "FOLGE UNS",
	"Email address": "E-Mail-Adresse",
	"OK": "OK",
	"Followed Successfully": "Erfolgreich gefolgt",
	"Power click": "Kräftige",
	"Elegance click": "Elegant und",
	"Toy click": "Verspielte",
	"Temperamental click": "Temperamentvoll",
	"Petite click": "Zierliches",
	"null": "Telefonnummer oder E-Mail dürfen nicht leer sein.",
	"The Game Awards 2024": "The Game Awards 2024",
	"Showcase Game": "Vorgestelltes Spiel",

}
