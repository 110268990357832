import { useContext, useState } from "react"
import styles from "./Detail.module.scss"

import NavPng from './assets/images/products_nav.png'
import Detail1Png from './assets/images/detail_1.png'
import Detail2Png from './assets/images/detail_2.png'
import Detail3Png from './assets/images/detail_3.png'
import Detail4Png from './assets/images/detail_4.png'
import Detail5Png from './assets/images/detail_5.png'
import Detail6Png from './assets/images/detail_6.png'
import Detail7Png from './assets/images/detail_7.png'
import Detail8Png from './assets/images/detail_8.png'

import Header from "../../components/layout/Header"
import Footer from "../footer/Footer"
import { LanguageContext } from "../../context/useLanguageContext"


export default () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['detail-scrollview']}>
            <Header white={true} goback={true}/>
            <div className={styles['detail']}>
                <div className={styles['detail__content']}>
                    <div className={styles['detail__content-header-wrapper']}>
                        <div className={styles['detail__content-header']}>
                            <div className={styles['detail__content-header-menu']}>
                                <div className={styles['detail__content-header-menu-panel']}>
                                    <div className={styles['detail__content-header-menu-panel-text1']}>{t('Products')}</div>
                                    <div className={styles['detail__content-header-menu-panel-text2-wrapper']}>
                                        <div className={styles['detail__content-header-menu-panel-text2']}>{t('Season 1')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles['detail__content-item']}>
                        <div className={styles['detail__content-item-1']}>
                            <div className={styles['detail__content-item-1-1']}>
                                <img src={Detail1Png} className={styles['detail__content-item-1-1-img']}/>
                                <span className={styles['detail__content-item-1-1-text']}>{t('Blossom Coat')}</span>
                            </div>
                            <div className={styles['detail__content-item-1-2']}>
                                <div className={styles['detail__content-item-1-2-1']}>
                                    <img src={Detail2Png} className={styles['detail__content-item-1-2-1-img']}/>
                                    <span className={styles['detail__content-item-1-2-1-text']}>{t('Winterwarrior Suit')}</span>
                                </div>
                                <div className={styles['detail__content-item-1-2-2']}>
                                    <img src={Detail3Png} className={styles['detail__content-item-1-2-2-img']}/>
                                    <span className={styles['detail__content-item-1-2-2-text']}>{t('Snowshield Suit')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles['detail__content-item-2']}>
                            <div className={styles['detail__content-item-2-1']}>
                                <img src={Detail4Png} className={styles['detail__content-item-2-1-img']}/>
                                <span className={styles['detail__content-item-2-1-text']}>{t('Macaron Coat')}</span>
                            </div>
                            <div className={styles['detail__content-item-2-2']}>
                                <img src={Detail5Png} className={styles['detail__content-item-2-2-img']}/>
                                <span className={styles['detail__content-item-2-2-text']}>{t('Blossom Tops')}</span>
                            </div>
                        </div>
                        <div className={styles['detail__content-item-3']}>
                            <div className={styles['detail__content-item-3-1']}>
                                <div className={styles['detail__content-item-3-1-1']}>
                                    <img src={Detail6Png} className={styles['detail__content-item-3-1-1-img']}/>
                                    <span className={styles['detail__content-item-3-1-1-text']}>{t('Combat Coat')}</span>
                                </div>
                                <div className={styles['detail__content-item-3-1-2']}>
                                    <img src={Detail7Png} className={styles['detail__content-item-3-1-2-img']}/>
                                    <span className={styles['detail__content-item-3-1-2-text']}>{t('Adventure Sports Suit')}</span>
                                </div>
                            </div>
                            <div className={styles['detail__content-item-3-2']}>
                                <img src={Detail8Png} className={styles['detail__content-item-3-2-img']}/>
                                <span className={styles['detail__content-item-3-2-text']}>{t('Futuristic Jacket')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width: '100%', overflowX: 'hidden'}}>
            <Footer/>
            </div>
        </div>
    )
}
