import { useEffect, useRef, useState } from "react"
import { useParams } from 'react-router-dom';
import Header from "../../components/layout/Header"
import P1 from "./detail1/Index"
import P2 from "./detail2/Index"
import Footer from "../footer/Footer"
import styles from "./Detail.module.scss"


const stepStyles = [
  "0",
  "-100vh",
  "calc(-100vh - 482px)",
]

let lastTime = 0

export default () => {

    let param = useParams();
    let imageId:string = param.id!
    // console.log('id:', imageId)
    
    // const [lastTime, setLastTime] = useState(0)
    let [step, setStep] = useState(() => 0)

    const [footerHeight, setFooterHeight] = useState<number>(0)
    const footerRef = useRef<HTMLDivElement>(null)

    const handleWheel = (event: any) => {

      var currentTime = Date.now()
      if (currentTime - lastTime <= 1300) {
        return
      }
      lastTime = currentTime
      // setLastTime(current => {
      //   return currentTime
      // })

      const delta = event.deltaY; // deltaY值为正表示向下滚动，为负表示向上滚动
      const down = delta > 0

      if(down){
        setStep(current => {
          const step = current + 1
          return Math.min(step, 2)
        })
      }else{
        setStep(current => {
          const step = current - 1
          return Math.max(0, step)
        })
      }
      
    };

    const getStyle = () => {

      let translateOffset = `translateY(${stepStyles[step]})`
      if(step == 2){
        translateOffset = `translateY(calc(-100vh - ${footerHeight}px))`
      }
      return {
        transform: translateOffset
      }
    }

    useEffect(() => {
      const _footerHeight = footerRef.current?.getBoundingClientRect().height??0
      setFooterHeight(_footerHeight)
    }, [])


    return (
        <div className={styles['homepage']}>
          <div className={styles['homepage-wrapper']} onWheel={handleWheel} style={getStyle()}>
            <Header white/>
            <P1 id={imageId}/>
            <P2 id={imageId}/>
            <div ref={footerRef}><Footer/></div>
          </div>
        </div>
    )
}


