import { createContext, useContext, useEffect, useRef, useState } from "react";

import bmg from "../assets/audio/bgm.WAV"

interface BgmContextDTO{
    playing: boolean
    play: () => void
    pause: () => void
    triggerPlay: () => void
}

const get = () => {
    return parseInt(localStorage.getItem("audio_playing")??'0')
}

const save = (value: boolean) => {
    localStorage.setItem("audio_playing", value?'1':'-1')
}

export const BgmContext = createContext<BgmContextDTO>({
    playing: false,
    play: function (): void {
        throw new Error("Function not implemented.");
    },
    pause: function (): void {
        throw new Error("Function not implemented.");
    },
    triggerPlay: function (): void {
        throw new Error("Function not implemented.");
    }
})


export default (): BgmContextDTO => {
    
    const [playing, setPlaying] = useState<boolean>(() => false)

    const play = () => {
        setPlaying(true)
        save(true)
    }

    const pause = () => {
        setPlaying(false)
        save(false)
    }

    const triggerPlay = () => {
        if(get() > -1){
            play()
        }
    }

    return {
        playing,
        play,
        pause,
        triggerPlay
    }

}

export const BmgPlayer = () => {

    const {playing, play, pause} = useContext(BgmContext)
    const ref = useRef<HTMLAudioElement>(null)

    useEffect(() => {

        if(playing){
            ref.current?.play()
        }else{
            ref.current?.pause()
        }

    }, [playing])

    return (
        <audio ref={ref} src={bmg} autoPlay={playing} loop={true}/>
    )
}