import styles from "./Index.module.scss"

export default (props: {id: string}) => {
    return (
        <div className={styles[`p2-d2-${props.id}`]}>
            <img className={styles[`p2-d2-${props.id}__l`]}></img>
            <img className={styles[`p2-d2-${props.id}__r`]}></img>
        </div>
    )
}


