
export default {
	"CATLY | Official Website": "CATLY | 官方網站",
	"description": "光影交織，細膩如詩，震撼心靈的畫面如夢境般絢爛。顯性生輝的3A貓，暖如晨曦，帶來無法言喻的陪伴。屬於你的超凡童話。",
	"Email Address / Phone Number": "電子郵件 / 手機號碼",
	"Register Now": "立即註冊",
	"English": "English",
	"简体中文": "简体中文",
	"繁體中文": "繁體中文",
	"日本語": "日本語",
	"한국어": "한국어",
	"Русский": "Русский",
	"Español": "Español",
	"Português": "Português",
	"Français": "Français",
	"Deutsch": "Deutsch",
	"Italiano": "Italiano",
	"Registered Successfully": "完美註冊！",
	"Confident": "霸氣",
	"Strides": "銳利",
	"Brave": "優雅",
	"and Boundless": "嬌媚",
	"Elegant": "童趣",
	"Nimble": "逗樂",
	"Temperamental": "熱辣",
	"by Nature": "獨特",
	"Fun and Lively": "娇嫩 可爱",
	"Confident Strides": "霸氣 銳利",
	"Brave Sprit": "優雅 嬌媚",
	"Elegant Nimble": "童趣 逗樂",
	"Temperamental Chic": "熱辣 獨特",
	"Fun and Lively detail": "嬌嫩 可愛",
	"In Their Eyes, Worlds Unfold": "貓眸流轉，奇境綻放",
	"Meta Essentials": "精緻甄選",
	"Discover All Accessories": "探索更多",
	"Sunglasses": "墨鏡",
	"Hat": "帽子",
	"Earrings": "耳環",
	"Necklace": "項鍊",
	"Bag": "包包",
	"Products Accessories": "商品",
	"All": "全部",
	"Sunglasses detail": "墨鏡",
	"Hat detail": "帽子",
	"Necklace detail": "耳環",
	"Earrings detail": "項鍊",
	"Bag detail": "包包",
	"Colored Sunglasses": "流光紫太陽眼鏡",
	"Big-ear Hat": "大耳毛絨帽",
	"Gold Sunglasses": "金色太陽眼鏡",
	"Gold Shell Earrings": "金色貝殼耳環",
	"Macaron Bag": "馬卡龍手袋",
	"Purple Shell Earphone": "紫色貝殼耳機",
	"Pink Lady Necklace": "粉魅女士項鍊",
	"A Canvas of Stories, Painted in Fur": "每一寸，皆為藝術",
	"A world of whispers, wonder, and infinite grace.": "貓語輕吟，綻放奇蹟與優雅的璀璨世界",
	"Style Collection": "潮流先鋒系列",
	"Discover All fashion": "盡情發現",
	"Blossom": "Blossom 外套",
	"Winterwarrior": "Winterwarrior 套裝",
	"Snowshield": "Snowshield 套裝",
	"Macaron": "Macaron 風衣",
	"Combat": "Combat 風衣",
	"Products": "商品",
	"Season 1": "秋冬系列",
	"Blossom Coat": "Blossom 外套",
	"Winterwarrior Suit": "Winterwarrior 套裝",
	"Snowshield Suit": "Snowshield 套裝",
	"Blossom Tops": "Blossom 上衣",
	"Macaron Coat": "Macaron 風衣",
	"Combat Coat": "Combat 風衣",
	"Adventure Sports Suit": "Adventure Sports 套裝",
	"Futuristic Jacket": "Futuristic 夾克",
	"Born of Moonlight, Bound by Mystery.": "月夜之靈，秘密深藏",
	"Music by Akira Senju": "音樂：Akira Senju 千住明",
	"Privacy Policy": "隱私權政策",
	"NEWSLETTER": "關注Catly",
	"Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.": "訂閱 Catly 新聞，獲取最新資訊，包括獨家線上預售和新品系列。",
	"FOLLOW US": "關注我們",
	"Email address": "信箱",
	"OK": "訂閱",
	"Followed Successfully": "訂閱成功",
	"Power click": "霸氣",
	"Elegance click": "優雅",
	"Toy click": "童趣",
	"Temperamental click": "熱辣",
	"Petite click": "可爱",
	"null": "手机或邮箱不能为空",
	"The Game Awards 2024": "The Game Awards 2024",
	"Showcase Game": "大會展示遊戲",

}
