import { http, HttpResponse, PaganitionRequest, PaganitionResponse } from "../bundle/lib";
import axios from "axios";


export interface RegisterRequest {
    region?: string
    phoneNumber?: string
    email?: string
}

export interface FollowRequest {
    email: string
}

const register = async (request: RegisterRequest) => {
    const res = (await axios.post("/user/register", request)).data as HttpResponse<void>
    http.checkState(res)
    return res
}

const follow = async (request: FollowRequest) => {
    const res = (await axios.post("/follow", request)).data as HttpResponse<void>
    http.checkState(res)
    return res
}

export default {
    register,
    follow,
}