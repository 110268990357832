
export default {
	"CATLY | Official Website": "-CATLY | Sito ufficiale",
	"description": "La nuova frontiera degli animali da compagnia con GATTI da tripla A. Un viaggio iperrealistico tra avventura, artigianato, moda, divertimento, neve, velocità, robot... solo grazie e attraverso i GATTI.",
	"Email Address / Phone Number": "Indirizzo e-mail / Numero di telefono",
	"Register Now": "Registrati ora",
	"English": "English",
	"简体中文": "简体中文",
	"繁體中文": "繁體中文",
	"日本語": "日本語",
	"한국어": "한국어",
	"Русский": "Русский",
	"Español": "Español",
	"Português": "Português",
	"Français": "Français",
	"Deutsch": "Deutsch",
	"Italiano": "Italiano",
	"Registered Successfully": "Registrazione miaoravigliosa!",
	"Confident": "Falcate",
	"Strides": "Potenti",
	"Brave": "Eleganza",
	"and Boundless": "E grazia",
	"Elegant": "Toy",
	"Nimble": "Amorevole",
	"Temperamental": "Volubile",
	"by Nature": "E chic",
	"Fun and Lively": "Morbidezza in miniatura",
	"Confident Strides": "Falcate potenti",
	"Brave Sprit": "Eleganza e grazia",
	"Elegant Nimble": "Toy amorevole",
	"Temperamental Chic": "Volubile e chic",
	"Fun and Lively detail": "Morbidezza in miniatura",
	"In Their Eyes, Worlds Unfold": "Interi mondi attraverso gli occhi di un gatto",
	"Meta Essentials": "E chic",
	"Discover All Accessories": "Scopri tutto",
	"Sunglasses": "Occhiali da sole",
	"Hat": "Cappello",
	"Earrings": "Orecchini",
	"Necklace": "Collana",
	"Bag": "Borsa",
	"Products Accessories": "Prodotti",
	"All": "Tutto",
	"Sunglasses detail": "Occhiali da sole",
	"Hat detail": "Cappello",
	"Necklace detail": "Orecchini",
	"Earrings detail": "Collana",
	"Bag detail": "Borsa",
	"Colored Sunglasses": "Occhiali da sole colorati",
	"Big-ear Hat": "Berretto con orecchie",
	"Gold Sunglasses": "Occhiali da sole dorati",
	"Gold Shell Earrings": "Orecchini dorati a conchiglia",
	"Macaron Bag": "Borsa macaron",
	"Purple Shell Earphone": "Auricolare viola a conchiglia",
	"Pink Lady Necklace": "Collana femminile rosa",
	"A Canvas of Stories, Painted in Fur": "Opere d'arte, di baffi e pelliccia",
	"A world of whispers, wonder, and infinite grace.": "Un mondo di gatti tra sussurri, stupore e sofisticatezza.",
	"Style Collection": "Personalizza collezione",
	"Discover All fashion": "Scopri tutto",
	"Blossom": "Fioritura",
	"Winterwarrior": "Guerriero invernale",
	"Snowshield": "Scudo riscaldante",
	"Macaron": "Macaron",
	"Combat": "Combattente",
	"Products": "Prodotti",
	"Season 1": "Stagione 1",
	"Blossom Coat": "Cappotto Fioritura",
	"Winterwarrior Suit": "Completo Guerriero invernale",
	"Snowshield Suit": "Completo Scudo riscaldante",
	"Blossom Tops": "Top Fioritura",
	"Macaron Coat": "Cappotto Macaron",
	"Combat Coat": "Cappotto Combattente",
	"Adventure Sports Suit": "Completo Sport estremo",
	"Futuristic Jacket": "Giacca Futurismo",
	"Born of Moonlight, Bound by Mystery.": "Nati tra le stelle, avvolti nel mistero.",
	"Music by Akira Senju": "Musica di Akira Senju",
	"Privacy Policy": "Informativa sulla privacy",
	"NEWSLETTER": "NEWSLETTER",
	"Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.": "Iscriviti per ricevere e-mail di Catly con le ultime novità, tra cui informazioni sui lanci in anteprima online esclusivi e sulle nuove collezioni.",
	"FOLLOW US": "SEGUICI",
	"Email address": "Indirizzo e-mail",
	"OK": "OK",
	"Followed Successfully": "Iscrizione riuscita",
	"Power click": "Falcate",
	"Elegance click": "Eleganza",
	"Toy click": "Toy",
	"Temperamental click": "Volubile",
	"Petite click": "Piccola",
	"null": "Il numero di telefono o l'email non possono essere vuoti.",
	"The Game Awards 2024": "Gioco mostrato ai",
	"Showcase Game": "Game Awards 2024",

}
