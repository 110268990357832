
export default [
	["Afghanistan", "93"],
	["Afrique du Sud", "27"],
	["Albanie", "355"],
	["Algérie", "213"],
	["Allemagne", "49"],
	["Andorre", "376"],
	["Angola", "244"],
	["Anguilla", "1-264"],
	["Antarctique", "672"],
	["Antigua-et-Barbuda", "1-268"],
	["Arabie saoudite", "966"],
	["Argentine", "54"],
	["Arménie", "374"],
	["Aruba", "297"],
	["Australie", "61"],
	["Autriche", "43"],
	["Azerbaïdjan", "994"],
	["Bahamas", "1-242"],
	["Bahreïn", "973"],
	["Bangladesh", "880"],
	["Barbade", "1-246"],
	["Belgique", "32"],
	["Belize", "501"],
	["Bermudes", "1-441"],
	["Bhoutan", "975"],
	["Birmanie", "95"],
	["Biélorussie", "375"],
	["Bolivie", "591"],
	["Bosnie-Herzégovine", "387"],
	["Botswana", "267"],
	["Brunei", "673"],
	["Brésil", "55"],
	["Bulgarie", "359"],
	["Burkina Faso", "226"],
	["Burundi", "257"],
	["Bénin", "229"],
	["Cambodge", "855"],
	["Cameroun", "237"],
	["Canada", "1"],
	["Cap-Vert", "238"],
	["Chili", "56"],
	["Chine", "86"],
	["Chypre", "357"],
	["Colombie", "57"],
	["Comores", "269"],
	["Corée du Nord", "850"],
	["Corée du Sud", "82"],
	["Costa Rica", "506"],
	["Croatie", "385"],
	["Cuba", "53"],
	["Curaçao", "599"],
	["Côte d'Ivoire", "225"],
	["Danemark", "45"],
	["Djibouti", "253"],
	["Dominique", "1-767"],
	["Espagne", "34"],
	["Estonie", "372"],
	["Eswatini", "268"],
	["Fidji", "679"],
	["Finlande", "358"],
	["France", "33"],
	["Gabon", "241"],
	["Gambie", "220"],
	["Ghana", "233"],
	["Gibraltar", "350"],
	["Grenade", "1-473"],
	["Groenland", "299"],
	["Grèce", "30"],
	["Guadeloupe", "590"],
	["Guam", "1-671"],
	["Guatemala", "502"],
	["Guernesey", "44"],
	["Guinée", "224"],
	["Guinée équatoriale", "240"],
	["Guinée-Bissau", "245"],
	["Guyana", "592"],
	["Guyane", "594"],
	["Géorgie", "995"],
	["Géorgie du Sud-et-les îles Sandwich du Sud", "500"],
	["Haïti", "509"],
	["Honduras", "504"],
	["Hong Kong", "852"],
	["Hongrie", "36"],
	["Inde", "91"],
	["Indonésie", "62"],
	["Irak", "964"],
	["Iran", "98"],
	["Irlande", "353"],
	["Islande", "354"],
	["Israël", "972"],
	["Italie", "39"],
	["Jamaïque", "1-876"],
	["Japon", "81"],
	["Jersey", "44"],
	["Jordanie", "962"],
	["Kazakhstan", "7"],
	["Kenya", "254"],
	["Kirghizistan", "996"],
	["Kiribati", "686"],
	["Koweït", "965"],
	["La Réunion", "262"],
	["Laos", "856"],
	["Lesotho", "266"],
	["Lettonie", "371"],
	["Liban", "961"],
	["Liberia", "231"],
	["Libye", "218"],
	["Liechtenstein", "423"],
	["Lituanie", "370"],
	["Luxembourg", "352"],
	["Macao", "853"],
	["Macédoine du Nord", "389"],
	["Madagascar", "261"],
	["Malaisie", "60"],
	["Malawi", "265"],
	["Maldives", "960"],
	["Mali", "223"],
	["Malouines", "500"],
	["Malte", "356"],
	["Maroc", "212"],
	["Martinique", "596"],
	["Maurice", "230"],
	["Mauritanie", "222"],
	["Mayotte", "262"],
	["Mexique", "52"],
	["Moldavie", "373"],
	["Monaco", "377"],
	["Mongolie", "976"],
	["Montserrat", "1-664"],
	["Monténégro", "382"],
	["Mozambique", "258"],
	["Namibie", "264"],
	["Nauru", "674"],
	["Nicaragua", "505"],
	["Niger", "227"],
	["Nigeria", "234"],
	["Niue", "683"],
	["Norvège", "47"],
	["Nouvelle-Calédonie", "687"],
	["Nouvelle-Zélande", "64"],
	["Népal", "977"],
	["Oman", "968"],
	["Ouganda", "256"],
	["Ouzbékistan", "998"],
	["Pakistan", "92"],
	["Palaos", "680"],
	["Palestine", "970"],
	["Panama", "507"],
	["Papouasie-Nouvelle-Guinée", "675"],
	["Paraguay", "595"],
	["Pays-Bas", "31"],
	["Pays-Bas caribéens", "599"],
	["Philippines", "63"],
	["Pologne", "48"],
	["Polynésie française", "689"],
	["Porto Rico", "1"],
	["Portugal", "351"],
	["Pérou", "51"],
	["Qatar", "974"],
	["Roumanie", "40"],
	["Royaume-Uni", "44"],
	["Russie", "7"],
	["Rwanda", "250"],
	["République arabe sahraouie démocratique", "212"],
	["République centrafricaine", "236"],
	["République dominicaine", "1-809"],
	["République du Congo", "242"],
	["République démocratique du Congo", "243"],
	["Saint-Barthélemy", "590"],
	["Saint-Christophe-et-Niévès", "1-869"],
	["Saint-Marin", "378"],
	["Saint-Martin", "590"],
	["Saint-Martin", "1-721"],
	["Saint-Pierre-et-Miquelon", "508"],
	["Saint-Siège (État de la Cité du Vatican)", "39-06"],
	["Saint-Vincent-et-les-Grenadines", "1-784"],
	["Sainte-Hélène, Ascension et Tristan da Cunha", "290n"],
	["Sainte-Lucie", "1-758"],
	["Salvador", "503"],
	["Samoa", "685"],
	["Samoa américaines", "1-684"],
	["Sao Tomé-et-Principe", "239"],
	["Serbie", "381p"],
	["Seychelles", "248"],
	["Sierra Leone", "232"],
	["Singapour", "65"],
	["Slovaquie", "421"],
	["Slovénie", "386"],
	["Somalie", "252"],
	["Soudan", "249"],
	["Soudan du Sud", "211"],
	["Sri Lanka", "94"],
	["Suisse", "41"],
	["Suriname", "597"],
	["Suède", "46"],
	["Svalbard et île Jan Mayen", "47"],
	["Syrie", "963"],
	["Sénégal", "221"],
	["Tadjikistan", "992"],
	["Tanzanie", "255"],
	["Taïwan (République de Chine)", "886"],
	["Tchad", "235"],
	["Tchéquie", "420"],
	["Terres australes et antarctiques françaises", "262"],
	["Territoire britannique de l'océan Indien", "246"],
	["Thaïlande", "66"],
	["Timor oriental", "670"],
	["Togo", "228"],
	["Tokelau", "690"],
	["Tonga", "676"],
	["Trinité-et-Tobago", "1-868"],
	["Tunisie", "216"],
	["Turkménistan", "993"],
	["Turquie", "90"],
	["Tuvalu", "688"],
	["Ukraine", "380"],
	["Uruguay", "598"],
	["Vanuatu", "678"],
	["Venezuela", "58"],
	["Viêt Nam", "84"],
	["Wallis-et-Futuna", "681"],
	["Yémen", "967"],
	["Zambie", "260"],
	["Zimbabwe", "263"],
	["Égypte", "20"],
	["Émirats arabes unis", "971"],
	["Équateur", "593"],
	["Érythrée", "291"],
	["États fédérés de Micronésie", "691"],
	["États-Unis", "1"],
	["Éthiopie", "251"],
	["Île Bouvet", "47"],
	["Île Christmas", "61"],
	["Île Norfolk", "672"],
	["Île de Man", "44"],
	["Îles Caïmans", "1-345"],
	["Îles Cocos", "61"],
	["Îles Cook", "682"],
	["Îles Féroé", "298"],
	["Îles Heard-et-MacDonald", "672"],
	["Îles Mariannes du Nord", "1-670"],
	["Îles Marshall", "692"],
	["Îles Pitcairn", "870"],
	["Îles Salomon", "677"],
	["Îles Turques-et-Caïques", "1-649"],
	["Îles Vierges britanniques", "1-284"],
	["Îles Vierges des États-Unis", "1-340"],
	["Îles mineures éloignées des États-Unis", ""],
	["Îles Åland", "358"],

]
