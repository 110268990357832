
export default [
	["Afeganistão", "93"],
	["Albânia", "355"],
	["Alemanha", "49"],
	["Andorra", "376"],
	["Angola", "244"],
	["Anguila", "1-264"],
	["Antártica", "672"],
	["Antígua e Barbuda", "1-268"],
	["Argentina", "54"],
	["Argélia", "213"],
	["Armênia", "374"],
	["Aruba", "297"],
	["Arábia Saudita", "966"],
	["Austrália", "61"],
	["Azerbaijão", "994"],
	["Bahamas", "1-242"],
	["Bahrein", "973"],
	["Bangladesh", "880"],
	["Barbados", "1-246"],
	["Belarus", "375"],
	["Belize", "501"],
	["Benin", "229"],
	["Bermudas", "1-441"],
	["Bolívia", "591"],
	["Botsuana", "267"],
	["Brasil", "55"],
	["Brunei", "673"],
	["Bulgária", "359"],
	["Burkina Faso", "226"],
	["Burundi", "257"],
	["Butão", "975"],
	["Bélgica", "32"],
	["Bósnia e Herzegovina", "387"],
	["Cabo Verde", "238"],
	["Camarões", "237"],
	["Camboja", "855"],
	["Canadá", "1"],
	["Cazaquistão", "7"],
	["Chade", "235"],
	["Chile", "56"],
	["China", "86"],
	["Chipre", "357"],
	["Cidade do Vaticano", "39-06"],
	["Cingapura", "65"],
	["Colômbia", "57"],
	["Comores", "269"],
	["Coréia do Norte", "850"],
	["Coréia do Sul", "82"],
	["Costa Rica", "506"],
	["Costa do Marfim", "225"],
	["Croácia", "385"],
	["Cuba", "53"],
	["Curaçao", "599"],
	["Dinamarca", "45"],
	["Djibouti", "253"],
	["Dominica", "1-767"],
	["Egito", "20"],
	["El Salvador", "503"],
	["Emirados Árabes Unidos", "971"],
	["Equador", "593"],
	["Eritreia", "291"],
	["Eslováquia", "421"],
	["Eslovênia", "386"],
	["Espanha", "34"],
	["Estados Federados da Micronésia", "691"],
	["Estados Unidos da América", "1"],
	["Estônia", "372"],
	["Eswatini", "268"],
	["Etiópia", "251"],
	["Fiji", "679"],
	["Filipinas", "63"],
	["Finlândia", "358"],
	["França", "33"],
	["Gabão", "241"],
	["Gana", "233"],
	["Geórgia", "995"],
	["Geórgia do Sul e as Ilhas Sandwich do Sul", "500"],
	["Gibraltar", "350"],
	["Grenada", "1-473"],
	["Groenlândia", "299"],
	["Grécia", "30"],
	["Guadalupe", "590"],
	["Guam", "1-671"],
	["Guatemala", "502"],
	["Guernsey", "44"],
	["Guiana", "592"],
	["Guiana Francesa", "594"],
	["Guiné", "224"],
	["Guiné Equatorial", "240"],
	["Guiné-Bissau", "245"],
	["Gâmbia", "220"],
	["Haiti", "509"],
	["Honduras", "504"],
	["Hong Kong", "852"],
	["Hungria", "36"],
	["Ilha Bouvet", "47"],
	["Ilha Norfolk", "672"],
	["Ilha da Reunião", "262"],
	["Ilha de Man", "44"],
	["Ilha de Natal", "61"],
	["Ilhas Cayman", "1-345"],
	["Ilhas Cocos", "61"],
	["Ilhas Cook", "682"],
	["Ilhas Faroe", "298"],
	["Ilhas Malvinas", "500"],
	["Ilhas Marianas do Norte", "1-670"],
	["Ilhas Marshall", "692"],
	["Ilhas Menores Distantes dos Estados Unidos", ""],
	["Ilhas Pitcairn", "870"],
	["Ilhas Salomão", "677"],
	["Ilhas Turcas e Caicos", "1-649"],
	["Ilhas Virgens (Reino Unido)", "1-284"],
	["Ilhas Virgens dos Estados Unidos", "1-340"],
	["Ilhas Åland", "358"],
	["Indonésia", "62"],
	["Iraque", "964"],
	["Irlanda", "353"],
	["Irã", "98"],
	["Islândia", "354"],
	["Israel", "972"],
	["Itália", "39"],
	["Iêmen", "967"],
	["Jamaica", "1-876"],
	["Japão", "81"],
	["Jersey", "44"],
	["Jordânia", "962"],
	["Kiribati", "686"],
	["Kuwait", "965"],
	["Laos", "856"],
	["Lesotho", "266"],
	["Letônia", "371"],
	["Libéria", "231"],
	["Liechtenstein", "423"],
	["Lituânia", "370"],
	["Luxemburgo", "352"],
	["Líbano", "961"],
	["Líbia", "218"],
	["Macau", "853"],
	["Madagascar", "261"],
	["Malauí", "265"],
	["Maldivas", "960"],
	["Mali", "223"],
	["Malta", "356"],
	["Malásia", "60"],
	["Marrocos", "212"],
	["Martinica", "596"],
	["Mauritânia", "222"],
	["Maurício", "230"],
	["Mayotte", "262"],
	["Mianmar", "95"],
	["Moldávia", "373"],
	["Mongólia", "976"],
	["Montenegro", "382"],
	["Montserrat", "1-664"],
	["Moçambique", "258"],
	["México", "52"],
	["Namíbia", "264"],
	["Nauru", "674"],
	["Nepal", "977"],
	["Nicarágua", "505"],
	["Nigéria", "234"],
	["Niue", "683"],
	["Norte da Macedônia", "389"],
	["Noruega", "47"],
	["Nova Caledônia", "687"],
	["Nova Zelândia", "64"],
	["Níger", "227"],
	["Omã", "968"],
	["Ouvido e Ilhas McDonald", "672"],
	["Palau", "680"],
	["Palestina", "970"],
	["Panamá", "507"],
	["Papua Nova Guiné", "675"],
	["Paquistão", "92"],
	["Paraguai", "595"],
	["Países Baixos", "31"],
	["Países Baixos Caribenhos", "599"],
	["Peru", "51"],
	["Polinésia Francesa", "689"],
	["Polônia", "48"],
	["Porto Rico", "1"],
	["Portugal", "351"],
	["Principado de Mônaco", "377"],
	["Qatar", "974"],
	["Quirguizistão", "996"],
	["Quênia", "254"],
	["Reino Unido", "44"],
	["República Centro-Africana", "236"],
	["República Democrática do Congo", "243"],
	["República Dominicana", "1-809"],
	["República do Congo", "242"],
	["Romênia", "40"],
	["Ruanda", "250"],
	["Rússia", "7"],
	["Saara Ocidental", "212"],
	["Samoa", "685"],
	["Samoa Americana", "1-684"],
	["Santa Helena, Ascensão e Tristão da Cunha", "290n"],
	["Santa Lúcia", "1-758"],
	["Santo Bartolomeu", "590"],
	["Senegal", "221"],
	["Serra Leoa", "232"],
	["Seychelles", "248"],
	["Sint Maarten", "1-721"],
	["Somália", "252"],
	["Sri Lanka", "94"],
	["St. Kitts e Nevis", "1-869"],
	["Sudão", "249"],
	["Sudão do Sul", "211"],
	["Suriname", "597"],
	["Suécia", "46"],
	["Suíça", "41"],
	["Svalbard e Jan Mayen", "47"],
	["São Marino", "378"],
	["São Martinho", "590"],
	["São Pedro e Miquelon", "508"],
	["São Tomé e Príncipe", "239"],
	["São Vicente e as Granadinas", "1-784"],
	["Sérvia", "381p"],
	["Síria", "963"],
	["Tailândia", "66"],
	["Taiwan", "886"],
	["Tajiquistão", "992"],
	["Tanzânia", "255"],
	["Tcheca", "420"],
	["Território Britânico do Oceano Índico", "246"],
	["Territórios Franceses do Sul e Antártico", "262"],
	["Timor Leste", "670"],
	["Togo", "228"],
	["Tokelau", "690"],
	["Tonga", "676"],
	["Trinidad e Tobago", "1-868"],
	["Tunísia", "216"],
	["Turcomenistão", "993"],
	["Turquia", "90"],
	["Tuvalu", "688"],
	["Ucrânia", "380"],
	["Uganda", "256"],
	["Uruguai", "598"],
	["Uzbequistão", "998"],
	["Vanuatu", "678"],
	["Venezuela", "58"],
	["Vietnã", "84"],
	["Wallis e Futuna", "681"],
	["Zimbábue", "263"],
	["Zâmbia", "260"],
	["África do Sul", "27"],
	["Áustria", "43"],
	["Índia", "91"],

]
