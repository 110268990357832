import { useContext } from "react"
import styles from "./Index.module.scss"
import { LanguageContext } from "../../../context/useLanguageContext"

export default () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles[`trieds1`]}>
            <div className={styles[`trieds1-text`]}>
             {t('Confident Strides')}
            </div>
        </div>
    )
}


