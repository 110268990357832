import styles from "./Index.module.scss"

export default () => {
    return (
        <div className={styles[`trieds3`]}>
        </div>
    )
}


