import styles from "./PrivacyPolicy.module.scss"

import Header from "../../components/layout/Header"
import Footer from "../footer/Footer"
import { useEffect, useRef } from "react"

export default () => {

    const pageRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        document.scrollingElement?.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <div ref={pageRef}>
            <Header white={true} bottom={true} className={styles['privacyPolicy__header']}/>
            <div className={styles['privacyPolicy']}>
                <div className={styles['privacyPolicy-1']}>
                    <div>
                        <span>Privacy Policy</span>
                        <span>LAST UPDATED November 2024</span>
                    </div>
                    <span>
                    SuperAuthenti Co., Limited and our affiliated companies (referred to as "we", "our" or "us") are a gaming company that develops, produces, distributes mobile games and computer games.
This privacy policy ("Privacy Policy") outlines how we collect, use, and share data through our Services, including our computer games, mobile games, websites, third-party platforms, and other products or services we provide.
Please read this Privacy Policy carefully to understand how we use and protect your data. By using our Services, you consent to the terms and conditions of this Privacy Policy.
If you do not want SuperAuthenti to collect, store, use, or share your personal information in the ways described in this Privacy Policy, you may not play our games or use our Services.
If you have questions or concerns about our Privacy Policy or practices, please contact us at privacy@authentigame.com.
                    </span>
                </div>
                <div className={styles['privacyPolicy-2']}>
                    <div className={styles['privacyPolicy-2-title']}>
                        <span className={styles['privacyPolicy-2-title-text']}>1. INTRODUCTION</span>
                    </div>
                    <div className={styles['privacyPolicy-2-content']}>
                        <span className={styles['privacyPolicy-2-content-text']}>
                            Our Privacy Policy applies to all individuals who use our "Services" which include our games, products, services, content, websites, and other domains or websites operated by SuperAuthenti. It outlines how we collect and use your personal data and explains your rights. Regardless of whether you are located within or outside of the European Union or European Economic Area, SuperAuthenti is considered a "joint data controller" of your personal data. In our Privacy Policy, we refer to countries within the European Union (EU), European Economic Area (EEA), and Switzerland as the "European Countries".
                        </span>
                    </div>
                </div>
                <div className={styles['privacyPolicy-3']}>
                    <div className={styles['privacyPolicy-3-title']}>
                        <span className={styles['privacyPolicy-3-title-text']}>2. DATA COLLECTION</span>
                    </div>
                    <div className={styles['privacyPolicy-3-content']}>
                        <div className={styles['privacyPolicy-3-content-1']}>
                            <span className={styles['privacyPolicy-3-content-1-text']}>
                            Our goal is to collect as little personal information as possible from users when they play our games or use our websites. The information we collect does not personally identify you, but it may provide a unique identifier for the device you are using to play the games or access the websites ("Device").
                            </span>
                        </div>
                        <div className={styles['privacyPolicy-3-content-2']}>
                            <div className={styles['content']}>
                                <span className={styles['content-title']}>2.1 Data about you that you give us directly</span>
                                <span className={styles['content-text']}>
                                When you use our games, you may provide us with data directly, such as when you set up your account. We store this data on our databases and use it for the purposes outlined in our Privacy Policy.
                                The data you may provide while using our games includes:
                                </span>
                                <div className={styles['content-list']}>
                                    <div>Player name and gender</div>
                                    <div>Profile information (such as profile photo)</div>
                                    <div>Chat log when you contact us for support</div>
                                    <div>Other information that helps us make sure it is you accessing your account or helps us providing support to you. Please see the “Support correspondence” section for more detail.</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles['privacyPolicy-3-content-2']}>
                            <div className={styles['content']}>
                                <span className={styles['content-title']}>2.2 Data generated using our games</span>
                                <span className={styles['content-text']}>
                                As an individual availing of our gaming services, it is essential to note that we gather extensive information regarding your interaction and engagement with our game offerings. This data encompasses elements such as the duration and frequency of your game activity, the specific game features and other players you interact with, any purchases made, progress or advancement achieved, and other gameplay activities. It is important to highlight that should you opt to utilize communication features within our services to facilitate information exchange or engagement with fellow players, we shall collect pertinent information concerning such interactions and communications.
                                </span>
                            </div>
                        </div>
                        <div className={styles['privacyPolicy-3-content-2']}>
                            <div className={styles['content']}>
                                <span className={styles['content-title']}>2.3 Payment data</span>
                                <span className={styles['content-text']}>
                                In addition to the aforementioned data collection practices, we also collect information associated with any purchases you make while utilizing our gaming services. If you make a purchase of in-game virtual currency, our trusted third-party payment partners will gather any essential billing and financial information required to facilitate and process your charges. This may include your postal address, e-mail address, and respective financial data. It is important to highlight that our payment partners never disclose your financial information, such as credit card numbers, to us.
                                </span>
                                <span className={styles['content-text']}>
                                When using our games in conjunction with third-party applications or platforms, such as Facebook, Apple, or Google, any purchases made will be processed by the third-party application in question, subject to their unique terms of service and privacy policies. As a result, SuperAuthenti will not receive any financial data; however, we may obtain non-financial information regarding your purchases, such as the specific items purchased and order number. The extent of information we obtain is dependent on the specific game being played and the corresponding third-party application. Our comprehensive Terms of Use delineate our policies and conditions related to billing, charges, virtual currencies, and third-party credits. Please note that purchases of third-party credits or currencies are also subject to the terms of service and privacy policies of the respective third-party entity.
                                </span>
                            </div>
                        </div>
                        <div className={styles['privacyPolicy-3-content-2']}>
                            <div className={styles['content']}>
                                <span className={styles['content-title']}>2.4 Other Data we automatically collect</span>
                                <div className={styles['content-list']}>
                                    <div>The advertising ID is a distinctive identifier assigned to your mobile device or tablet that is utilized by marketers to track your preferences and display targeted ads.</div>
                                    <div>Basic location details (such as your country and city) will be shown on our multiplayer screen when you engage with the game.</div>
                                    <div>Device details (such as device name, operating system details, and language preferences) are also collected.</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles['privacyPolicy-3-content-2']}>
                            <div className={styles['content']}>
                                <span className={styles['content-title']}>2.5 Support correspondence</span>
                                <span className={styles['content-text']}>
                                When you seek help from our support team, we may need to collect certain information to provide adequate assistance. This includes your contact details, such as your name, email address, and player ID, alongside data on your gameplay or activity in the relevant game. We store all communication with our support team, along with any additional information necessary to resolve your query and enhance our services. It's important to note that we may require a minimum amount of data, such as your player ID, to identify you and provide the necessary support. Our support software automatically collects certain data, such as your Google Play email address and device information, to improve our assistance with troubleshooting. This information is securely transferred to processors' servers in the United Kindom, under strict contractual agreements that protect your privacy and ensure our partners handle your data only as we direct. We also apply data minimization principles to obtain only essential data, with a goal of improving our customer service and response times. We store the content of your request as long as you remain an active player, to facilitate more rapid resolution of future issues.
                                </span>
                            </div>
                        </div>
                        <div className={styles['privacyPolicy-3-content-2']}>
                            <div className={styles['content']}>
                                <span className={styles['content-title']}>2.6 Data about you from third-party partners</span>
                                <span className={styles['content-text']}>
                                If you log in to our games using your Facebook, Apple, or Google account, we may gather information from those companies. Moreover, data about you may come from third-party platforms like Google Play, which depends on the game, your privacy preferences, and the platform provider. This data includes your profile picture, social network ID, email address, anonymous demographics, and advertising and analytics data. Before using any third-party platform to access our games, it's crucial to read and understand the platform's terms of use and privacy policy.
                                </span>
                            </div>
                        </div>
                        <div className={styles['privacyPolicy-3-content-2']}>
                            <div className={styles['content']}>
                                <span className={styles['content-title']}>2.7 Cookies</span>
                                <span className={styles['content-text']}>
                                Just like other online platforms, we and our partners may use cookies or other similar technologies to analyze data and tailor our Service to your individual needs. While you have the option to disable cookies in your browser, please note that doing so may affect your ability to fully utilize our Service.
                                </span>
                            </div>
                        </div>
                        <div className={styles['privacyPolicy-3']}>
                            <div className={styles['privacyPolicy-3-title']}>
                                <span className={styles['privacyPolicy-3-title-text']}>3. USE OF DATA AND LEGAL GROUNDS FOR PROCESSING</span>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    We collect and store your data for the primary purpose of delivering the Services you require from us, and to optimize your gameplay experience. Nevertheless, it should be noted that there are other reasons for gathering and storing your data, such as:
                                    </span>
                                </div>
                                <div className={styles['content-list']}>
                                    <div>to enhance our games and your gaming experience.</div>
                                    <div>to create and manage player accounts for our game access.</div>
                                    <div>to customize our Services and recommend relevant connections to you.</div>
                                    <div>to keep you informed about game updates, new services, and promotions that may interest you. to allow players to communicate with each other.</div>
                                    <div>to provide support and respond to player requests.</div>
                                    <div>to ensure a just and secure atmosphere for our multiplayer functionalities.</div>
                                    <div>to oversee and provide customized advertising.</div>
                                    <div>to manage and execute promotional initiatives or events that we or our partners sponsor or organize.</div>
                                    <div>to fulfill our legal responsibilities, settle any disputes that may arise between you or other players, and uphold our contracts with third parties.</div>
                                </div>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    Our collection and use of your personal data will depend on various factors, such as the type of data we're collecting and the context in which we're collecting it. In general, we will only collect data from you when we need it to fulfill our contract with you as outlined in our Terms of Use, when we have a legitimate interest in processing the data (provided that such interest does not override your rights or interests), or when you have given us your consent.
                                    </span>
                                </div>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    In some instances, we may also have a legal obligation to collect your data or may need to do so to protect your vital interests or those of another individual, such as another player. When we require you to provide data to comply with a legal obligation or fulfill our contract with you, we will make this clear at the time of collection. If it's not immediately obvious why we need certain information, we will provide an explanation.
                                    </span>
                                </div>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    If we collect and use your personal data based on our legitimate interests or those of a third party, we will communicate these interests to you at the time of collection. For instance, we may use your data to personalize our services or conduct direct marketing campaigns.
                                    </span>
                                </div>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    If you have any questions or need more information about the legal basis for our data collection and use, please don't hesitate to contact us at privacy@authentigame.com at any time.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={styles['privacyPolicy-3']}>
                            <div className={styles['privacyPolicy-3-title']}>
                                <span className={styles['privacyPolicy-3-title-text']}>4. DATA SHARING</span>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    To enhance your interactions with other players while playing our game, we may share certain elements of your data with your friends and fellow players. Moreover, we may also share your data with third parties or enable such parties to gather this information from our games in the following ways:
                                    </span>
                                </div>
                                <div className={styles['privacyPolicy-3-content-2']}>
                                    <div className={styles['content']}>
                                        <span className={styles['content-title']}>4.1 Friends and other players</span>
                                        <span className={styles['content-text']}>
                                        Our games support encourage you to interact with other players. In most games, your friends who also play our game will see your player name, photo and your level and rank, but not details of your player profile.
                                        </span>
                                    </div>
                                </div>
                                <div className={styles['privacyPolicy-3-content-2']}>
                                    <div className={styles['content']}>
                                        <span className={styles['content-title']}>4.2 Third-party analytics and advertising</span>
                                        <span className={styles['content-text']}>
                                        For a complete list of our third party providers and partners please see below.
                                        </span>
                                        <span className={styles['content-text']}>
                                        As an online business, it is important to us to know how players interact with the Game and interact with each other. We use third party Analytics Partners to collect location information, device information and advertising ID so we can measure, analyze and improve our Games and Services. It is also important to us to keep the Game fair for multiplayer sessions and we cannot deliver this without analytics.
                                        </span>
                                        <span className={styles['content-text']}>
                                        We also share some of your data with advertisers to tailor the advertisements you see when you use our Services to your interests. We use advertising to enable us to offer some of our games for free. These advertisements may include, without limitation, a full-screen ads in between some gameplay for a short period or in-game items offered to players in exchange for watching a video advertisement for a longer period.
                                        </span>
                                        <span className={styles['content-text']}>
                                        We do not actively share personal data that directly identifies you with third-party advertisers for their direct marketing purposes unless you give us your consent.
                                        </span>
                                        <span className={styles['content-text']}>
                                        To enable advertisers and ad media partners to serve you with more relevant ads (a practice known as interest-based advertising or behavioral advertising), they may collect, or we may collect and share with them, the following types of data when you use our games:
                                        </span>
                                    </div>
                                    <div className={styles['content-list']}>
                                        <div>A unique device identifier called Advertising ID</div>
                                        <div>Location information</div>
                                        <div>Device information</div>
                                    </div>
                                    <div className={styles['content']}>
                                        <span className={styles['content-text']}>
                                        You can review in more detail what our advertising service providers collect from our list of partners below.
                                        </span>
                                        <span className={styles['content-text']}>
                                        If you do want to receive interest based in-application advertisements from third parties, your mobile device could offer your ability to opt-out for all apps on your device. On Android devices, you may opt-out by enabling the "Opt out of Ads Personalization" setting. On iOS devices, you may opt-out by enabling the "Limit Ad Tracking" setting. If you want to opt-out from specific advertising partner please review our information in the full partner list. If you just want to opt-out interest based advertising within our Game, please contact us at developer@authentigame.com. Please note that if you opt out interest based advertising, you will still receive contextual ads which are related to the Game you’re playing not based on your interests.
                                        </span>
                                    </div>
                                </div>

                                <div className={styles['privacyPolicy-3-content-2']}>
                                    <div className={styles['content']}>
                                        <span className={styles['content-title']}>4.3 Security measures</span>
                                        <span className={styles['content-text']}>
                                        We share your data if necessary for safety, security, or compliance with law. Your data and the contents of all of your online communications in our games and between you and SuperAuthenti may be accessed and monitored as needed to provide our games and may be disclosed:
                                        </span>
                                    </div>
                                    <div className={styles['content-list']}>
                                        <div>to satisfy any laws or regulations that apply</div>
                                        <div>when we have to disclose the information in response to lawful requests by public authorities</div>
                                        <div>if our Games are being used in committing a crime or to share data with other companies and organizations for the purposes of fraud protection and other security precautions</div>
                                        <div>to protect the rights or property of SuperAuthenti or applicable third parties.</div>
                                    </div>
                                </div>

                                <div className={styles['privacyPolicy-3-content-2']}>
                                    <div className={styles['content']}>
                                        <span className={styles['content-title']}>4.4 Other third parties</span>
                                        <span className={styles['content-text']}>
                                        In addition to the sharing described above, we may share your data with other third parties or allow them to collect your information from our Services in some ways not specifically described in this Privacy Policy if we have your consent to do so.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles['privacyPolicy-3']}>
                            <div className={styles['privacyPolicy-3-title']}>
                                <span className={styles['privacyPolicy-3-title-text']}>5. CHILDREN'S PERSONAL INFORMATION</span>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    We do not knowingly collect children's personal information without parental consent. If you’re a child under 13 please do not provide name, address, phone number or email address that can personally identify you. If you are a parent of a child playing the Games or using our Services, you may review or have your child's personal information deleted and refuse the further collection of his/her personal information by contacting us at privacy@authentigame.com.
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div className={styles['privacyPolicy-3']}>
                            <div className={styles['privacyPolicy-3-title']}>
                                <span className={styles['privacyPolicy-3-title-text']}>6. YOUR RIGHTS</span>
                            </div>
                            <div className={styles['privacyPolicy-3-content-2']}>
                                <div className={styles['content']}>
                                    <span className={styles['content-title']}>6.1 Accessing and deleting personal data held by SuperAuthenti</span>
                                    <span className={styles['content-text']}>
                                    You can request a copy of or deletion of your game account data through our game. You can contact us through in-game chats or at privacy@authentigame.com. We will aim to satisfy your request within 30 days, and if we cannot do so we will let you know.
                                    </span>
                                </div>
                            </div>
                            <div className={styles['privacyPolicy-3-content-2']}>
                                <div className={styles['content']}>
                                    <span className={styles['content-title']}>6.2 Opting In or Out of marketing communication with SuperAuthenti</span>
                                    <span className={styles['content-text']}>
                                    You can unsubscribe from promotional emails by clicking the “unsubscribe” link in our marketing emails. You can opt out of mobile push notifications in your device’s settings page.
If you want to stop receiving push notifications, which are messages an app sends you on your mobile device even when the app is not on, you can turn of push notifications by visiting your mobile device’s “Settings”. Many of our games also offer the ability to turn off push notifications in the in-game “Settings”. They’re usually titled as “reminders” in our Settings menu.
                                    </span>
                                    <span className={styles['content-text']}>
                                    Please note that if you opt in or out of receiving marketing communications from us, we may still communicate with you from time to time if we need to provide you with information about the games you are using from us, if we need to request information from you with respect to a transaction initiated by you, or for other legitimate non-marketing reasons, for example, if we update this Privacy Policy, our Terms of Use or SuperAuthenti End User License Agreement.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className={styles['privacyPolicy-3']}>
                            <div className={styles['privacyPolicy-3-title']}>
                                <span className={styles['privacyPolicy-3-title-text']}>7. PRIVACY POLICIES OF LINKED THIRD-PARTY SERVICES</span>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    We are not responsible for the ways in which third parties use your data (other than our partners acting under our guidance). Our Websites and Games may contain advertisements from third-party services that may link to their own websites or services. The full list of third party services and their privacy policies are located below.
                                    </span>
                                </div>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    We are not responsible for the privacy practices or the content of these parties. If you have any questions about how these entities use your data, you should review their policies and contact them directly.
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div className={styles['privacyPolicy-3']}>
                            <div className={styles['privacyPolicy-3-title']}>
                                <span className={styles['privacyPolicy-3-title-text']}>8. TRANSFER OF DATA</span>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    When you use our games, your personal data may be sent outside of the European Countries, for example, to the United States and possibly other countries. When we transfer, store, and use personal data collected in the European Countries outside of the European Countries, we comply with our legal obligations and ensure that your personal data is subject to suitable safeguards (such as encrypting communication) or transferred to “approved” territories.
                                    </span>
                                </div>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    As a global company, we may transfer your personal data to our “Affiliated Entities,” located in European Countries and outside like United States and possibly other countries which are any other corporate entities under common ownership with SuperAuthenti, or to our business partners and service providers from time to time for our legitimate business purposes. For example, SuperAuthenti provides certain services to its Affiliates.
                                    </span>
                                </div>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    We store personal data about players and visitors to our sites on servers located in the United Kindom, and we may also store this information on servers and equipment in other countries. Personal information collected in the European Countries may be transferred to, and stored at, a destination outside of the European Countries. The data we hold may also be processed by employees operating outside of the European Countries who work for us, one of our Affiliated Entities, or one of our business partners or service providers. This staff may be engaged in, among other things, marketing communication with players and product development. When we transfer your personal data internationally we will take reasonable steps to ensure that your data is treated securely, lawfully, and in the manner we describe here. Please note that laws vary from jurisdiction to jurisdiction, so the privacy laws applicable to the places where your data is transferred to or stored, used, or processed in, may be different from the privacy laws applicable to the place where you are resident.
                                    </span>
                                </div>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    Additionally, if we share with entities which are not subject to the Privacy Shield framework, and we make sure that these entities ensure an adequate level of protection, the transfer is supported by the European Commission’s standard contractual clauses for the transfer of personal data to a country outside the European Countries.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className={styles['privacyPolicy-3']}>
                            <div className={styles['privacyPolicy-3-title']}>
                                <span className={styles['privacyPolicy-3-title-text']}>9. DATA RETENTION</span>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    We’ll keep your information for as long as necessary to provide you with the Services, maintain our legitimate business operations, and/or exercise, defend or establish our rights.
How long we retain your personal information depends on why we collected it and how we use it, but we will not retain your personal information for longer than is necessary for our business purposes or for legal requirements.
                                    </span>
                                </div>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    We will retain personal information about you and connected with your account and/or the Services you use from us for as long as you have an active account with us. We will take reasonable measures to delete this personal information if you delete your account. However, you acknowledge that we may retain some information after you have closed, or we have deleted, your account with us where necessary to enable us to meet our legal obligations or to exercise, defend, or establish our rights.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className={styles['privacyPolicy-3']}>
                            <div className={styles['privacyPolicy-3-title']}>
                                <span className={styles['privacyPolicy-3-title-text']}>10. SECURITY OF YOUR INFORMATION</span>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    We work to protect your information but you as a player also have to take certain steps.
                                    </span>
                                </div>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    We implement reasonable and appropriate security measures to help protect the security of your data and to ensure that your data is treated securely and in accordance applicable laws.
                                    </span>
                                </div>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    It is important that you protect and maintain your SuperAuthenti account’s security and that you immediately tell us of any unauthorized use of your account. If you access our Services through Facebook and your account is hacked or otherwise compromised, this may lead to someone accessing or deleting your game accounts without your permission. So, be careful to keep your Facebook account information, including your social network account password, secure as well. We urge you to log out of your SuperAuthenti account and any social network account you have used to access our Services when you are finished using them.
                                    </span>
                                </div>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    If you have a customer service-related issue, please contact us through in-game chat.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className={styles['privacyPolicy-3']}>
                            <div className={styles['privacyPolicy-3-title']}>
                                <span className={styles['privacyPolicy-3-title-text']}>11. CHANGES TO OUR PRIVACY POLICY</span>
                            </div>
                            <div className={styles['privacyPolicy-3-content']}>
                                <div className={styles['privacyPolicy-3-content-1']}>
                                    <span className={styles['privacyPolicy-3-content-1-text']}>
                                    We let you know about any material changes to our Privacy Policy. If we decide to make material changes to our Privacy Policy, we will tell you and other players by placing a notice in-game or on our website or by sending you a notice to your e-mail address we have on file for you before the change becomes effective (and we will ask for your consent where required by law).
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}


