import { createContext, useEffect, useRef, useState } from "react"
import { useParams } from 'react-router-dom';
import Header from "../../components/layout/Header"
import Trieds1 from "./trieds1/Index";
import Trieds2 from "./trieds2/Index";
import Trieds3 from "./trieds3/Index";
import Footer from "../footer/Footer"
import styles from "./Detail.module.scss"

const stepStyles = [
  "translateY(0)",
  "translateY(-100vh)",
  "translateY(-100vh)",
  "translateY(-200vh)",
  "translateY(calc(-200vh - 482px))",
]

interface TriedContextDTO{
  step: number
}

export const TriedContext = createContext<TriedContextDTO>({step: 0})

let lastTime = 0
export default () => {
    
  const [footerHeight, setFooterHeight] = useState<number>(0)
  const footerRef = useRef<HTMLDivElement>(null)
  // const [lastTime, setLastTime] = useState(0)
  let [step, setStep] = useState(() => 0)
  const handleWheel = (event: any) => {

    var currentTime = Date.now()
    if (currentTime - lastTime <= 1300) {
      return
    }
    lastTime = currentTime
    // setLastTime(current => {
    //   return currentTime
    // })

    const delta = event.deltaY; // deltaY值为正表示向下滚动，为负表示向上滚动
    const down = delta > 0

    if(down){
      setStep(current => {
        const step = current + 1
        return Math.min(step, 4)
      })
    }else{
      setStep(current => {
        const step = current - 1
        return Math.max(0, step)
      })
    }
    
  };

  // useEffect(() => {

    // const keydownHandle = (e: any) => {
    //   console.log("key press: ", e.keyCode)
    //   const keyCode = e.keyCode
    //   if(keyCode == 115 || keyCode == 119){
    //     let down = keyCode == 115
    //     console.log("page down: ", down)
    //     handleWheel({deltaY: down?1:-1})
    //   }
    // }

    //   // 添加鼠标滚轮事件监听器
    //   window.addEventListener('keypress', keydownHandle);
  
    //   // // 清理函数，移除事件监听器
    //   return () => {
    //     window.removeEventListener('keypress', keydownHandle);
    //   };
    // }, []);

  const getStyle = () => {
    let translateOffset = `${stepStyles[step]}`
      if(step == 4){
        translateOffset = `translateY(calc(-200vh - ${footerHeight}px))`
      }
      return {
        transform: translateOffset
      }
  }

  useEffect(() => {
    const _footerHeight = footerRef.current?.getBoundingClientRect().height??0
    setFooterHeight(_footerHeight)
  }, [])

    return (
      <TriedContext.Provider value={{step}}>
          <div className={styles['homepage']}>
            <Header  white={true}/>
            <div className={styles['homepage-wrapper']} onWheel={handleWheel} style={getStyle()}>
              <Trieds1/>
              <Trieds2/>
              <Trieds3/>
              <div ref={footerRef}><Footer/></div>
              </div>
          </div>
      </TriedContext.Provider>
    )
}


