
export default [
	["Afghanistan", "93"],
	["Albania", "355"],
	["Algeria", "213"],
	["Andorra", "376"],
	["Angola", "244"],
	["Anguilla", "1-264"],
	["Antartide", "672"],
	["Antigua e Barbuda", "1-268"],
	["Arabia Saudita", "966"],
	["Argentina", "54"],
	["Armenia", "374"],
	["Aruba", "297"],
	["Australia", "61"],
	["Austria", "43"],
	["Azerbaigian", "994"],
	["Bahamas", "1-242"],
	["Bahrein", "973"],
	["Bangladesh", "880"],
	["Barbados", "1-246"],
	["Belgio", "32"],
	["Belize", "501"],
	["Benin", "229"],
	["Bermuda", "1-441"],
	["Bhutan", "975"],
	["Bielorussia", "375"],
	["Birmania", "95"],
	["Bolivia", "591"],
	["Bosnia ed Erzegovina", "387"],
	["Botswana", "267"],
	["Brasile", "55"],
	["Brunei", "673"],
	["Bulgaria", "359"],
	["Burkina Faso", "226"],
	["Burundi", "257"],
	["Cambogia", "855"],
	["Camerun", "237"],
	["Canada", "1"],
	["Capo Verde", "238"],
	["Ciad", "235"],
	["Cile", "56"],
	["Cina", "86"],
	["Cipro", "357"],
	["Città del Vaticano", "39-06"],
	["Colombia", "57"],
	["Comore", "269"],
	["Corea del Nord", "850"],
	["Corea del Sud", "82"],
	["Costa Rica", "506"],
	["Costa d'Avorio", "225"],
	["Croazia", "385"],
	["Cuba", "53"],
	["Curaçao", "599"],
	["Danimarca", "45"],
	["Dominica", "1-767"],
	["Ecuador", "593"],
	["Egitto", "20"],
	["El Salvador", "503"],
	["Emirati Arabi Uniti", "971"],
	["Eritrea", "291"],
	["Estonia", "372"],
	["Etiopia", "251"],
	["Figi", "679"],
	["Filippine", "63"],
	["Finlandia", "358"],
	["Francia", "33"],
	["Fær Øer", "298"],
	["Gabon", "241"],
	["Gambia", "220"],
	["Georgia", "995"],
	["Georgia del Sud e Isole Sandwich Australi", "500"],
	["Germania", "49"],
	["Ghana", "233"],
	["Giamaica", "1-876"],
	["Giappone", "81"],
	["Gibilterra", "350"],
	["Gibuti", "253"],
	["Giordania", "962"],
	["Grecia", "30"],
	["Grenada", "1-473"],
	["Groenlandia", "299"],
	["Guadalupa", "590"],
	["Guam", "1-671"],
	["Guatemala", "502"],
	["Guernsey", "44"],
	["Guinea", "224"],
	["Guinea Equatoriale", "240"],
	["Guinea-Bissau", "245"],
	["Guyana", "592"],
	["Guyana francese", "594"],
	["Haiti", "509"],
	["Honduras", "504"],
	["Hong Kong", "852"],
	["India", "91"],
	["Indonesia", "62"],
	["Iran", "98"],
	["Iraq", "964"],
	["Irlanda", "353"],
	["Islanda", "354"],
	["Isola Bouvet", "47"],
	["Isola Norfolk", "672"],
	["Isola di Man", "44"],
	["Isola di Natale", "61"],
	["Isole BES", "599"],
	["Isole Cayman", "1-345"],
	["Isole Cocos (Keeling)", "61"],
	["Isole Cook", "682"],
	["Isole Falkland", "500"],
	["Isole Heard e McDonald", "672"],
	["Isole Marianne Settentrionali", "1-670"],
	["Isole Marshall", "692"],
	["Isole Pitcairn", "870"],
	["Isole Salomone", "677"],
	["Isole Vergini Americane", "1-340"],
	["Isole Vergini Britanniche", "1-284"],
	["Isole minori esterne degli Stati Uniti", ""],
	["Isole Åland", "358"],
	["Israele", "972"],
	["Italia", "39"],
	["Jersey", "44"],
	["Kazakistan", "7"],
	["Kenya", "254"],
	["Kirghizistan", "996"],
	["Kiribati", "686"],
	["Kuwait", "965"],
	["La Riunione", "262"],
	["Laos", "856"],
	["Lesotho", "266"],
	["Lettonia", "371"],
	["Libano", "961"],
	["Liberia", "231"],
	["Libia", "218"],
	["Liechtenstein", "423"],
	["Lituania", "370"],
	["Lussemburgo", "352"],
	["Macao", "853"],
	["Macedonia del Nord", "389"],
	["Madagascar", "261"],
	["Malawi", "265"],
	["Malaysia", "60"],
	["Maldive", "960"],
	["Mali", "223"],
	["Malta", "356"],
	["Marocco", "212"],
	["Martinica", "596"],
	["Mauritania", "222"],
	["Mauritius", "230"],
	["Mayotte", "262"],
	["Messico", "52"],
	["Micronesia", "691"],
	["Moldavia", "373"],
	["Monaco", "377"],
	["Mongolia", "976"],
	["Montenegro", "382"],
	["Montserrat", "1-664"],
	["Mozambico", "258"],
	["Namibia", "264"],
	["Nauru", "674"],
	["Nepal", "977"],
	["Nicaragua", "505"],
	["Niger", "227"],
	["Nigeria", "234"],
	["Niue", "683"],
	["Norvegia", "47"],
	["Nuova Caledonia", "687"],
	["Nuova Zelanda", "64"],
	["Oman", "968"],
	["Paesi Bassi", "31"],
	["Pakistan", "92"],
	["Palau", "680"],
	["Palestina", "970"],
	["Panama", "507"],
	["Papua Nuova Guinea", "675"],
	["Paraguay", "595"],
	["Perù", "51"],
	["Polinesia francese", "689"],
	["Polonia", "48"],
	["Porto Rico", "1"],
	["Portogallo", "351"],
	["Qatar", "974"],
	["RD del Congo", "243"],
	["Regno Unito", "44"],
	["Rep. Ceca", "420"],
	["Rep. Centrafricana", "236"],
	["Rep. Dominicana", "1-809"],
	["Rep. del Congo", "242"],
	["Romania", "40"],
	["Ruanda", "250"],
	["Russia", "7"],
	["Sahara Occidentale", "212"],
	["Saint Kitts e Nevis", "1-869"],
	["Saint Lucia", "1-758"],
	["Saint Vincent e Grenadine", "1-784"],
	["Saint-Barthélemy", "590"],
	["Saint-Martin", "590"],
	["Saint-Pierre e Miquelon", "508"],
	["Samoa", "685"],
	["Samoa Americane", "1-684"],
	["San Marino", "378"],
	["Sant'Elena, Ascensione e Tristan da Cunha", "290n"],
	["Senegal", "221"],
	["Serbia", "381p"],
	["Seychelles", "248"],
	["Sierra Leone", "232"],
	["Singapore", "65"],
	["Sint Maarten", "1-721"],
	["Siria", "963"],
	["Slovacchia", "421"],
	["Slovenia", "386"],
	["Somalia", "252"],
	["Spagna", "34"],
	["Sri Lanka", "94"],
	["Stati Uniti", "1"],
	["Sudafrica", "27"],
	["Sudan", "249"],
	["Sudan del Sud", "211"],
	["Suriname", "597"],
	["Svalbard e Jan Mayen", "47"],
	["Svezia", "46"],
	["Svizzera", "41"],
	["São Tomé e Príncipe", "239"],
	["Tagikistan", "992"],
	["Taiwan", "886"],
	["Tanzania", "255"],
	["Terre australi e antartiche francesi", "262"],
	["Territorio britannico dell'Oceano Indiano", "246"],
	["Thailandia", "66"],
	["Timor Est", "670"],
	["Togo", "228"],
	["Tokelau", "690"],
	["Tonga", "676"],
	["Trinidad e Tobago", "1-868"],
	["Tunisia", "216"],
	["Turchia", "90"],
	["Turkmenistan", "993"],
	["Turks e Caicos", "1-649"],
	["Tuvalu", "688"],
	["Ucraina", "380"],
	["Uganda", "256"],
	["Ungheria", "36"],
	["Uruguay", "598"],
	["Uzbekistan", "998"],
	["Vanuatu", "678"],
	["Venezuela", "58"],
	["Vietnam", "84"],
	["Wallis e Futuna", "681"],
	["Yemen", "967"],
	["Zambia", "260"],
	["Zimbabwe", "263"],
	["eSwatini", "268"],

]
