import { MouseEventHandler, useContext, useEffect, useRef, useState } from "react"
import styles from "./P4.module.scss"

import CardSvg from './assets/images/card.svg'
import Product1 from './assets/images/products_list_1.png'
import Product2 from './assets/images/products_list_2.png'
import Product3 from './assets/images/products_list_3.png'
import Product4 from './assets/images/products_list_4.png'
import Product5 from './assets/images/products_list_5.png'
import SunglassesVideo from "./assets/videos/Sunglasses.mp4"
import HatVideo from "./assets/videos/Hat.mp4"
import EarringVideo from "./assets/videos/Earring.mp4"
import NecklaceVideo from "./assets/videos/Necklace.mp4"
import BagVideo from "./assets/videos/Bag.mp4"
import { Link, useNavigate } from "react-router-dom"
import { SwiperContext } from "../homepage/swiper/Swiper"
import Steps from "../homepage/Steps"
import { LanguageContext } from "../../context/useLanguageContext"
import { HomepageContext } from "../homepage/Homepage"

import Maidian, { MaidianEventType } from "../../api/maidian"

const recommends = [
    {
        icon: Product2,
        video: HatVideo,
        label: "Hat"
    },
    {
        icon: Product1,
        video: SunglassesVideo,
        label: "Sunglasses"
    },
    {
        icon: Product3,
        video: EarringVideo,
        label: "Earrings"
    },
    {
        icon: Product4,
        video: NecklaceVideo,
        label: "Necklace"
    },
    {
        icon: Product5,
        video: BagVideo,
        label: "Bag"
    }
]

export default () => {

    const {
        maidianEventSend,
      } = Maidian

    const {translate: t} = useContext(LanguageContext)

    const navigate = useNavigate()
    const {step: current} = useContext(HomepageContext)
    const [autoplayIdx, setAutoplayIdx] = useState<number>(-1)
    const p4Ref = useRef<HTMLDivElement>(null)

    const triggerAutoplay = () => {
        const nextIdx = (autoplayIdx + 1) % recommends.length
        setAutoplayIdx(nextIdx)
        // console.log(`P4 autoplayIdx: ${autoplayIdx}, nextIdx: ${nextIdx} `)
        const video = p4Ref.current?.querySelectorAll("video")[nextIdx] as HTMLVideoElement
        video?.play()
    }

    const closeAutoplay = () => {
        setAutoplayIdx(-1)
    }

    useEffect(() => {

        if(current == Steps.P4){
            triggerAutoplay()
        }else{
            closeAutoplay()
        }

    },  [current])


    const mouseEnterHandle = (e: any, idx: number) => {
        // const video = (e.currentTarget as HTMLDivElement).querySelector("video") as HTMLVideoElement
        // video?.play()
        // setAutoplayIdx(idx)
    }

    const mouseLeaveHandle = (e: any) => {
    }

    const stopHandle = (idx: number) => {
        if(current !== Steps.P4) return
        if(autoplayIdx != idx) return
        triggerAutoplay()
    }
//window.open("/p4/products", "_blank")
    return (
        <div ref={p4Ref} className={styles['p4']}>
            <div className={styles['p4__content']}>
                <div className={styles['p4__content-desc']}>
                    <label className={styles['p4__content-desc-title']}>{t('Meta Essentials')}</label>
                    <div className={styles['p4__content-desc-sub-title']} onClick={() => {navigate("/p4/products"), maidianEventSend(MaidianEventType.ENTER_SUB_PAGE, 4)}}>
                        {t('Discover All Accessories')}
                        <div className={styles['p4__content-desc-sub-title__divider']}></div>
                    </div>
                </div>
                <div className={styles['p4__content-card']}>
                    {recommends.map((recommend, idx) => (
                        <div key={idx} className={styles['p4__content-card-item']} onMouseEnter={(e) => mouseEnterHandle(e, idx)} onMouseLeave={mouseLeaveHandle}>
                            <video src={recommend.video} className={styles['p4__content-card-item-img']} muted onEnded={() => stopHandle(idx)} ></video>
                            <label className={styles['p4__content-card-item-desc']}>{t(recommend.label)}</label>
                        </div>
                    ))}
                    
                </div>
            </div>
        </div>
    )
}

export const CardList = () => {
    
    let list = []
    for (let i = 1; i <= 5; i ++) {
        list.push(
            <div className={styles['p4__content-card-item']} key={i}>
                <Link to="/p4/products">
                <img src={CardSvg} className={styles['p4__content-card-item--svg']}></img>
                </Link>
                <label className={styles['p4__content-card-item-desc']}>Collection {i+1}</label>
            </div>
        )
    }
    return (
        <>{list}</>
    )
}
