import { CSSProperties, PropsWithChildren, useContext, useEffect, useRef, useState } from "react"
import styles from "./Products.module.scss"

import ProductItem1Png from './assets/images/products_item_1.png'
import ProductItem2Png from './assets/images/products_item_2.png'
import ProductItem3Png from './assets/images/products_item_3.png'
import ProductItem4Png from './assets/images/products_item_4.png'
import ProductItem5Png from './assets/images/products_item_5.png'
import ProductItem6Png from './assets/images/products_item_6.png'
import ProductItem7Png from './assets/images/products_item_7.png'
import sp_3_1 from './assets/images/sp-3-1x.png'
import sp_5_1 from './assets/images/sp-5-1x.png'

import Header from "../../components/layout/Header"
import Footer from "../footer/Footer"
import { useNavigate, useParams } from "react-router-dom"
import { LanguageContext } from "../../context/useLanguageContext"


const types = [
    "All",
    "Sunglasses",
    "Hat",
    "Necklace",
    "Earrings",
    "Bag",
]

export default () => {

    const {translate: t} = useContext(LanguageContext)
    let [currentType, setCurrentType] = useState<String>("All")
    let [menuItemStyles, setMenuItemStyles] = useState<CSSProperties>()

    const changeType = (type: String) => {
        setCurrentType(type)

        const idx = types.findIndex(it => it === type)
        const firstMenuItem = document.querySelector(`#menuItem0`)?.getBoundingClientRect() as DOMRect
        const targetMenuItem = document.querySelector(`#menuItem${idx}`)?.getBoundingClientRect() as DOMRect
        const offsetX = targetMenuItem.left - firstMenuItem.left
        setMenuItemStyles({
            transform: `translateX(${offsetX}px)`,
            width: `${targetMenuItem.width}px`
        })

    }


    const itemRender = () => {
        const idx = types.findIndex(it => it === currentType)
        return (
            <>
            <ScrollView><ItemWraper activate={idx == 0} ><All/><Footer/></ItemWraper></ScrollView>
            <ScrollView><ItemWraper activate={idx == 1} ><Sunglasses/><Footer/></ItemWraper></ScrollView>
            
            <ScrollView><ItemWraper activate={idx == 2} ><Hat/><Footer/></ItemWraper></ScrollView>
            <ScrollView><ItemWraper activate={idx == 3} ><Necklace/><Footer/></ItemWraper></ScrollView>
            <ScrollView><ItemWraper activate={idx == 4} ><Earrings/><Footer/></ItemWraper></ScrollView>
            <ScrollView><ItemWraper activate={idx == 5} ><Bag/><Footer/></ItemWraper></ScrollView>
            </>
        )
        
    }

    const getStyle = () => {
        return menuItemStyles
    }

    const getContainerStyle = (): CSSProperties => {
        const idx = types.findIndex(it => it === currentType)
        return {
            transform: `translateX(calc(-100vw * ${idx}))`
        }
    }

    return (
        <div className={styles['products-scrollview']}>
            <div className={styles['products']}>
                <div className={styles['products__content']}>
                    <div className={styles['products__content-header']}>
                        <div className={styles['products__content-header-nav']}>
                        <Header white={true} goback={true}/>
                        </div>
                        <div className={styles['products__content-header-menu']}>
                            <div className={styles['products__content-header-menu-panel']}>
                                <span className={styles['products__content-header-menu-panel-title']}>{t('Products')}</span>
                                <div className={styles['products__content-header-menu-panel-list']}>
                                    {
                                        types.map((type, idx) => (
                                            <div 
                                                id={`menuItem${idx}`}
                                                className={[styles['products__content-header-menu-panel-list-item'], type === currentType?styles['products__content-header-menu-panel-list-item_hl']:''].join(" ")} 
                                                onClick={() => changeType(type)}
                                                >
                                                <span className={styles['products__content-header-menu-panel-list-item-text']}>{t(type)}</span>
                                            </div>
                                        ))
                                    }
                                    <div className={styles['products__content-header-menu-panel-list__ani-wrapper']}>
                                    <div className={styles['products__content-header-menu-panel-list__ani']} style={getStyle()}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles['products__content-item-container']} style={getContainerStyle()}>
                        {itemRender()}
                    </div>
                </div>
            </div>
        </div>
    )
}


const ItemWraper = (props: PropsWithChildren<{activate: boolean}>) => {

    const {
        activate,
    } = props

    return (
        <div className={[styles['products__content-item-wrapper'], activate?styles['products__content-item-wrapper_activate']:''].join(" ")}>
            {props.children}
            {/* <div className={[styles['products__content-item-scrollview']].join(" ")}>
             
            </div> */}
            
        </div>
    )
}

const All = () => {

    const {translate: t} = useContext(LanguageContext)

    return (
        <div className={styles['products__content-item']}>
            <div className={styles['products__content-item-left']}>
                <div className={styles['products__content-item-left-1']} >
                    <img className={styles['products__content-item-left-1-img']} src={ProductItem1Png}/>
                    <span className={styles['products__content-item-left-1-text']}>{t('Colored Sunglasses')}</span>
                </div>
                <div className={styles['products__content-item-left-2']} >
                    <img className={styles['products__content-item-left-2-img']} src={ProductItem2Png}/>
                    <span className={styles['products__content-item-left-2-text']}>{t('Big-ear Hat')}</span>
                </div>
                <div className={styles['products__content-item-left-3']} >
                    <img className={styles['products__content-item-left-3-img']} src={ProductItem3Png}/>
                    <span className={styles['products__content-item-left-3-text']}>{t('Gold Shell Earrings')}</span>
                </div>
                <div className={styles['products__content-item-left-4']} >
                    <img className={styles['products__content-item-left-4-img']} src={ProductItem4Png}/>
                    <span className={styles['products__content-item-left-4-text']}>{t('Purple Shell Earphone')}</span>
                </div>
                <div className={styles['products__content-item-left-5']} >
                    <img className={styles['products__content-item-left-5-img']} src={ProductItem5Png}/>
                    <span className={styles['products__content-item-left-5-text']}>{t('Pink Lady Necklace')}</span>
                </div>
            </div>
            <div className={styles['products__content-item-right']}>
                <div className={styles['products__content-item-right-1']} >
                    <img className={styles['products__content-item-right-1-img']} src={ProductItem6Png}/>
                    <span className={styles['products__content-item-right-1-text']}>{t('Gold Sunglasses')}</span>
                </div>
                <div className={styles['products__content-item-right-2']} >
                    <img className={styles['products__content-item-right-2-img']} src={ProductItem7Png}/>
                    <span className={styles['products__content-item-right-2-text']}>{t('Macaron Bag')}</span>
                </div>
            </div>
        </div>
    )
}

const Sunglasses = () => {

    const {translate: t} = useContext(LanguageContext)

    return (
        <div className={styles['sunglass']}>
            <div className={styles['sunglass__left']}>
                <img className={styles['sunglass__left__img']} src={ProductItem1Png}/>
                <span className={styles['sunglass__left__label']}>{t('Colored Sunglasses')}</span>
            </div>
            <div className={styles['sunglass__right']}>
                <img className={styles['sunglass__right__img']} src={ProductItem6Png}/>
                <span className={styles['sunglass__right__label']}>{t('Gold Sunglasses')}</span>
            </div>
        </div>
    )

}

const Hat = () => {

    const {translate: t} = useContext(LanguageContext)

    return (
        <div className={styles['hat']}>
            <div className={styles['hat__item']}>
                <img className={styles['hat__item__img']} src={ProductItem2Png}/>
                <span className={styles['hat__item__label']}>{t('Big-ear Hat')}</span>
            </div>
        </div>
    )
    
}

const Necklace = () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['necklace']}>
            <div className={styles['necklace__item']}>
                <img className={styles['necklace__item__img']} src={ProductItem5Png}/>
                <span className={styles['necklace__item__label']}>{t('Pink Lady Necklace')}</span>
            </div>
        </div>
    )
    
}

const Earrings = () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['earrings']}>
            <div className={styles['earrings__item']} >
                <img className={styles['earrings__item__img']} src={sp_3_1}/>
                <span className={styles['earrings__item__text']}>{t('Gold Shell Earrings')}</span>
            </div>
            <div className={styles['earrings__item']} >
                <img className={styles['earrings__item__img']} src={sp_5_1}/>
                <span className={styles['earrings__item__text']}>{t('Purple Shell Earphone')}</span>
            </div>
        </div>
    )
}

const Bag = () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['bag']}>
            <div className={styles['bag__item']}>
                <img className={styles['bag__item__img']} src={ProductItem7Png}/>
                <span className={styles['bag__item__label']}>{t('Macaron Bag')}</span>
            </div>
        </div>
    )
}

const ScrollView = (props: PropsWithChildren) => {

    return <div className={styles['scrollview']}>{props.children}</div>
}