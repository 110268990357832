import { useContext, useEffect, useRef, useState } from "react"
import styles from "./Index.module.scss"
import { TriedContext } from "../Trieds"

import bg from "./assets/images/bg_1920X1080.png"

export default () => {

    const {step} = useContext(TriedContext)
    const [offset, setOffset] = useState(0)
    const imgRef = useRef<HTMLImageElement>(null)

    useEffect(() => {
        const imgWidth = imgRef.current?.getBoundingClientRect().width??0
        const _offset = imgWidth - window.innerWidth
        console.log("imgWidth: ", imgWidth, " _offset: ", _offset)
        setOffset(_offset)
    }, [])

    const getStyle = () => {
        return {
            transform: step >= 2?`translateX(-${offset}px)`:"translateX(0)"
        }
    }

    return (
        <div className={styles[`trieds2`]}>
            <img ref={imgRef} src={bg} className={styles[`trieds2__img`]} style={getStyle()} />
        </div>
    )
}


