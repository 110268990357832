
export default {
	"CATLY | Official Website": "CATLY | 官方网站",
	"description": "光影交织，细腻如诗，震撼心灵的画面如梦境般绚烂。栩栩生辉的3A猫，暖如晨曦，带来无法言喻的陪伴。属于你的超凡童话。",
	"Email Address / Phone Number": "电子邮箱 / 手机号码",
	"Register Now": "立即注册",
	"English": "English",
	"简体中文": "简体中文",
	"繁體中文": "繁體中文",
	"日本語": "日本語",
	"한국어": "한국어",
	"Русский": "Русский",
	"Español": "Español",
	"Português": "Português",
	"Français": "Français",
	"Deutsch": "Deutsch",
	"Italiano": "Italiano",
	"Registered Successfully": "完美注册！",
	"Confident": "霸气",
	"Strides": "锐利",
	"Brave": "优雅",
	"and Boundless": "娇媚",
	"Elegant": "童趣",
	"Nimble": "逗乐",
	"Temperamental": "热辣",
	"by Nature": "独特",
	"Fun and Lively": "娇嫩 可爱",
	"Confident Strides": "霸气 锐利",
	"Brave Sprit": "优雅 娇媚",
	"Elegant Nimble": "童趣 逗乐",
	"Temperamental Chic": "热辣 独特",
	"Fun and Lively detail": "娇嫩 可爱",
	"In Their Eyes, Worlds Unfold": "猫眸流转，奇境绽放",
	"Meta Essentials": "精致甄选",
	"Discover All Accessories": "探索更多",
	"Sunglasses": "太阳眼镜",
	"Hat": "帽子",
	"Earrings": "耳环",
	"Necklace": "项链",
	"Bag": "包袋",
	"Products Accessories": "商品",
	"All": "全部",
	"Sunglasses detail": "太阳眼镜",
	"Hat detail": "帽子",
	"Necklace detail": "耳环",
	"Earrings detail": "项链",
	"Bag detail": "包袋",
	"Colored Sunglasses": "流光紫太阳眼镜",
	"Big-ear Hat": "大耳毛绒帽",
	"Gold Sunglasses": "金色太阳眼镜",
	"Gold Shell Earrings": "金贝壳耳环",
	"Macaron Bag": "马卡龙手袋",
	"Purple Shell Earphone": "紫贝壳耳机",
	"Pink Lady Necklace": "粉魅女士项链",
	"A Canvas of Stories, Painted in Fur": "每一寸，皆为艺术",
	"A world of whispers, wonder, and infinite grace.": "猫语轻吟，绽放奇迹与优雅的璀璨世界",
	"Style Collection": "潮流先锋系列",
	"Discover All fashion": "尽情发现",
	"Blossom": "Blossom 外套",
	"Winterwarrior": "Winterwarrior 套装",
	"Snowshield": "Snowshield 套装",
	"Macaron": "Macaron 风衣",
	"Combat": "Combat 风衣",
	"Products": "商品",
	"Season 1": "秋冬系列",
	"Blossom Coat": "Blossom 外套",
	"Winterwarrior Suit": "Winterwarrior 套装",
	"Snowshield Suit": "Snowshield 套装",
	"Blossom Tops": "Blossom 上衣",
	"Macaron Coat": "Macaron 风衣",
	"Combat Coat": "Combat 风衣",
	"Adventure Sports Suit": "Adventure Sports 套装",
	"Futuristic Jacket": "Futuristic 夹克",
	"Born of Moonlight, Bound by Mystery.": "月夜之灵，秘韵深藏",
	"Music by Akira Senju": "音乐：Akira Senju 千住明",
	"Privacy Policy": "隐私政策",
	"NEWSLETTER": "关注Catly",
	"Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.": "订阅 Catly 新闻，获取最新资讯，包括独家线上预售和新品系列。",
	"FOLLOW US": "关注我们",
	"Email address": "邮箱",
	"OK": "订阅",
	"Followed Successfully": "订阅成功",
	"Power click": "霸气",
	"Elegance click": "优雅",
	"Toy click": "童趣",
	"Temperamental click": "热辣",
	"Petite click": "可爱",
	"null": "手机或邮箱不能为空",
	"The Game Awards 2024": "The Game Awards 2024",
	"Showcase Game": "大会展示游戏",

}
