import styles from "./Footer.module.scss"

import logoSvg from "./assets/images/logo.svg"
import switchSvg from "./assets/images/switch.svg"
import { Link, useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { LanguageContext } from "../../context/useLanguageContext"

import Api, { RegisterRequest } from "../../api/api"
import maidian, { MaidianEventType } from "../../api/maidian"

import {STEAM_URL, X_URL, IWATCH_URL} from "../../constants"

export default () => {

    const {
        maidianEventSend,
      } = maidian

      
      const {translate: t, language} = useContext(LanguageContext)
      const [footerP, setFooterP] = useState<boolean>(false)
    const navigate = useNavigate()

    const iconClick = (type: string, url: string) => {
        maidianEventSend(MaidianEventType.ICON_CLICK, type)
        window.open(url, "_href")
    }

    useEffect(() => {
        setFooterP(language === 'zh-CN' || language === 'zh' || language === 'ko' || language === 'ja')
    }, [language])

    return (
        <div className={styles['footer']}>
            <div className={styles['footer__logo']}>
                <img className={styles['footer__logo-img']} src={logoSvg}></img>
            </div>
            <div className={styles['footer__content']}>
                <div className={styles['footer__content-left']}>
                    <div className={styles['footer__content-left-icons']}>
                        <span className={styles['footer__content-left-icons-text']}>
                            {t('Music by Akira Senju')}
                        </span>
                        <div className={styles['footer__content-left-icons-item']}>
                            <div className={styles['footer__content-left-icons-item-steam']} 
                                onClick={() => {iconClick('STEAM', STEAM_URL)}}
                            ></div>
                            <div className={styles['footer__content-left-icons-item-iwatch']}
                                onClick={() => {iconClick('IWATCH', IWATCH_URL)}}
                            ></div>
                            <div className={styles['footer__content-left-icons-item-switch']}
                            ></div>
                        </div>
                    </div>
                    <div className={styles['footer__content-left-pp']}>
                        <div className={[styles['footer__content-left-pp-text'], footerP?styles['footerpPP']:''].join(" ")} onClick={() => {navigate("/privacyPolicy"), maidianEventSend(MaidianEventType.ENTER_SUB_PAGE, 'privacyPolicy')}}>
                            {t('Privacy Policy')}
                        </div>
                    </div>
                </div>
                <div className={styles['footer__content-right']}>
                    <div className={styles['footer__content-right-1']}>
                        <span className={[styles['footer__content-right-1-text1'], footerP?styles['footerpTitle']:''].join(" ")}>{t('NEWSLETTER')}</span>
                        <span className={[styles['footer__content-right-1-text2'], footerP?styles['footerpFollowDesc']:''].join(" ")}>
                            {t('Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.')}
                        </span>
                    </div>
                    <div className={styles['footer__content-right-2']}>
                        <span className={[styles['footer__content-right-2-text1'], footerP?styles['footerpFollowMe']:''].join(" ")}>{t('FOLLOW US')}</span>
                        <div className={styles['footer__content-right-2-icon']}
                                onClick={() => {iconClick('X', X_URL)}}
                            ></div>
                        </div>
                    <div className={styles['footer__content-right-3']}>
                        <Follow/>
                    </div>
                </div>
            </div>
        </div>
    )
}


export const Follow = () => {
    const {
        follow,
    } = Api
    const [followed, setFollowed] = useState<boolean>(false)
    // const [followed, setFollowed] = useState<boolean>(true)
    const [email, setEmail] = useState<string>("")
    const {translate: t, language} = useContext(LanguageContext)
    const [footerP, setFooterP] = useState<boolean>(false)
    useEffect(() => {
        setFooterP(language === 'zh-CN' || language === 'zh' || language === 'ko' || language === 'ja')
    }, [language])
    const doFollow = () => {
        if (!email) {
            //alert('邮箱不能为空')
            return
        }
        setFollowed(true)

        follow({email: email})
        .then(res => {
            console.log('订阅成功')
        }).catch(err => {
            console.error('订阅失败')
        })
    }

    if (followed) {
        return (
            <div className={styles['followed']}>
                <span className={styles['followed__text']}>{t('Followed Successfully')}</span>
            </div>
        )
    }
    return (
        <div className={styles['follow']}>
            <div className={styles['follow__input-wrapper']}>
                <input id="followInput" value={email} onChange={(e) => setEmail(e.target.value)} className={[styles['follow__input'], footerP?styles['footerpPlaceholder']:''].join(" ")} placeholder={t("Email address")}/>
                <div className={styles['follow__btn-wrapper']} onClick={() => doFollow()}>
                    <span className={[styles['follow__btn'], footerP?styles['footerpBtn']:''].join(" ")}>{t('OK')}</span>
                </div>
            </div>
        </div>
    )
}

