import styles from "./Detail.module.scss"

import Header from "../../header/Header"
import Footer from "../../footer/Footer"

import { useParams } from "react-router-dom"

export default () => {
    let param = useParams();
    let imageId:string = param.id!

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getStyle = () => {
        return {
            marginTop: document.getElementById('header')?.offsetHeight
        }
    }

    return (
        <div>
            <Header white goback/>
            <div className={styles['items']} style={getStyle()}>
                { imageId === "1" && <DetailTrides/> }
                { imageId > "1" && <Detail1 detailId={imageId}/> }
            </div>
            <Footer/>
        </div>
    )
}

import imageTrides0 from "./assets/images/p2-0-0.png"
import imageTrides1 from "./assets/images/p2-0-1.png"
import imageTrides2 from "./assets/images/p2-0-2.png"
import imageTrides3 from "./assets/images/p2-0-3.png"
export const DetailTrides = () => {
    const {translate: t, language} = useContext(LanguageContext)
    return (
        <div>
        <div className={styles['items-0']}>
            <img src={imageTrides0}/>
            <div className={styles['items-0__text-wrapper']}>
                <span className={styles['items-0__text']}>{t('Confident Strides')}</span>
            </div>
        </div>
        <div className={styles['items-1']}>
            <img src={imageTrides1}/>
        </div>
        <div className={styles['items-2']}>
            <img src={imageTrides2}/>
        </div>
        <div className={styles['items-3']}>
            <img src={imageTrides3}/>
        </div>
        </div>
    )
}

export const Detail1 = (props: {detailId: string}) => {
    const {translate: t, language} = useContext(LanguageContext)
    let images = getImages(parseInt(props.detailId)-1)
    return (
        <>
        <div className={styles['items-0']}>
            <img src={images[0].img}/>
            <div className={styles['items-0__text-wrapper']}>
                <span className={styles['items-0__text']}>{t(images[0].text??'')}</span>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <img src={images[1].img}/>
        </div>
        <div style={{display: 'flex'}}>
            <img src={images[2].img}/>
        </div>
        </>
    )
}

import image_1_0 from "./assets/images/p2-1-0.png"
import image_1_1 from "./assets/images/p2-1-1.png"
import image_1_2 from "./assets/images/p2-1-2.png"

import image_2_0 from "./assets/images/p2-2-0.png"
import image_2_1 from "./assets/images/p2-2-1.png"
import image_2_2 from "./assets/images/p2-2-2.png"

import image_3_0 from "./assets/images/p2-3-0.png"
import image_3_1 from "./assets/images/p2-3-1.png"
import image_3_2 from "./assets/images/p2-3-2.png"

import image_4_0 from "./assets/images/p2-4-0.png"
import image_4_1 from "./assets/images/p2-4-1.png"
import image_4_2 from "./assets/images/p2-4-2.png"
import { useContext, useEffect } from "react"
import { HomepageContext } from "../../homepage/Homepage"
import { LanguageContext } from "../../../context/useLanguageContext"

const getImages = (detailId: number) => {
    let list = [
        [],
        [{img: image_1_0, text: 'Brave Sprit'}, {img: image_1_1}, {img: image_1_2}],
        [{img: image_2_0, text: 'Elegant Nimble'}, {img: image_2_1}, {img: image_2_2}],
        [{img: image_3_0, text: 'Temperamental Chic'}, {img: image_3_1}, {img: image_3_2}],
        [{img: image_4_0, text: 'Fun and Lively detail'}, {img: image_4_1}, {img: image_4_2}],
    ]
    return list[detailId]
}


