import { useNavigate } from "react-router-dom"
import styles from "./P7.module.scss"

import BlossomCover from './assets/images/Blossom_cover.png'
import BlossomVideo from '../../pages/p7/assets/videos/Blossom.mp4'
import WinterwarriorCover from './assets/images/Winterwarrior_cover.png'
import WinterwarriorVideo from '../../pages/p7/assets/videos/Winterwarrior.mp4'
import SnowshieldCover from './assets/images/Snowshield_cover.png'
import SnowshieldVideo from '../../pages/p7/assets/videos/Snowshield.mp4'
import CombatCover from './assets/images/Combat_cover.png'
import CombatVideo from '../../pages/p7/assets/videos/Combat.mp4'
import { useContext, useEffect } from "react"
import { LanguageContext } from "../../context/useLanguageContext"

import Maidian, { MaidianEventType } from "../../api/maidian"
import { HomepageContext } from "../homepage/Homepage"

export default () => {
    const {
        maidianEventSend,
      } = Maidian
    let navigate = useNavigate();

    const {translate: t} = useContext(LanguageContext)
    return (
        <div data-step="7" className={styles['p7']}>
            <div className={styles['p7-top']}>
            <span className={styles['p7-top-title']}>{t('Meta Essentials')}</span>
                <div className={styles['p7-top-desc']}>
                    <div className={styles['p7-top-desc-wrapper']} onClick={() => {navigate("/p7/detail"), maidianEventSend(MaidianEventType.ENTER_SUB_PAGE, 7)}}>
                        <span className={styles['p7-top-desc-wrapper-text']}>{t('Discover All fashion')}</span>
                        <span className={styles['p7-top-desc-wrapper-underline']}></span>
                    </div>
                </div>
            </div>
            <div className={styles['p7-content']}>
                <ItemList/>
            </div>
        </div>
    )
}

export const ItemList = () => {
    const {translate: t} = useContext(LanguageContext)
    const {step} = useContext(HomepageContext)
    useEffect(() => {
        for (let i=0; i<4; i++) {
            let video = document.getElementById("p7_video_"+i) as any
            video?.load()
        }
    }, [])
    useEffect(() => {
        if (step === 6) {
            nextPlay(0)
        } else {
            for (let i=0; i<4; i++) {
                let video = document.getElementById("p7_video_"+i) as HTMLVideoElement
                if (!video.paused) {
                    video.pause()
                }
            }
        }
    }, [step])
    const nextPlay = (videoIdx: number) => {
        if (videoIdx > 3) {
            videoIdx = 0
        }
        console.log('p7视频播放,idx:', videoIdx)
        const video = document.getElementById("p7_video_"+videoIdx) as any
        video?.play()
        .catch(() => {
            console.error('p7播放失败')
        });
    }
    let typeList = [
        {cover: BlossomCover, video: BlossomVideo, text: t("Blossom")},
        {cover: WinterwarriorCover, video: WinterwarriorVideo, text: t("Winterwarrior")},
        {cover: SnowshieldCover, video: SnowshieldVideo, text: t("Snowshield")},
        {cover: CombatCover, video: CombatVideo, text: t("Combat")},
    ]
    const itemList = []
    for (let i = 0; i < typeList.length; i ++) {
        itemList.push(
            <div className={styles['p7-content-item']} key={i}>
                {/* <img src={typeList[i].img} className={styles['p7-content-item-img']}/> */}
                <video id={"p7_video_"+i} 
                className={styles['p7-content-item-video']}
                src={typeList[i].video}
                //poster={typeList[i].img}
                muted 
                // autoPlay
                playsInline
                onEnded={() => {nextPlay(i+1)}}
                />
                <img src={typeList[i].cover} className={styles['p7-content-item-img']}/>
                <span className={styles['p7-content-item-text']}>{typeList[i].text}</span>
            </div>
        )
    }
    return (
        <>{itemList}</>
    )
}


