import styles from "./Detail.module.scss"

import Header from "../../header/Header"
import Footer from "../../footer/Footer"

import Background from "./assets/images/background.png"
import BlossomCoat from "./assets/images/BlossomCoat.png"
import WinterwarriorSuit from "./assets/images/WinterwarriorSuit.png"
import Content_1 from "./assets/images/content-1.png"
import Blossom from './assets/images/Blossom.png'
import Winterwarrior from './assets/images/Winterwarrior.png'
import Snowshield from './assets/images/Snowshield.png'
import Combat from './assets/images/Combat.png'

import MacaronCoat from './assets/images/MacaronCoat.png'
import AdventureSportsSuit from './assets/images/AdventureSportsSuit.png'
import { useContext, useEffect } from "react"
import { LanguageContext } from "../../../context/useLanguageContext"

export default () => {

    const {translate: t} = useContext(LanguageContext)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
        <Header white goback/>
        <div className={styles['detail']}>
            <img className={styles['detail-background']} src={Background}/>
            <div className={styles['detail-nav']}>
                <span className={styles['detail-nav-text']}>{t('Season 1')}</span>
            </div>
            <div className={styles['detail-1']}>
                <div className={styles['detail-1-1']}>
                    <img className={styles['detail-1-1-img']} src={BlossomCoat}/>
                    <span className={styles['detail-1-1-text']}>{t('Blossom Coat')}</span>
                </div>
                <div className={styles['detail-1-2']}>
                    <img className={styles['detail-1-2-img']} src={WinterwarriorSuit}/>
                    <span className={styles['detail-1-2-text']}>{t('Winterwarrior Suit')}</span>
                </div>
            </div>
            <div className={styles['detail-2']}>
                <img className={styles['detail-2-img']} src={Content_1}/>
            </div>
            <div className={styles['detail-3']}>
                <div className={styles['detail-3-1']}>
                    <img className={styles['detail-3-1-img']} src={Snowshield}/>
                    <span className={styles['detail-3-1-text']}>{t('Snowshield Suit')}</span>
                </div>
                <div className={styles['detail-3-2']}>
                    <img className={styles['detail-3-2-img']} src={Combat}/>
                    <span className={styles['detail-3-2-text']}>{t('Combat Coat')}</span>
                </div>
                <div className={styles['detail-3-3']}>
                    <div className={styles['detail-3-3-1']}>
                        <img className={styles['detail-3-3-1-img']} src={MacaronCoat}/>
                        <span className={styles['detail-3-3-1-text']}>{t('Macaron Coat')}</span>
                    </div>
                    <div className={styles['detail-3-3-2']}>
                        <img className={styles['detail-3-3-2-img']} src={AdventureSportsSuit}/>
                        <span className={styles['detail-3-3-2-text']}>{t('Adventure Sports Suit')}</span>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </div>
    )
}


