
import { http, HttpResponse, PaganitionRequest, PaganitionResponse } from "../bundle/lib";
import axios from "axios";

interface MaidianEventRequest {
    sid: string
    eventType: string
}

export enum MaidianEventType {
    OPEN,
    CLOSE,
    REGISTER,
    FOLLOW,
    ENTER_PAGE,   //进入页面
    ENTER_SUB_PAGE,   //进入子页面
    ICON_CLICK,   //icon点击
}

/**
 * 获取SID
 * @returns sid
 */
const getSid = () => {
    let sid = localStorage.getItem('sid')
    if (!sid) {
        sid = Array.from(crypto.getRandomValues(new Uint8Array(16)))
        .map(n => n.toString(16).padStart(2, '0'))
        .join('')
        localStorage.setItem('sid', sid)
    }
    return sid
}

/**
 * 发送打开应用事件
 */
const maidianOpen = () => {
    //TODO 发送打开应用事件
    maidianEventSend(MaidianEventType.OPEN)
}

/**
 * 发送关闭应用事件
 */
const maidianClose = () => {
    //TODO 发送关闭应用事件
    maidianEventSend(MaidianEventType.CLOSE)
}

/**
 * 发送注册事件
 */
const maidianRegister = () => {
    //TODO 发送注册事件
    maidianEventSend(MaidianEventType.REGISTER)
}

/**
 * 发送订阅事件
 */
const maidianFollow = () => {
    //TODO 发送订阅事件
    maidianEventSend(MaidianEventType.FOLLOW)
}

/**
 * 发送埋点事件
 */
const maidianEventSend = (event: MaidianEventType, eventSubType?: any) => {
    try {
        let sid = getSid()
        //TODO 发送埋点事件
        //console.log('收到埋点事件,button:', MaidianEventType[event], 'sid:', sid)
    
        let request = {
            sid,
            eventType: MaidianEventType[event],
            appVersion: navigator.appVersion,
            appCodeName: navigator.appCodeName,
            appLanguage: navigator.language,
            userAgent: navigator.userAgent,
            from: localStorage.getItem('from'),
            utmSource: localStorage.getItem('utm_source'),
            eventSubType: eventSubType,
        }
        send(request)
        .then(res => {
        })
        .catch(err => {
            console.error('埋点上报异常', err)
        })
    } catch (err) {
        console.error('埋点上报异常', err)
    }
}

const send = async (request: MaidianEventRequest) => {
    const res = (await axios.post("/maidianEvent/create", request)).data as HttpResponse<void>
    http.checkState(res)
    return res
}

export default {
    maidianOpen,
    maidianClose,
    maidianRegister,
    maidianFollow,
    maidianEventSend,
}