
interface LanguageOption{
    label: string
    value: string
}


export default [
    {
        label: "English",
        value: "en"
    },
    {
        label: "简体中文",
        value: "zh-CN"
    },
    {
        label: "繁體中文",
        value: "zh"
    },
    {
        label: "日本語",
        value: "ja"
    },
    {
        label: "한국어",     //韩语
        value: "ko"
    },
    {
        label: "Русский",   //俄语
        value: "ru"
    },
    {
        label: "Español",
        value: "es"
    },
    {
        label: "Português",   //葡萄牙
        value: "pt"
    },
    {
        label: "Français",
        value: "fr"
    },
    {
        label: "Deutsch",     //德语
        value: "de"
    },
    {
        label: "Italiano",    //意大利
        value: "it"
    }
] as LanguageOption[]