
export default {
	"CATLY | Official Website": "CATLY | Página web oficial",
	"description": "Redefiniendo la compañía con gatos AAA. Un viaje hiperrealista lleno de aventuras, artesanía, moda, diversión, nieve, velocidad, robots... todo ello con y a través de los gatos.",
	"Email Address / Phone Number": "Dirección de correo / Número de teléfono",
	"Register Now": "Regístrate ya",
	"English": "English",
	"简体中文": "简体中文",
	"繁體中文": "繁體中文",
	"日本語": "日本語",
	"한국어": "한국어",
	"Русский": "Русский",
	"Español": "Español",
	"Português": "Português",
	"Français": "Français",
	"Deutsch": "Deutsch",
	"Italiano": "Italiano",
	"Registered Successfully": "¡Rrrregistro completado!",
	"Confident": "Poder",
	"Strides": "Zancadas",
	"Brave": "Elegancia",
	"and Boundless": "Gracia",
	"Elegant": "Juguete",
	"Nimble": "Deleite",
	"Temperamental": "Caprichos",
	"by Nature": "Chic",
	"Fun and Lively": "Peluchito",
	"Confident Strides": "Zancadas poderosas",
	"Brave Sprit": "Elegancia al cuadrado",
	"Elegant Nimble": "Deleite juguetón",
	"Temperamental Chic": "Chic caprichoso",
	"Fun and Lively detail": "Peluchito",
	"In Their Eyes, Worlds Unfold": "Los mundos se crean en los ojos de los gatos",
	"Meta Essentials": "Chic",
	"Discover All Accessories": "Descúbrelo todo",
	"Sunglasses": "Gafas de sol",
	"Hat": "Sombrero",
	"Earrings": "Pendientes",
	"Necklace": "Collar",
	"Bag": "Mochila",
	"Products Accessories": "Productos",
	"All": "Todo",
	"Sunglasses detail": "Gafas de sol",
	"Hat detail": "Sombrero",
	"Necklace detail": "Pendientes",
	"Earrings detail": "Collar",
	"Bag detail": "Mochila",
	"Colored Sunglasses": "Gafas de sol de colores",
	"Big-ear Hat": "Sombrero de orejas grandes",
	"Gold Sunglasses": "Gafas de sol de oro",
	"Gold Shell Earrings": "Pendientes de conchas de oro",
	"Macaron Bag": "Mochila de macaron",
	"Purple Shell Earphone": "Auriculares de conchas moradas",
	"Pink Lady Necklace": "Collar de señorita rosa",
	"A Canvas of Stories, Painted in Fur": "Un lienzo de historias hecho con pelos",
	"A world of whispers, wonder, and infinite grace.": "Un mundo gatuno de susurros, maravillas y gracia infinita.",
	"Style Collection": "Colección de estilos",
	"Discover All fashion": "Descúbrelo todo",
	"Blossom": "Flores",
	"Winterwarrior": "Guerrero invernal",
	"Snowshield": "Escudo nevado",
	"Macaron": "Macaron",
	"Combat": "Combate",
	"Products": "Productos",
	"Season 1": "Temporada 1",
	"Blossom Coat": "Abrigo de flores",
	"Winterwarrior Suit": "Traje de guerrero invernal",
	"Snowshield Suit": "Traje de escudo nevado",
	"Blossom Tops": "Top de flores",
	"Macaron Coat": "Abrigo de macaron",
	"Combat Coat": "Abrigo de combate",
	"Adventure Sports Suit": "Traje deportivo de aventura",
	"Futuristic Jacket": "Chaqueta futurista",
	"Born of Moonlight, Bound by Mystery.": "Engendrado por la luz de la luna, ligado por el misterio.",
	"Music by Akira Senju": "Música: Akira Senju",
	"Privacy Policy": "Política de privacidad",
	"NEWSLETTER": "Boletín informativo",
	"Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.": "Suscríbete para recibir correos electrónicos de Catly y las últimas noticias, además de prelanzamientos exclusivos en línea y nuevas colecciones.",
	"FOLLOW US": "SÍGUENOS",
	"Email address": "Dirección de correo",
	"OK": "Vale",
	"Followed Successfully": "Ahora nos sigues",
	"Power click": "Poder",
	"Elegance click": "Elegancia",
	"Toy click": "Juguete",
	"Temperamental click": "Caprichos",
	"Petite click": "Peluchito",
	"null": "El número de teléfono o el correo electrónico no pueden estar vacíos.",
	"The Game Awards 2024": "The Game Awards 2024",
	"Showcase Game": "Exhibición del juego",

}
