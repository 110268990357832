
export default {
	"CATLY | Official Website": "CATLY | Официальный сайт",
	"description": "Новый уровень отношений с котиками! Гиперреалистические приключения, творчество, мода, веселье, снег, скорость и роботы — нигде не обойдётся без котиков!",
	"Email Address / Phone Number": "Адрес эл. почты / номер телефона",
	"Register Now": "Зарегистрироваться",
	"English": "English",
	"简体中文": "简体中文",
	"繁體中文": "繁體中文",
	"日本語": "日本語",
	"한국어": "한국어",
	"Русский": "Русский",
	"Español": "Español",
	"Português": "Português",
	"Français": "Français",
	"Deutsch": "Deutsch",
	"Italiano": "Italiano",
	"Registered Successfully": "Мур-мур! Вы зарегистрировались!",
	"Confident": "Изящная",
	"Strides": "походка",
	"Brave": "Элегантность",
	"and Boundless": "и грация",
	"Elegant": "Маленькая",
	"Nimble": "радость",
	"Temperamental": "Шик",
	"by Nature": "и нрав",
	"Fun and Lively": "Маленький плюш",
	"Confident Strides": "Изящная походка",
	"Brave Sprit": "Элегантность и грация",
	"Elegant Nimble": "Маленькая радость",
	"Temperamental Chic": "Шик и нрав",
	"Fun and Lively detail": "Маленький плюш",
	"In Their Eyes, Worlds Unfold": "В глазах котика — целые миры",
	"Meta Essentials": "Шик",
	"Discover All Accessories": "Посмотреть все",
	"Sunglasses": "Очки",
	"Hat": "Шляпа",
	"Earrings": "Серьги",
	"Necklace": "Ожерелье",
	"Bag": "Сумка",
	"Products Accessories": "Предметы",
	"All": "Все",
	"Sunglasses detail": "Очки",
	"Hat detail": "Шляпа",
	"Necklace detail": "Серьги",
	"Earrings detail": "Ожерелье",
	"Bag detail": "Сумка",
	"Colored Sunglasses": "Цветные очки",
	"Big-ear Hat": "Шляпа с большими ушами",
	"Gold Sunglasses": "Золотые очки",
	"Gold Shell Earrings": "Золотые серьги",
	"Macaron Bag": "Сумка-макарон",
	"Purple Shell Earphone": "Фиолетовые наушники",
	"Pink Lady Necklace": "Розовое ожерелье",
	"A Canvas of Stories, Painted in Fur": "Меховое полотно историй",
	"A world of whispers, wonder, and infinite grace.": "Кошачий мир тихих звуков, чудес и неуловимой грации.",
	"Style Collection": "Коллекция стиля",
	"Discover All fashion": "Посмотреть все",
	"Blossom": "Цветение",
	"Winterwarrior": "Зимний воин",
	"Snowshield": "Снежная защита",
	"Macaron": "Макарон",
	"Combat": "Боевой",
	"Products": "Предметы",
	"Season 1": "Сезон 1",
	"Blossom Coat": "Шубка цветения",
	"Winterwarrior Suit": "Костюм зимнего воина",
	"Snowshield Suit": "Костюм снежной защиты",
	"Blossom Tops": "Майки цветения",
	"Macaron Coat": "Шубка «макарон»",
	"Combat Coat": "Боевая шубка",
	"Adventure Sports Suit": "Костюм для активного отдыха",
	"Futuristic Jacket": "Футуристическая куртка",
	"Born of Moonlight, Bound by Mystery.": "Дети лунного света, окутанные тайной",
	"Music by Akira Senju": "Композитор: Акира Сэндзю",
	"Privacy Policy": "Политика конфиденциальности",
	"NEWSLETTER": "РАССЫЛКА",
	"Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.": "Подпишитесь на рассылку Catly и получайте последние новости об эксклюзивных событиях к выходу материалов и о новых коллекциях.",
	"FOLLOW US": "ПОДПИСАТЬСЯ",
	"Email address": "Адрес эл. почты",
	"OK": "ОК",
	"Followed Successfully": "Вы подписались. Мур-мур!",
	"Power click": "Изящная",
	"Elegance click": "Элегантность",
	"Toy click": "Маленькая",
	"Temperamental click": "Шик",
	"Petite click": "Маленький",
	"null": "Номер телефона или электронная почта не могут быть пустыми.",
	"The Game Awards 2024": "The Game Awards 2024",
	"Showcase Game": "Презентация игры",

}
