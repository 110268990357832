

import de1l from "./de/p2-1-l.png"
import de2l from "./de/p2-2-l.png"
import de3l from "./de/p2-3-l.png"
import de4l from "./de/p2-4-l.png"
import de5l from "./de/p2-5-l.png"

import en1l from "./en/p2-1-l.png"
import en2l from "./en/p2-2-l.png"
import en3l from "./en/p2-3-l.png"
import en4l from "./en/p2-4-l.png"
import en5l from "./en/p2-5-l.png"

import es1l from "./es/p2-1-l.png"
import es2l from "./es/p2-2-l.png"
import es3l from "./es/p2-3-l.png"
import es4l from "./es/p2-4-l.png"
import es5l from "./es/p2-5-l.png"

import fr1l from "./fr/p2-1-l.png"
import fr2l from "./fr/p2-2-l.png"
import fr3l from "./fr/p2-3-l.png"
import fr4l from "./fr/p2-4-l.png"
import fr5l from "./fr/p2-5-l.png"

import it1l from "./it/p2-1-l.png"
import it2l from "./it/p2-2-l.png"
import it3l from "./it/p2-3-l.png"
import it4l from "./it/p2-4-l.png"
import it5l from "./it/p2-5-l.png"

import ja1l from "./ja/p2-1-l.png"
import ja2l from "./ja/p2-2-l.png"
import ja3l from "./ja/p2-3-l.png"
import ja4l from "./ja/p2-4-l.png"
import ja5l from "./ja/p2-5-l.png"

import ko1l from "./ko/p2-1-l.png"
import ko2l from "./ko/p2-2-l.png"
import ko3l from "./ko/p2-3-l.png"
import ko4l from "./ko/p2-4-l.png"
import ko5l from "./ko/p2-5-l.png"

import pt1l from "./pt/p2-1-l.png"
import pt2l from "./pt/p2-2-l.png"
import pt3l from "./pt/p2-3-l.png"
import pt4l from "./pt/p2-4-l.png"
import pt5l from "./pt/p2-5-l.png"

import ru1l from "./ru/p2-1-l.png"
import ru2l from "./ru/p2-2-l.png"
import ru3l from "./ru/p2-3-l.png"
import ru4l from "./ru/p2-4-l.png"
import ru5l from "./ru/p2-5-l.png"

import zh1l from "./zh/p2-1-l.png"
import zh2l from "./zh/p2-2-l.png"
import zh3l from "./zh/p2-3-l.png"
import zh4l from "./zh/p2-4-l.png"
import zh5l from "./zh/p2-5-l.png"

import zhCN1l from "./zh-CN/p2-1-l.png"
import zhCN2l from "./zh-CN/p2-2-l.png"
import zhCN3l from "./zh-CN/p2-3-l.png"
import zhCN4l from "./zh-CN/p2-4-l.png"
import zhCN5l from "./zh-CN/p2-5-l.png"

export default {
    "de": {
        1: de1l,
        2: de2l,
        3: de3l,
        4: de4l,
        5: de5l
    },
    "en": {
        1: en1l,
        2: en2l,
        3: en3l,
        4: en4l,
        5: en5l
    },
    "es": {
        1: es1l,
        2: es2l,
        3: es3l,
        4: es4l,
        5: es5l
    },
    "fr": {
        1: fr1l,
        2: fr2l,
        3: fr3l,
        4: fr4l,
        5: fr5l
    },
    "it": {
        1: it1l,
        2: it2l,
        3: it3l,
        4: it4l,
        5: it5l
    },
    "ja": {
        1: ja1l,
        2: ja2l,
        3: ja3l,
        4: ja4l,
        5: ja5l
    },
    "ko": {
        1: ko1l,
        2: ko2l,
        3: ko3l,
        4: ko4l,
        5: ko5l
    },
    "pt": {
        1: pt1l,
        2: pt2l,
        3: pt3l,
        4: pt4l,
        5: pt5l
    },
    "ru": {
        1: ru1l,
        2: ru2l,
        3: ru3l,
        4: ru4l,
        5: ru5l
    },
    "zh": {
        1: zh1l,
        2: zh2l,
        3: zh3l,
        4: zh4l,
        5: zh5l
    },
    "zh-CN": {
        1: zhCN1l,
        2: zhCN2l,
        3: zhCN3l,
        4: zhCN4l,
        5: zhCN5l
    }
} as Record<string, any>