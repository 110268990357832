import { useContext } from "react"
import styles from "./P5.module.scss"

import VideoPoster from './assets/images/p5-h.png'
import Video from './assets/videos/index.mp4'
import { LanguageContext } from "../../context/useLanguageContext"

export default () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div data-step="5" className={styles['p5']}>
            <img src={VideoPoster} className={styles['p5-img']}/>
            {/* <video id="p1Video" className={styles['p5-img']} src={Video} playsInline autoPlay loop muted></video> */}
            {/* <span className={styles['p3-text']}>In Their Eyes, Worlds Unfold.</span> */}
            <div className={styles['p5-content']}>
                <span className={styles['p5-content-text']}>{t('A Canvas of Stories, Painted in Fur')}</span>
            </div>
        </div>
    )
}


