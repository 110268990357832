
export default [
	["미국령 군소 제도", ""],
	["푸에르토리코", "1"],
	["캐나다", "1"],
	["미국", "1"],
	["바하마", "1-242"],
	["바베이도스", "1-246"],
	["앵귈라", "1-264"],
	["앤티가 바부다", "1-268"],
	["영국령 버진아일랜드", "1-284"],
	["미국령 버진아일랜드", "1-340"],
	["케이맨 제도", "1-345"],
	["버뮤다", "1-441"],
	["그레나다", "1-473"],
	["터크스 케이커스 제도", "1-649"],
	["몬트세랫", "1-664"],
	["북마리아나 제도", "1-670"],
	["괌", "1-671"],
	["아메리칸사모아", "1-684"],
	["신트마르턴", "1-721"],
	["세인트루시아", "1-758"],
	["도미니카 연방", "1-767"],
	["세인트빈센트 그레나딘", "1-784"],
	["도미니카 공화국", "1-809"],
	["트리니다드 토바고", "1-868"],
	["세인트키츠 네비스", "1-869"],
	["자메이카", "1-876"],
	["이집트", "20"],
	["남수단", "211"],
	["모로코", "212"],
	["서사하라", "212"],
	["알제리", "213"],
	["튀니지", "216"],
	["리비아", "218"],
	["감비아", "220"],
	["세네갈", "221"],
	["모리타니", "222"],
	["말리", "223"],
	["기니", "224"],
	["코트디부아르", "225"],
	["부르키나파소", "226"],
	["니제르", "227"],
	["토고", "228"],
	["베냉", "229"],
	["모리셔스", "230"],
	["라이베리아", "231"],
	["시에라리온", "232"],
	["가나", "233"],
	["나이지리아", "234"],
	["차드", "235"],
	["중앙아프리카 공화국", "236"],
	["카메룬", "237"],
	["카보베르데", "238"],
	["상투메 프린시페", "239"],
	["적도 기니", "240"],
	["가봉", "241"],
	["콩고 공화국", "242"],
	["콩고 민주 공화국", "243"],
	["앙골라", "244"],
	["기니비사우", "245"],
	["영국령 인도양 지역", "246"],
	["세이셸", "248"],
	["수단", "249"],
	["르완다", "250"],
	["에티오피아", "251"],
	["소말리아", "252"],
	["지부티", "253"],
	["케냐", "254"],
	["탄자니아", "255"],
	["우간다", "256"],
	["부룬디", "257"],
	["모잠비크", "258"],
	["잠비아", "260"],
	["마다가스카르", "261"],
	["프랑스령 남방 및 남극 지역", "262"],
	["레위니옹", "262"],
	["마요트", "262"],
	["짐바브웨", "263"],
	["나미비아", "264"],
	["말라위", "265"],
	["레소토", "266"],
	["보츠와나", "267"],
	["에스와티니", "268"],
	["코모로", "269"],
	["남아프리카 공화국", "27"],
	["세인트헬레나", "290n"],
	["에리트레아", "291"],
	["아루바", "297"],
	["페로 제도", "298"],
	["그린란드", "299"],
	["그리스", "30"],
	["네덜란드", "31"],
	["벨기에", "32"],
	["프랑스", "33"],
	["스페인", "34"],
	["지브롤터", "350"],
	["포르투갈", "351"],
	["룩셈부르크", "352"],
	["아일랜드", "353"],
	["아이슬란드", "354"],
	["알바니아", "355"],
	["몰타", "356"],
	["키프로스", "357"],
	["올란드 제도", "358"],
	["핀란드", "358"],
	["불가리아", "359"],
	["헝가리", "36"],
	["리투아니아", "370"],
	["라트비아", "371"],
	["에스토니아", "372"],
	["몰도바", "373"],
	["아르메니아", "374"],
	["벨라루스", "375"],
	["안도라", "376"],
	["모나코", "377"],
	["산마리노", "378"],
	["우크라이나", "380"],
	["세르비아", "381p"],
	["몬테네그로", "382"],
	["크로아티아", "385"],
	["슬로베니아", "386"],
	["보스니아 헤르체고비나", "387"],
	["북마케도니아", "389"],
	["이탈리아", "39"],
	["바티칸 시국", "39-06"],
	["루마니아", "40"],
	["스위스", "41"],
	["체코", "420"],
	["슬로바키아", "421"],
	["리히텐슈타인", "423"],
	["오스트리아", "43"],
	["건지섬", "44"],
	["맨섬", "44"],
	["영국", "44"],
	["저지섬", "44"],
	["덴마크", "45"],
	["스웨덴", "46"],
	["부베섬", "47"],
	["스발바르 얀마옌", "47"],
	["노르웨이", "47"],
	["폴란드", "48"],
	["독일", "49"],
	["포클랜드 제도", "500"],
	["사우스조지아 사우스샌드위치 제도", "500"],
	["벨리즈", "501"],
	["과테말라", "502"],
	["엘살바도르", "503"],
	["온두라스", "504"],
	["니카라과", "505"],
	["코스타리카", "506"],
	["파나마", "507"],
	["생피에르 미클롱", "508"],
	["아이티", "509"],
	["페루", "51"],
	["멕시코", "52"],
	["쿠바", "53"],
	["아르헨티나", "54"],
	["브라질", "55"],
	["칠레", "56"],
	["콜롬비아", "57"],
	["베네수엘라", "58"],
	["생마르탱", "590"],
	["과들루프", "590"],
	["생바르텔레미", "590"],
	["볼리비아", "591"],
	["가이아나", "592"],
	["에콰도르", "593"],
	["프랑스령 기아나", "594"],
	["파라과이", "595"],
	["마르티니크", "596"],
	["수리남", "597"],
	["우루과이", "598"],
	["보네르섬", "599"],
	["퀴라소", "599"],
	["말레이시아", "60"],
	["오스트레일리아", "61"],
	["코코스 제도", "61"],
	["크리스마스섬", "61"],
	["인도네시아", "62"],
	["필리핀", "63"],
	["뉴질랜드", "64"],
	["싱가포르", "65"],
	["태국", "66"],
	["동티모르", "670"],
	["허드 맥도널드 제도", "672"],
	["남극", "672"],
	["노퍽섬", "672"],
	["브루나이", "673"],
	["나우루", "674"],
	["파푸아뉴기니", "675"],
	["통가", "676"],
	["솔로몬 제도", "677"],
	["바누아투", "678"],
	["피지", "679"],
	["팔라우", "680"],
	["왈리스 푸투나", "681"],
	["쿡 제도", "682"],
	["니우에", "683"],
	["사모아", "685"],
	["키리바시", "686"],
	["누벨칼레도니", "687"],
	["투발루", "688"],
	["프랑스령 폴리네시아", "689"],
	["토켈라우", "690"],
	["미크로네시아 연방", "691"],
	["마셜 제도", "692"],
	["러시아", "7"],
	["카자흐스탄", "7"],
	["일본", "81"],
	["대한민국", "82"],
	["베트남", "84"],
	["조선민주주의인민공화국", "850"],
	["홍콩", "852"],
	["마카오", "853"],
	["캄보디아", "855"],
	["라오스", "856"],
	["중국", "86"],
	["핏케언 제도", "870"],
	["방글라데시", "880"],
	["대만", "886"],
	["튀르키예", "90"],
	["인도", "91"],
	["파키스탄", "92"],
	["아프가니스탄", "93"],
	["스리랑카", "94"],
	["미얀마", "95"],
	["몰디브", "960"],
	["레바논", "961"],
	["요르단", "962"],
	["시리아", "963"],
	["이라크", "964"],
	["쿠웨이트", "965"],
	["사우디아라비아", "966"],
	["예멘", "967"],
	["오만", "968"],
	["팔레스타인", "970"],
	["아랍에미리트", "971"],
	["이스라엘", "972"],
	["바레인", "973"],
	["카타르", "974"],
	["부탄", "975"],
	["몽골", "976"],
	["네팔", "977"],
	["이란", "98"],
	["타지키스탄", "992"],
	["투르크메니스탄", "993"],
	["아제르바이잔", "994"],
	["조지아", "995"],
	["키르기스스탄", "996"],
	["우즈베키스탄", "998"],

]
