
export default [
	["Afganistán", "93"],
	["Albania", "355"],
	["Alemania", "49"],
	["Andorra", "376"],
	["Angola", "244"],
	["Anguila", "1-264"],
	["Antigua y Barbuda", "1-268"],
	["Antártida", "672"],
	["Arabia Saudita", "966"],
	["Argelia", "213"],
	["Argentina", "54"],
	["Armenia", "374"],
	["Aruba", "297"],
	["Australia", "61"],
	["Austria", "43"],
	["Azerbaiyán", "994"],
	["Bahamas", "1-242"],
	["Bangladés", "880"],
	["Barbados", "1-246"],
	["Baréin", "973"],
	["Belice", "501"],
	["Benín", "229"],
	["Bermudas", "1-441"],
	["Bielorrusia", "375"],
	["Birmania", "95"],
	["Bolivia", "591"],
	["Bonaire, San Eustaquio y Saba", "599"],
	["Bosnia y Herzegovina", "387"],
	["Botsuana", "267"],
	["Brasil", "55"],
	["Brunéi", "673"],
	["Bulgaria", "359"],
	["Burkina Faso", "226"],
	["Burundi", "257"],
	["Bután", "975"],
	["Bélgica", "32"],
	["Cabo Verde", "238"],
	["Camboya", "855"],
	["Camerún", "237"],
	["Canadá", "1"],
	["Catar", "974"],
	["Chad", "235"],
	["Chile", "56"],
	["China", "86"],
	["Chipre", "357"],
	["Ciudad del Vaticano", "39-06"],
	["Colombia", "57"],
	["Comoras", "269"],
	["Corea del Norte", "850"],
	["Corea del Sur", "82"],
	["Costa Rica", "506"],
	["Costa de Marfil", "225"],
	["Croacia", "385"],
	["Cuba", "53"],
	["Curazao", "599"],
	["Dinamarca", "45"],
	["Dominica", "1-767"],
	["Ecuador", "593"],
	["Egipto", "20"],
	["El Salvador", "503"],
	["Emiratos Árabes Unidos", "971"],
	["Eritrea", "291"],
	["Eslovaquia", "421"],
	["Eslovenia", "386"],
	["España", "34"],
	["Estados Unidos", "1"],
	["Estonia", "372"],
	["Etiopía", "251"],
	["Filipinas", "63"],
	["Finlandia", "358"],
	["Fiyi", "679"],
	["Francia", "33"],
	["Gabón", "241"],
	["Gambia", "220"],
	["Georgia", "995"],
	["Ghana", "233"],
	["Gibraltar", "350"],
	["Granada", "1-473"],
	["Grecia", "30"],
	["Groenlandia", "299"],
	["Guadalupe", "590"],
	["Guam", "1-671"],
	["Guatemala", "502"],
	["Guayana Francesa", "594"],
	["Guernsey", "44"],
	["Guinea", "224"],
	["Guinea Ecuatorial", "240"],
	["Guinea-Bisáu", "245"],
	["Guyana", "592"],
	["Haití", "509"],
	["Honduras", "504"],
	["Hong Kong", "852"],
	["Hungría", "36"],
	["India", "91"],
	["Indonesia", "62"],
	["Irak", "964"],
	["Irlanda", "353"],
	["Irán", "98"],
	["Isla Bouvet", "47"],
	["Isla Norfolk", "672"],
	["Isla de Man", "44"],
	["Isla de Navidad", "61"],
	["Islandia", "354"],
	["Islas Caimán", "1-345"],
	["Islas Cocos", "61"],
	["Islas Cook", "682"],
	["Islas Feroe", "298"],
	["Islas Georgias del Sur y Sandwich del Sur", "500"],
	["Islas Heard y McDonald", "672"],
	["Islas Malvinas", "500"],
	["Islas Marianas del Norte", "1-670"],
	["Islas Marshall", "692"],
	["Islas Pitcairn", "870"],
	["Islas Salomón", "677"],
	["Islas Turcas y Caicos", "1-649"],
	["Islas Ultramarinas Menores de los Estados Unidos", ""],
	["Islas Vírgenes Británicas", "1-284"],
	["Islas Vírgenes de los Estados Unidos", "1-340"],
	["Israel", "972"],
	["Italia", "39"],
	["Jamaica", "1-876"],
	["Japón", "81"],
	["Jersey", "44"],
	["Jordania", "962"],
	["Kazajistán", "7"],
	["Kenia", "254"],
	["Kirguistán", "996"],
	["Kiribati", "686"],
	["Kuwait", "965"],
	["Laos", "856"],
	["Lesoto", "266"],
	["Letonia", "371"],
	["Liberia", "231"],
	["Libia", "218"],
	["Liechtenstein", "423"],
	["Lituania", "370"],
	["Luxemburgo", "352"],
	["Líbano", "961"],
	["Macao", "853"],
	["Macedonia del Norte", "389"],
	["Madagascar", "261"],
	["Malasia", "60"],
	["Malaui", "265"],
	["Maldivas", "960"],
	["Mali", "223"],
	["Malta", "356"],
	["Marruecos", "212"],
	["Martinica", "596"],
	["Mauricio", "230"],
	["Mauritania", "222"],
	["Mayotte", "262"],
	["Micronesia", "691"],
	["Moldavia", "373"],
	["Mongolia", "976"],
	["Montenegro", "382"],
	["Montserrat", "1-664"],
	["Mozambique", "258"],
	["México", "52"],
	["Mónaco", "377"],
	["Namibia", "264"],
	["Nauru", "674"],
	["Nepal", "977"],
	["Nicaragua", "505"],
	["Nigeria", "234"],
	["Niue", "683"],
	["Noruega", "47"],
	["Nueva Caledonia", "687"],
	["Nueva Zelanda", "64"],
	["Níger", "227"],
	["Omán", "968"],
	["Pakistán", "92"],
	["Palaos", "680"],
	["Palestina", "970"],
	["Panamá", "507"],
	["Papúa Nueva Guinea", "675"],
	["Paraguay", "595"],
	["Países Bajos", "31"],
	["Perú", "51"],
	["Polinesia Francesa", "689"],
	["Polonia", "48"],
	["Portugal", "351"],
	["Puerto Rico", "1"],
	["Reino Unido", "44"],
	["República Centroafricana", "236"],
	["República Checa", "420"],
	["República Democrática del Congo", "243"],
	["República Dominicana", "1-809"],
	["República del Congo", "242"],
	["República Árabe Saharaui Democrática", "212"],
	["Reunión", "262"],
	["Ruanda", "250"],
	["Rumania", "40"],
	["Rusia", "7"],
	["Samoa", "685"],
	["Samoa Americana", "1-684"],
	["San Bartolomé", "590"],
	["San Cristóbal y Nieves", "1-869"],
	["San Marino", "378"],
	["San Martín", "590"],
	["San Martín", "1-721"],
	["San Pedro y Miquelón", "508"],
	["San Vicente y las Granadinas", "1-784"],
	["Santa Elena, Ascensión y Tristán de Acuña", "290n"],
	["Santa Lucía", "1-758"],
	["Santo Tomé y Príncipe", "239"],
	["Senegal", "221"],
	["Serbia", "381p"],
	["Seychelles", "248"],
	["Sierra Leona", "232"],
	["Singapur", "65"],
	["Siria", "963"],
	["Somalia", "252"],
	["Sri Lanka", "94"],
	["Suazilandia", "268"],
	["Sudáfrica", "27"],
	["Sudán", "249"],
	["Sudán del Sur", "211"],
	["Suecia", "46"],
	["Suiza", "41"],
	["Surinam", "597"],
	["Svalbard y Jan Mayen", "47"],
	["Tailandia", "66"],
	["Taiwán (República de China)", "886"],
	["Tanzania", "255"],
	["Tayikistán", "992"],
	["Territorio Británico del Océano Índico", "246"],
	["Tierras Australes y Antárticas Francesas", "262"],
	["Timor Oriental", "670"],
	["Togo", "228"],
	["Tokelau", "690"],
	["Tonga", "676"],
	["Trinidad y Tobago", "1-868"],
	["Turkmenistán", "993"],
	["Turquía", "90"],
	["Tuvalu", "688"],
	["Túnez", "216"],
	["Ucrania", "380"],
	["Uganda", "256"],
	["Uruguay", "598"],
	["Uzbekistán", "998"],
	["Vanuatu", "678"],
	["Venezuela", "58"],
	["Vietnam", "84"],
	["Wallis y Futuna", "681"],
	["Yemen", "967"],
	["Yibuti", "253"],
	["Zambia", "260"],
	["Zimbabue", "263"],
	["Åland", "358"],

]
