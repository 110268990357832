import { useContext } from "react"
import styles from "./P8.module.scss"
import { LanguageContext } from "../../context/useLanguageContext"

export default () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div className={styles['p8']}>
            <div className={styles['p8__content-wrapper']}>
                <div className={styles['p8__content']}>
                {t('Born of Moonlight, Bound by Mystery.')}
                </div>
            </div>
        </div>
    )
}


