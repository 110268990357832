
import { PropsWithChildren, useContext, useEffect, useRef, useState } from "react"
import style from "./SwiperItem.module.scss"
import { SwiperContext } from "./Swiper"
import { HomeSwiperContext } from "./HomepageSwiper"

export default (props: PropsWithChildren<{idx: number, height?: string}>) => {

    const {
        idx,
        height = "100%"
    } = props

    const {setItem} = useContext(HomeSwiperContext)
    const [realHeight, setRealHeight] = useState<number>()

    const pageRef = useRef<HTMLDivElement>(null)

    // 浏览器窗口高度发生改变触发
    // const resizeChange = (e: any) => {
    //     console.log(e.contentRect.height, e, '监听元素正在发生变化!')
    //     setItem({
    //         idx: idx,
    //         height: e.contentRect.height
    //     })

    // };

    useEffect(() => {
        //   // 监听的函数
        //     const resize = new ResizeObserver((e) => {
        //         if (!Array.isArray(e) || !e.length) return;
        //         for (const ent of e) {
        //         resizeChange(ent);
        //         }
        //     });
        //     // 传入监听对象
        //     resize.observe(pageRef.current as any);
        //     return () => { resize.unobserve(pageRef?.current as any); };
        const height = pageRef.current?.getBoundingClientRect().height as number
        setItem({
            idx,
            height: height
        })
        setRealHeight(height)
    }, [])

    return (
        <div className="swiper-item" ref={pageRef} style={{height: realHeight}}>{props.children}</div>
    )
}