
export default [
	["Afghanistan", "93"],
	["Albanien", "355"],
	["Algerien", "213"],
	["Amerikanisch-Samoa", "1-684"],
	["Amerikanische Jungferninseln", "1-340"],
	["Andorra", "376"],
	["Angola", "244"],
	["Anguilla", "1-264"],
	["Antarktis", "672"],
	["Antigua und Barbuda", "1-268"],
	["Argentinien", "54"],
	["Armenien", "374"],
	["Aruba", "297"],
	["Aserbaidschan", "994"],
	["Australien", "61"],
	["Bahamas", "1-242"],
	["Bahrain", "973"],
	["Bangladesch", "880"],
	["Barbados", "1-246"],
	["Belarus", "375"],
	["Belgien", "32"],
	["Belize", "501"],
	["Benin", "229"],
	["Bermuda", "1-441"],
	["Bhutan", "975"],
	["Bolivien", "591"],
	["Bonaire, Saba, Sint Eustatius", "599"],
	["Bosnien und Herzegowina", "387"],
	["Botswana", "267"],
	["Bouvetinsel", "47"],
	["Brasilien", "55"],
	["Britische Jungferninseln", "1-284"],
	["Britisches Territorium im Indischen Ozean", "246"],
	["Brunei", "673"],
	["Bulgarien", "359"],
	["Burkina Faso", "226"],
	["Burundi", "257"],
	["Cayman Islands (Kaimaninseln)", "1-345"],
	["Chile", "56"],
	["Cookinseln", "682"],
	["Costa Rica", "506"],
	["Curaçao", "599"],
	["Demokratische Republik Kongo", "243"],
	["Deutschland", "49"],
	["Dominica", "1-767"],
	["Dominikanische Republik", "1-809"],
	["Dschibuti", "253"],
	["Dänemark", "45"],
	["Ecuador", "593"],
	["El Salvador", "503"],
	["Elfenbeinküste (Côte d’Ivoire)", "225"],
	["Eritrea", "291"],
	["Estland", "372"],
	["Eswatini", "268"],
	["Falklandinseln", "500"],
	["Fidschi", "679"],
	["Finnland", "358"],
	["Frankreich", "33"],
	["Französisch-Guayana", "594"],
	["Französisch-Polynesien", "689"],
	["Französische Süd- und Antarktisgebiete", "262"],
	["Färöer", "298"],
	["Föderierte Staaten von Mikronesien", "691"],
	["Gabun", "241"],
	["Gambia", "220"],
	["Georgien", "995"],
	["Ghana", "233"],
	["Gibraltar", "350"],
	["Grenada", "1-473"],
	["Griechenland", "30"],
	["Grönland", "299"],
	["Guadeloupe", "590"],
	["Guam", "1-671"],
	["Guatemala", "502"],
	["Guernsey (Kanalinsel)", "44"],
	["Guinea", "224"],
	["Guinea-Bissau", "245"],
	["Guyana", "592"],
	["Haiti", "509"],
	["Heard und McDonaldinseln", "672"],
	["Honduras", "504"],
	["Hongkong", "852"],
	["Indien", "91"],
	["Indonesien", "62"],
	["Irak", "964"],
	["Iran", "98"],
	["Irland", "353"],
	["Island", "354"],
	["Isle of Man", "44"],
	["Israel", "972"],
	["Italien", "39"],
	["Jamaika", "1-876"],
	["Japan", "81"],
	["Jemen", "967"],
	["Jersey (Kanalinsel)", "44"],
	["Jordanien", "962"],
	["Kambodscha", "855"],
	["Kamerun", "237"],
	["Kanada", "1"],
	["Kap Verde (Cabo Verde)", "238"],
	["Kasachstan", "7"],
	["Katar", "974"],
	["Kenia", "254"],
	["Kirgisistan", "996"],
	["Kiribati", "686"],
	["Kokosinseln", "61"],
	["Kolumbien", "57"],
	["Komoren", "269"],
	["Kroatien", "385"],
	["Kuba", "53"],
	["Kuwait", "965"],
	["Laos", "856"],
	["Lesotho", "266"],
	["Lettland", "371"],
	["Libanon", "961"],
	["Liberia", "231"],
	["Libyen", "218"],
	["Liechtenstein", "423"],
	["Litauen", "370"],
	["Luxemburg", "352"],
	["Macau", "853"],
	["Madagaskar", "261"],
	["Malawi", "265"],
	["Malaysia", "60"],
	["Malediven", "960"],
	["Mali", "223"],
	["Malta", "356"],
	["Marokko", "212"],
	["Marshallinseln", "692"],
	["Martinique", "596"],
	["Mauretanien", "222"],
	["Mauritius", "230"],
	["Mayotte", "262"],
	["Mexiko", "52"],
	["Moldau", "373"],
	["Monaco", "377"],
	["Mongolei", "976"],
	["Montenegro", "382"],
	["Montserrat", "1-664"],
	["Mosambik", "258"],
	["Myanmar", "95"],
	["Namibia", "264"],
	["Nauru", "674"],
	["Nepal", "977"],
	["Neukaledonien", "687"],
	["Neuseeland", "64"],
	["Nicaragua", "505"],
	["Niederlande", "31"],
	["Niger", "227"],
	["Nigeria", "234"],
	["Niue", "683"],
	["Nordkorea", "850"],
	["Nordmazedonien", "389"],
	["Norfolkinsel", "672"],
	["Norwegen", "47"],
	["Nördliche Marianen", "1-670"],
	["Oman", "968"],
	["Pakistan", "92"],
	["Palau", "680"],
	["Palästina", "970"],
	["Panama", "507"],
	["Papua-Neuguinea", "675"],
	["Paraguay", "595"],
	["Peru", "51"],
	["Philippinen", "63"],
	["Pitcairninseln", "870"],
	["Polen", "48"],
	["Portugal", "351"],
	["Puerto Rico", "1"],
	["Republik Kongo", "242"],
	["Romania", "40"],
	["Ruanda", "250"],
	["Russland", "7"],
	["Réunion", "262"],
	["Saint-Barthélemy", "590"],
	["Saint-Martin (französischer Teil)", "590"],
	["Saint-Pierre und Miquelon", "508"],
	["Salomonen", "677"],
	["Sambia", "260"],
	["Samoa", "685"],
	["San Marino", "378"],
	["Saudi-Arabien", "966"],
	["Schweden", "46"],
	["Schweiz (Confoederatio Helvetica)", "41"],
	["Senegal", "221"],
	["Serbien", "381p"],
	["Seychellen", "248"],
	["Sierra Leone", "232"],
	["Simbabwe", "263"],
	["Singapur", "65"],
	["Sint Maarten", "1-721"],
	["Slowakei", "421"],
	["Slowenien", "386"],
	["Somalia", "252"],
	["Spanien", "34"],
	["Spitzbergen und Jan Mayen", "47"],
	["Sri Lanka", "94"],
	["St. Helena, Ascension und Tristan da Cunha", "290n"],
	["St. Kitts und Nevis", "1-869"],
	["St. Lucia", "1-758"],
	["St. Vincent und die Grenadinen", "1-784"],
	["Sudan", "249"],
	["Suriname", "597"],
	["Syrien", "963"],
	["São Tomé und Príncipe", "239"],
	["Südafrika", "27"],
	["Südgeorgien und die Südlichen Sandwichinseln", "500"],
	["Südkorea", "82"],
	["Südsudan", "211"],
	["Tadschikistan", "992"],
	["Taiwan", "886"],
	["Tansania", "255"],
	["Thailand", "66"],
	["Timor-Leste", "670"],
	["Togo", "228"],
	["Tokelau", "690"],
	["Tonga", "676"],
	["Trinidad und Tobago", "1-868"],
	["Tschad", "235"],
	["Tschechien", "420"],
	["Tunesien", "216"],
	["Turkmenistan", "993"],
	["Turks- und Caicosinseln", "1-649"],
	["Tuvalu", "688"],
	["Türkei", "90"],
	["Uganda", "256"],
	["Ukraine", "380"],
	["Ungarn", "36"],
	["United States Minor Outlying Islands", ""],
	["Uruguay", "598"],
	["Usbekistan", "998"],
	["Vanuatu", "678"],
	["Vatikanstadt", "39-06"],
	["Venezuela", "58"],
	["Vereinigte Arabische Emirate", "971"],
	["Vereinigte Staaten", "1"],
	["Vereinigtes Königreich", "44"],
	["Vietnam", "84"],
	["Volksrepublik China", "86"],
	["Wallis und Futuna", "681"],
	["Weihnachtsinsel", "61"],
	["Westsahara", "212"],
	["Zentralafrikanische Republik", "236"],
	["Zypern", "357"],
	["Ägypten", "20"],
	["Äquatorialguinea", "240"],
	["Äthiopien", "251"],
	["Åland", "358"],
	["Österreich", "43"],

]
