
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react"
import style from "./Swiper.module.scss"
import { HomepageContext } from "../Homepage"
import { BgmContext } from "../../../context/useBgmContext"
import { useNavigate } from "react-router-dom"


export interface HomeSwiperContextDTO{
    current: number
    setItem: (item: SwiperItemDTO) => void
    setStep: (step: number) => void
}
export const HomeSwiperContext = createContext<HomeSwiperContextDTO>({
  current: 0,
  setItem: function (item: SwiperItemDTO) {
    throw new Error("Function not implemented.")
  },
  setStep: function (step: number): void {
    throw new Error("Function not implemented.")
  }
})
export interface SwiperItemDTO{
    idx: number
    height: number
}

let lastTime = 0

export default (props: PropsWithChildren<{}>) => {

    // let [step, setStep] = useState<number>(0)
    
    const [items, setItems] = useState<SwiperItemDTO[]>([])
    const {checkNewUser, step, incrStep, decrStep} = useContext(HomepageContext)
    const {playing, play, pause} = useContext(BgmContext)
    const [lastScrollY, setLastScrollY] = useState<number>()

    const navigate = useNavigate()

    const handleWheel = useCallback((event: any) => {

      const delta = event.deltaY; // deltaY值为正表示向下滚动，为负表示向上滚动
      // if(Math.abs(delta) < 30) return

      //新用户准备
      checkNewUser()

      var currentTime = Date.now()
      const cost = currentTime - lastTime
      console.log("cost: ", cost)
      if (cost <= 1300) {
        return
      }
      lastTime = currentTime
      // setLastTime(current => {
      //   return currentTime
      // })

      // const delta = event.deltaY; // deltaY值为正表示向下滚动，为负表示向上滚动
      const down = delta > 0

      if(down){
        incrStep()
      }else{
        decrStep()
        // setStep(current => {
        //   console.log("current step: ", current)
        //   let step = current - 1
        //   step = Math.max(0, step)
        //   navigate(`/p/${step}`)
        //   return current
        // })
      }
      
    }, [items]);


    const onScroll = (event: any) => {
      console.log("onScroll: ", document.scrollingElement?.scrollTop)
    }

    
    const getStyle = () => {
        
        let offsetX = 0
        for(let i = 0; i < step; i++){
            offsetX += items[i]?.height??0
        }
        items[step] && (offsetX -= (window.innerHeight - items[step].height))
        // console.log("step changed, step: ", step, "offsetX:", offsetX)
        return {
            transform: `translateY(-${offsetX}px)`
        }
    }

    const setItem = (item: SwiperItemDTO) => {
      setItems(current => {
        current[item.idx] = {...item}
        return [...current]
      })
    }

    const setStep = () => {
      // throw new Error("unimplement")
      console.trace("setStep trigger")
      throw new Error("unimplement")
    }

    useEffect(() => {

      // const keydownHandle = (e: any) => {
      //     console.log("key press: ", e.keyCode)
      //     const keyCode = e.keyCode
      //     if(keyCode == 115 || keyCode == 119){
      //     let down = keyCode == 115
      //     console.log("page down: ", down)
      //     handleWheel({deltaY: down?1:-1})
      //     }
      // }

      //   // 添加鼠标滚轮事件监听器
      //   window.addEventListener('keypress', keydownHandle);
      //   // // 清理函数，移除事件监听器
      //   return () => {
      //   window.removeEventListener('keypress', keydownHandle);
      //   };


    }, []);

    useEffect(() => {
      // console.log("items changed: ", items)
    }, [items])

    // useEffect(() => {
    //   _setStep(step)
    // }, [step])

    // useEffect(() => {
    //   setStep(contextStep)
    // }, [step])

    return (
        <HomeSwiperContext.Provider value={{current: step, setStep, setItem}}>
          <div className={style['swiper']} style={getStyle()} onWheel={handleWheel} >
              {props.children}
          </div>
        </HomeSwiperContext.Provider>
    )
}