import styles from "./P1.module.scss"

import VideoPoster from './assets/images/p1-h.png'
import Video from './assets/videos/index.mp4'

import Steam from './assets/images/icon/steam.png'
import X from './assets/images/icon/x.png'
import Mail from './assets/images/icon/mail.png'
import Iwatch from './assets/images/icon/iwatch.png'
import Switch from './assets/images/icon/switch.png'
import { CSSProperties, PropsWithChildren, useContext, useEffect, useRef, useState } from "react"
import { LanguageContext } from "../../context/useLanguageContext"
import RegionDicts from "../../regions/dicts"
import { HomepageContext } from "../homepage/Homepage"

import Api, { RegisterRequest } from "../../api/api"
import Maidian, { MaidianEventType } from "../../api/maidian"

import {STEAM_URL, X_URL, IWATCH_URL} from "../../constants"

function isMobile (mobile: string) {
    return /^\d+$/.test(mobile)
  }

export default () => {
    const {step} = useContext(HomepageContext)
    const {
        maidianEventSend,
    } = Maidian
    const iconClick = (type: string, url: string) => {
        maidianEventSend(MaidianEventType.ICON_CLICK, type)
        window.open(url, "_href")
    }

    useEffect(() => {
        let video = document.getElementById("p1Video") as HTMLVideoElement
        if (step === 0) {
            video.play()
            .catch(err => {
                console.log('p1播放失败')
            })
        } else {
            if (!video.paused) {
                video.pause()
            }
        }
    }, [step])

    return (
        <div data-step="1" className={styles['p1']}>
            {/* <img src={VideoPoster} className={styles['p1-bg']}/> */}
            <video id="p1Video" className={styles['p1-bg']} poster={VideoPoster} autoPlay loop muted playsInline preload="auto">
                <source src={Video}  type="video/mp4"/>
            </video>
            <div className={styles['p1__footer']}>
                <Zck/>
                <div className={styles['p1__footer-icons']}>
                    <img className={styles['p1__footer-icons-icon']} src={Steam}
                        onClick={() => {iconClick('STEAM', STEAM_URL)}}
                    />
                    <img className={styles['p1__footer-icons-icon']} src={X}
                        onClick={() => {iconClick('X', X_URL)}}
                    />
                    {/* <img className={styles['p1__footer-icons-icon']} src={Mail}/> */}
                    <img className={styles['p1__footer-icons-icon']} src={Iwatch}
                        onClick={() => {iconClick('IWATCH', IWATCH_URL)}}
                    />
                    <img className={styles['p1__footer-icons-icon']} src={Switch}/>
                </div>
            </div>
        </div>
    )
}


const Zck = () => {

    const {
        register,
    } = Api
    const {
        maidianRegister,
    } = Maidian

    const {showHeader} = useContext(HomepageContext)
    const {translate: t, language} = useContext(LanguageContext)

    const [registered, setRegistered] = useState<boolean>(false)
    const [needRegion, setNeedRegion] = useState<boolean>(false)
    const [text, setText] = useState<string>("")
    const [region, setRegion] = useState<string>("00")

    const zckRef = useRef<HTMLDivElement>(null)
    const [inputStyle, setInputStyle] = useState<CSSProperties>({})
    const [countryInputStyle, setCountryInputStyle] = useState<CSSProperties>({})
    const [textInputStyle, setTextInputStyle] = useState<CSSProperties>({})
    const [btnRawWidth, setBtnRawWidth] = useState<number>(-1)
    const [btnStyle, setBtnStyle] = useState<CSSProperties>({})
    

    const inputHandle = (text: string) => {
        setText(text)
        setNeedRegion(isMobile(text))
    }

    const registerHandle = () => {
        if (!text) {
            //alert('手机或邮箱不能为空')
            return
        }
        setRegistered(true)

        maidianRegister()

        let phone
        let mail
        if (isMobile(text)) {
            phone = text
        } else {
            mail = text
        }

        register({region: region, phoneNumber: phone, email: mail })
        .then(res => {
            console.log('注册成功')
        }).catch(err => {
            console.error('注册失败', err)
        })
    }

    useEffect(() => {
        const btnRawWidth = zckRef.current?.querySelector("#registerBtn")?.getBoundingClientRect().width??0
        console.log("btnRawWidth: ", btnRawWidth)
        setBtnRawWidth(btnRawWidth)
    }, [])

    useEffect(() => {
        
        if(!needRegion){
            if(zckRef.current){
                // const rect = zckRef.current.getBoundingClientRect()
                const countryRect = zckRef.current.querySelector("#countryInput")?.getBoundingClientRect() as DOMRect
                const textInputRect = zckRef.current.querySelector("#textInput")?.getBoundingClientRect() as DOMRect
                // const tranX = textInputRect.left - countryRect.left
                setInputStyle({
                    transform: `translateX(-${countryRect.width}px)`
                })
                setCountryInputStyle({
                    opacity: 0
                })
                setTextInputStyle({
                    width: `calc(${countryRect.width}px + ${textInputRect.width}px)`
                })
            } 
        }else{
            setInputStyle({
                transform: `translateX(-${0}px)`
            })
            setCountryInputStyle({
                opacity: 1
            })
            setTextInputStyle({
                width: `100%`
            })
        }

    }, [needRegion])

    useEffect(() => {

        if(registered){
            setBtnStyle({
                maxWidth: "100%"
            })
        }else{
            let width = btnRawWidth
            if(btnRawWidth == -1){
                width = zckRef.current?.querySelector("#registerBtn")?.getBoundingClientRect().width??0
            }
            setBtnStyle({
                maxWidth: `${width}px`
            })
        }

    }, [registered])

    return (
        <div className={[styles['zck'], showHeader?styles['zck_show']:''].join(" ")} ref={zckRef} >
            <div className={[styles['zck__input'], registered?styles['zck__input_hidden']:''].join(" ")} style={inputStyle} >

                <RegionSelection onChange={setRegion}>
                    <div id="countryInput" className={[styles['zck__input__country-wrapper']].join(" ")} style={countryInputStyle}>
                        <div 
                        className={
                            [styles['zck__input__country'],
                            language === 'ru'?styles['input-ru']:'',
                            language === 'pt'?styles['input-pt']:'',
                            language === 'es'?styles['input-es']:'',
                            language === 'fr'?styles['input-fr']:'',
                            language === 'it'?styles['input-it']:'',
                        ].join(" ")}
                        >+{region}</div>
                    </div>
                </RegionSelection>
                
                <div id="textInput" className={[styles['zck__input__text-wrapper']].join(" ")} style={textInputStyle}>
                    <input
                    className={[
                        styles['zck__input__text'],
                        language === 'ru'?styles['input-ru']:'',
                        language === 'pt'?styles['input-pt']:'',
                        language === 'es'?styles['input-es']:'',
                        language === 'fr'?styles['input-fr']:'',
                        language === 'it'?styles['input-it']:'',
                    ].join(" ")} 
                    placeholder={t("Email Address / Phone Number")} value={text} onChange={e => inputHandle(e.target.value)}></input>
                </div>
            </div>
            <div className={styles['zck__block']}></div>
            <div id="registerBtn"  className={styles['zck__btn-wrapper']} style={btnStyle}>
                <div className={[styles['zck__btn'], language === 'fr'?styles['btn-fr']:'', language === 'ru'?styles['btn-ru']:''].join(" ")} onClick={registerHandle}>
                    {registered?t('Registered Successfully'):t('Register Now')}
                </div>
            </div>
        </div>
        
    )

}

const RegionSelection = (props: PropsWithChildren<{onChange: (region: string) => void}>) => {

    const {translate: t, language, setLanguage} = useContext(LanguageContext)
    const [activate, setActivate] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement>(null)
    const [positionStyle, setPositionStyle] = useState<CSSProperties>({})
    const [region, setRegion] = useState<string>("+00")
    const [selectRegion, setSelectRegion] = useState<string>("")
    const [regions, setRegions] = useState<any[]>(RegionDicts.getDict(language))

    const clickHandle = () => {
        const _activate = !activate
        setActivate(_activate)
    }

    const changeRegion = (region: string, name: string) => {
        setRegion(region)
        setSelectRegion(`${region}_${name}`)
        props.onChange(region)
    }

    useEffect(() => {
        const rect = ref.current?.getBoundingClientRect()
        rect && setPositionStyle({
            left: 0,
            bottom: `calc(50px * var(--scale))`
        })
    }, [])

    useEffect(() => {
        setRegions(RegionDicts.getDict(language))
    }, [language])

    return (
        <div className={styles["region-selection"]} onClick={clickHandle} ref={ref}>
            <div className={styles['region-selection__parent']} >{props.children}</div>
            <div 
                className={[styles["region-selection__ul-wrapper"], !activate?styles["region-selection__ul-wrapper_hidden"]:''].join(" ")}
                style={positionStyle}
            >
                <div className={styles["region-selection__ul"]}>
                    {
                        regions.map((it, idx) => {
                            return (
                                <div key={idx} className={[styles['region-selection__li'], selectRegion===`${it[1]}_${it[0]}`?styles['region-selection__li_select']:''].join(" ")} onClick={() => changeRegion(it[1], it[0])}>
                                    <div className={styles['region-selection__li__phone']}>{it[1]}</div>
                                    <div className={styles['region-selection__li__country']}>{it[0]}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )

}

