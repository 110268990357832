
import styles from "./Header.module.scss";

import logoSvg from "../../assets/images/logo.svg"
import logoPng from "./assets/images/logo.png"
import languageSvg from "./assets/images/language.png"
import languageActivateSvg from "./assets/images/language_hover.png"
import soundOnSvg from "./assets/images/soundon.png"
import soundOffSvg from "./assets/images/soundoff_d.png"
import { CSSProperties, PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { HomepageContext } from "../../mobile-pages/homepage/Homepage";
import backPng from "./assets/images/back.png"
import { useNavigate } from "react-router-dom";

import languageOptions from "../../api/languageOptions";
import { LanguageContext } from "../../context/useLanguageContext";
import { BgmContext } from "../../context/useBgmContext";


export default (props: {white?: boolean, className?: String, goback?: boolean}) => {

    const {
        white,
        className: exClassName,
        goback,
    } = props
    let navigate = useNavigate();

    return (
        <div id="header" className={[styles['header'], exClassName].join(" ")}>
            <div className={styles['header-grid']}>
                <div className={styles['header-grid-left']}>
                    {
                        goback
                        ?
                        <img src={backPng} className={styles['header-grid-left-goback']} onClick={() => navigate(-1)}></img>
                        :
                        <></>
                    }
                </div>
                <div className={styles['header-grid-center']}>
                    <img className={styles['header-grid-center-logo']} src={logoSvg} onClick={() => {navigate("/")}}/>
                </div>
                <div className={styles['header-grid-right']}>
                    {/* <img src={languageSvg} className={styles['header-grid-right-language']}></img>
                    <img src={soundOnSvg} className={styles['header-grid-right-sound']}></img> */}
                    <LanguageBtn/>
                    <SoundBtn />
                </div>
            </div>
        </div>
    )
}

const LanguageBtn = () => {

    const [activate, setActivate] = useState<boolean>(false)
    

    return (
        <LanguageSelection onActivate={setActivate}>
            <img src={activate?languageActivateSvg:languageSvg} className={styles['header-grid-right-language']}></img>
        </LanguageSelection>
    )
}

const SoundBtn = () => {
    const {playing, play, pause} = useContext(BgmContext)

    const changeActivate = () => {
        playing?pause():play()
    }

    return (
        <img src={playing?soundOnSvg:soundOffSvg} className={styles['header-grid-right-sound']} onClick={changeActivate}></img>
        
    )
}

const LanguageSelection = (props: PropsWithChildren<{onActivate: (activate: boolean) => void}>) => {

    const {translate: t, language, setLanguage} = useContext(LanguageContext)
    const [activate, setActivate] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement>(null)
    const [positionStyle, setPositionStyle] = useState<CSSProperties>({})

    const clickHandle = () => {
        const _activate = !activate
        console.log("clickHandle change language")
        setActivate(_activate)
        props.onActivate(_activate)
    }

    useEffect(() => {
        // const rect = ref.current?.getBoundingClientRect()
        // rect && setPositionStyle({
        //     right: -(window.innerWidth - rect.right),
        //     top: rect.bottom
        // })
        const rect = document.getElementById('languageSelection')?.getBoundingClientRect()
        rect && setPositionStyle({
            right: -(window.innerWidth - rect.right),
            top: rect.bottom
        })
    }, [])

    return (
        <div className={styles["language-selection"]} onClick={clickHandle} id="languageSelection"  ref={ref}>
            <div className={styles['language-selection__parent']} >{props.children}</div>
            <div 
                className={[styles["language-selection__ul-wrapper"], !activate?styles["language-selection__ul-wrapper_hidden"]:''].join(" ")}
                style={positionStyle}
            >
                <div className={styles["language-selection__ul"]}>
                    {
                        languageOptions.map((lan, idx) => {
                            return (
                                <div key={idx}
                                onClick={() => setLanguage(lan.value)}
                                className={[styles["language-selection__li"], language==lan.value?styles["language-selection__li_select"]:""].join(" ")}>{t(lan.label)}</div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
    
}

