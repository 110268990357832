import { useContext, useEffect, useRef, useState } from "react"
import styles from "./P7.module.scss"

import CardSvg from './assets/images/card.svg'
import CardImg1 from './assets/images/card_1.png'
import CardImg2 from './assets/images/card_2.png'
import CardImg3 from './assets/images/card_3.png'
import CardImg4 from './assets/images/card_4.png'
import CardImg5 from './assets/images/card_5.png'
import BlossomVideo from './assets/videos/Blossom.mp4'
import WinterwarriorVideo from './assets/videos/Winterwarrior.mp4'
import SnowshieldVideo from './assets/videos/Snowshield.mp4'
import MacaronVideo from './assets/videos/Macaron.mp4'
import CombatVideo from './assets/videos/Combat.mp4'
import { Link, useNavigate } from "react-router-dom"
import { SwiperContext } from "../homepage/swiper/Swiper"
import Steps from "../homepage/Steps"
import { LanguageContext } from "../../context/useLanguageContext"
import { HomepageContext } from "../homepage/Homepage"

import maidian, { MaidianEventType } from "../../api/maidian"

const cardList = [
    {img: CardImg2, text: "Winterwarrior", video: WinterwarriorVideo},
    {img: CardImg1, text: "Blossom", video: BlossomVideo},
    {img: CardImg3, text: "Snowshield", video: SnowshieldVideo},
    {img: CardImg4, text: "Macaron", video: MacaronVideo},
    {img: CardImg5, text: "Combat", video: CombatVideo},
]

export default () => {

    const {
        maidianEventSend,
      } = maidian
    
    const navigate = useNavigate()
    const {step: current} = useContext(HomepageContext)
    const [autoplayIdx, setAutoplayIdx] = useState<number>(-1)
    const p7Ref = useRef<HTMLDivElement>(null)

    const {translate: t} = useContext(LanguageContext)

    const triggerAutoplay = () => {
        const nextIdx = (autoplayIdx + 1) % cardList.length
        setAutoplayIdx(nextIdx)
        console.log(`P7 autoplayIdx: ${autoplayIdx}, nextIdx: ${nextIdx} `)
        const video = p7Ref.current?.querySelectorAll("video")[nextIdx] as HTMLVideoElement
        video?.play()
    }

    const closeAutoplay = () => {
        setAutoplayIdx(-1)
    }

    useEffect(() => {

        if(current == Steps.P7){
            triggerAutoplay()
        }else{
            closeAutoplay()
        }

    },  [current])


    const mouseEnterHandle = (e: any, idx: number) => {
        // const video = (e.currentTarget as HTMLDivElement).querySelector("video") as HTMLVideoElement
        // video?.play()
        // setAutoplayIdx(idx)
    }

    const mouseLeaveHandle = (e: any) => {
    }

    const stopHandle = (idx: number) => {
        if(current !== Steps.P7) return
        if(autoplayIdx != idx) return
        triggerAutoplay()
    }
    //window.open("/p7/detail", "_blank")

    return (
        <div ref={p7Ref} className={styles['p7']}>
            <div className={styles['p7__content']}>
                <div className={styles['p7__content-desc']}>
                <label className={styles['p7__content-desc-title']}>{t('Style Collection')}</label>
                    <div className={styles['p7__content-desc-sub-title']} onClick={() => {navigate("/p7/detail"), maidianEventSend(MaidianEventType.ENTER_SUB_PAGE, 7)}}>
                        {t('Discover All fashion')}
                        <div className={styles['p7__content-desc-sub-title__divider']}></div>
                    </div>
                </div>
                <div className={styles['p7__content-card']}>
                    {
                        cardList.map((card, idx) => {
                            return (
                                <div className={styles['p7__content-card-item']} key={idx} onMouseEnter={(e) => mouseEnterHandle(e, idx)} onMouseLeave={mouseLeaveHandle}>
                                    <video src={card.video} className={styles['p7__content-card-item-img']} muted onEnded={() => stopHandle(idx)} ></video>
                                    <label className={styles['p7__content-card-item-desc']}>{t(card.text)}</label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}


