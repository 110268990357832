import { useContext, useEffect } from "react"
import styles from "./P5.module.scss"

import p5Video from "./assets/videos/p5.mp4"
import { HomepageContext } from "../homepage/Homepage"
import { LanguageContext } from "../../context/useLanguageContext"

export default () => {

    const {step, showHeader} = useContext(HomepageContext)
    const {translate: t} = useContext(LanguageContext)

    useEffect(() => {

        // const video: any = document.querySelector("#p5Video")
        // if(step == 4){
        //     video.play()
        // }else{
        //     video.pause();
        // }
    }, [step])

    return (
        <div className={styles['p5']}>
            <div className={styles['p5__content-wrapper']}>
                <div className={styles['p5__content']}>
                {t('A Canvas of Stories, Painted in Fur')}
                </div>
            </div>
        </div>
    )
}



