import { useContext } from "react"
import styles from "./Index.module.scss"
import { LanguageContext } from "../../../context/useLanguageContext"

export default (props: {id: string}) => {
    const {translate: t} = useContext(LanguageContext)
    let textMap:any = {
        '1': 'Brave Sprit',
        '2': 'Elegant Nimble',
        '3': 'Temperamental Chic',
        '4': 'Fun and Lively detail',
    }
    return (
        <div className={styles[`p2-d1-${props.id}`]}>
            <div className={styles[`p2-d1-${props.id}-text`]}>
             {t(textMap[props.id])}
            </div>
        </div>
    )
}


