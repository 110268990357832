import styles from "./P4.module.scss"
import { useNavigate } from "react-router-dom"

import SunglassesCover from './assets/images/Sunglasses_cover.png'
import SunglassesVideo from '../../pages/p4/assets/videos/Sunglasses.mp4'
import HatCover from './assets/images/Hat_cover.png'
import HatVideo from '../../pages/p4/assets/videos/Hat.mp4'
import EarringCover from './assets/images/Earring_cover.png'
import EarringVideo from '../../pages/p4/assets/videos/Earring.mp4'
import NecklaceCover from './assets/images/Necklace_cover.png'
import NecklaceVideo from '../../pages/p4/assets/videos/Necklace.mp4'
import { useContext, useEffect, useState } from "react"
import { LanguageContext } from "../../context/useLanguageContext"

import Maidian, { MaidianEventType } from "../../api/maidian"
import { HomepageContext } from "../homepage/Homepage"

export default () => {
    const {
        maidianEventSend,
      } = Maidian
    let navigate = useNavigate();
    const {translate: t} = useContext(LanguageContext)
    
    return (
        <div data-step="4" className={styles['p4']}>
            <div className={styles['p4-top']}>
                <span className={styles['p4-top-title']}>{t('Meta Essentials')}</span>
                <div className={styles['p4-top-desc']}>
                    <div className={styles['p4-top-desc-wrapper']} onClick={() => {navigate("/p4/detail"), maidianEventSend(MaidianEventType.ENTER_SUB_PAGE, 4)}}>
                        <span className={styles['p4-top-desc-wrapper-text']}>{t('Discover All fashion')}</span>
                        <span className={styles['p4-top-desc-wrapper-underline']}></span>
                    </div>
                </div>
            </div>
            <div className={styles['p4-content']}>
                <ItemList/>
            </div>
        </div>
    )
}

export const ItemList = () => {
    const {step} = useContext(HomepageContext)
    const {translate: t} = useContext(LanguageContext)
    useEffect(() => {
        for (let i=0; i<4; i++) {
            let video = document.getElementById("video_"+i) as any
            video?.load()
        }
    }, [step])
    useEffect(() => {
        if (step === 3) {
            nextPlay(0)
        } else {
            for (let i=0; i<4; i++) {
                let video = document.getElementById("video_"+i) as HTMLVideoElement
                if (!video.paused) {
                    video.pause()
                }
            }
        }
    }, [step])
    const nextPlay = (videoIdx: number) => {
        if (videoIdx > 3) {
            videoIdx = 0
        }
        //console.log('播放,idx:' + videoIdx)
        const video = document.getElementById("video_"+videoIdx) as any
        video?.play()
        .catch(() => {
            console.error('p4播放失败')
        });
    }
    let typeList = [
        {cover: SunglassesCover, video: SunglassesVideo, text: t("Sunglasses")},
        {cover: HatCover, video: HatVideo, text: t("Hat")},
        {cover: EarringCover, video: EarringVideo, text: t("Earrings")},
        {cover: NecklaceCover, video: NecklaceVideo, text: t("Necklace")},
    ]
    const itemList = []
    for (let i = 0; i < typeList.length; i ++) {
        itemList.push(
            <div className={styles['p4-content-item']} key={i}>
                {/* <img src={typeList[i].img} className={styles['p4-content-item-img']}/> */}
                <video id={"video_"+i} 
                className={styles['p4-content-item-video']} 
                // poster={typeList[i].cover}
                preload="auto"
                controlsList="nodownload noremoteplayback"
                muted
                playsInline
                onContextMenu={(e) => {e.preventDefault()}}
                onDoubleClick={(e) => {e.preventDefault()}}
                onClick={(e) => {e.preventDefault()}}
                onEnded={() => {nextPlay(i+1)}}
                >
                    <source src={typeList[i].video} type="video/mp4"></source>
                    </video>
                    <img src={typeList[i].cover} className={styles['p4-content-item-img']}/>
                <span className={styles['p4-content-item-text']}>{typeList[i].text}</span>
            </div>
        )
    }
    return (
        <>{itemList}</>
    )
}


