import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import AppPC from './AppPC';
import AppMobile from './AppMobile';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Env from './Env';
import axios from 'axios';


const gateway = process.env.REACT_APP_GATEWAY_PREFIX
if(Env.DEBUG){
  console.log("======env======")
  console.log("gateway: ", gateway)
  console.log("======env======")
}
axios.defaults.baseURL = gateway
axios.defaults.withCredentials = true;

//utmSource
const queryParams = new URLSearchParams(window.location.search);
const utmSource = queryParams.get('utm_source');
localStorage.setItem('utm_source', utmSource??'')

const height =window.outerHeight
const width = window.outerWidth
console.log(`height: ${height}, width: ${width}`)
const from = height <= width ? 'PC' : 'H5'
localStorage.setItem('from', from)
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
  {
    height <= width
    ?
    <AppPC/>
    :
    <AppMobile/>
  }
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
