
export default [
	["阿尔巴尼亚", "355"],
	["阿尔及利亚", "213"],
	["阿富汗", "93"],
	["阿根廷", "54"],
	["爱尔兰", "353"],
	["埃及", "20"],
	["埃塞俄比亚", "251"],
	["爱沙尼亚", "372"],
	["阿联酋", "971"],
	["阿鲁巴", "297"],
	["阿曼", "968"],
	["安道尔", "376"],
	["安哥拉", "244"],
	["安圭拉", "1-264"],
	["安提瓜和巴布达", "1-268"],
	["澳大利亚", "61"],
	["奥地利", "43"],
	["奥兰群岛", "358"],
	["澳门", "853"],
	["阿塞拜疆", "994"],
	["巴巴多斯", "1-246"],
	["巴布亚新几内亚", "675"],
	["巴哈马", "1-242"],
	["白俄罗斯", "375"],
	["百慕大", "1-441"],
	["巴基斯坦", "92"],
	["巴拉圭", "595"],
	["巴勒斯坦", "970"],
	["巴林", "973"],
	["巴拿马", "507"],
	["保加利亚", "359"],
	["巴西", "55"],
	["北马里亚纳群岛", "1-670"],
	["北马其顿", "389"],
	["贝宁", "229"],
	["比利时", "32"],
	["冰岛", "354"],
	["博茨瓦纳", "267"],
	["波多黎各", "1"],
	["波黑", "387"],
	["波兰", "48"],
	["玻利维亚", "591"],
	["伯利兹", "501"],
	["不丹", "975"],
	["布基纳法索", "226"],
	["布隆迪", "257"],
	["布韦岛", "47"],
	["朝鲜", "850"],
	["赤道几内亚", "240"],
	["丹麦", "45"],
	["德国", "49"],
	["东帝汶", "670"],
	["多哥", "228"],
	["多米尼加", "1-809"],
	["多米尼克", "1-767"],
	["厄瓜多尔", "593"],
	["厄立特里亚", "291"],
	["俄罗斯", "7"],
	["法国", "33"],
	["法罗群岛", "298"],
	["梵蒂冈", "39-06"],
	["法属波利尼西亚", "689"],
	["法属圭亚那", "594"],
	["法属南部和南极领地", "262"],
	["法属圣马丁", "590"],
	["斐济", "679"],
	["菲律宾", "63"],
	["芬兰", "358"],
	["佛得角", "238"],
	["福克兰群岛", "500"],
	["冈比亚", "220"],
	["刚果共和国", "242"],
	["刚果民主共和国", "243"],
	["格陵兰", "299"],
	["格林纳达", "1-473"],
	["格鲁吉亚", "995"],
	["哥伦比亚", "57"],
	["根西", "44"],
	["哥斯达黎加", "506"],
	["瓜德罗普", "590"],
	["关岛", "1-671"],
	["古巴", "53"],
	["圭亚那", "592"],
	["海地", "509"],
	["韩国", "82"],
	["哈萨克斯坦", "7"],
	["赫德岛和麦克唐纳群岛", "672"],
	["黑山", "382"],
	["荷兰", "31"],
	["荷属加勒比地区", "599"],
	["荷属圣马丁", "1-721"],
	["洪都拉斯", "504"],
	["加纳", "233"],
	["加拿大", "1"],
	["柬埔寨", "855"],
	["加蓬", "241"],
	["吉布提", "253"],
	["捷克", "420"],
	["吉尔吉斯斯坦", "996"],
	["基里巴斯", "686"],
	["津巴布韦", "263"],
	["几内亚", "224"],
	["几内亚比绍", "245"],
	["开曼群岛", "1-345"],
	["喀麦隆", "237"],
	["卡塔尔", "974"],
	["科科斯（基林）群岛", "61"],
	["克罗地亚", "385"],
	["科摩罗", "269"],
	["肯尼亚", "254"],
	["科特迪瓦", "225"],
	["科威特", "965"],
	["库克群岛", "682"],
	["库拉索", "599"],
	["莱索托", "266"],
	["老挝", "856"],
	["拉脱维亚", "371"],
	["黎巴嫩", "961"],
	["利比里亚", "231"],
	["利比亚", "218"],
	["列支敦士登", "423"],
	["立陶宛", "370"],
	["留尼汪", "262"],
	["罗马尼亚", "40"],
	["卢森堡", "352"],
	["卢旺达", "250"],
	["马达加斯加", "261"],
	["马恩岛", "44"],
	["马尔代夫", "960"],
	["马耳他", "356"],
	["马来西亚", "60"],
	["马拉维", "265"],
	["马里", "223"],
	["毛里求斯", "230"],
	["毛里塔尼亚", "222"],
	["马绍尔群岛", "692"],
	["马提尼克", "596"],
	["马约特", "262"],
	["美国", "1"],
	["美国本土外小岛屿", ""],
	["美属萨摩亚", "1-684"],
	["美属维尔京群岛", "1-340"],
	["蒙古国", "976"],
	["孟加拉国", "880"],
	["蒙特塞拉特", "1-664"],
	["缅甸", "95"],
	["密克罗尼西亚联邦", "691"],
	["秘鲁", "51"],
	["摩尔多瓦", "373"],
	["摩洛哥", "212"],
	["摩纳哥", "377"],
	["莫桑比克", "258"],
	["墨西哥", "52"],
	["纳米比亚", "264"],
	["南非", "27"],
	["南极洲", "672"],
	["南乔治亚和南桑威奇群岛", "500"],
	["南苏丹", "211"],
	["瑙鲁", "674"],
	["尼泊尔", "977"],
	["尼加拉瓜", "505"],
	["尼日尔", "227"],
	["尼日利亚", "234"],
	["纽埃", "683"],
	["诺福克岛", "672"],
	["挪威", "47"],
	["帕劳", "680"],
	["皮特凯恩群岛", "870"],
	["葡萄牙", "351"],
	["日本", "81"],
	["瑞典", "46"],
	["瑞士", "41"],
	["萨尔瓦多", "503"],
	["塞尔维亚", "381p"],
	["塞拉利昂", "232"],
	["塞内加尔", "221"],
	["塞浦路斯", "357"],
	["塞舌尔", "248"],
	["萨摩亚", "685"],
	["沙特阿拉伯", "966"],
	["圣巴泰勒米", "590"],
	["圣诞岛", "61"],
	["圣多美和普林西比", "239"],
	["圣赫勒拿、阿森松和特里斯坦-达库尼亚", "290n"],
	["圣基茨和尼维斯", "1-869"],
	["圣卢西亚", "1-758"],
	["圣马力诺", "378"],
	["圣皮埃尔和密克隆", "508"],
	["圣文森特和格林纳丁斯", "1-784"],
	["斯里兰卡", "94"],
	["斯洛伐克", "421"],
	["斯洛文尼亚", "386"],
	["斯瓦尔巴群岛和扬马延岛", "47"],
	["斯威士兰", "268"],
	["苏丹", "249"],
	["苏里南", "597"],
	["所罗门群岛", "677"],
	["索马里", "252"],
	["泰国", "66"],
	["台湾", "886"],
	["塔吉克斯坦", "992"],
	["汤加", "676"],
	["坦桑尼亚", "255"],
	["特克斯和凯科斯群岛", "1-649"],
	["特立尼达和多巴哥", "1-868"],
	["土耳其", "90"],
	["土库曼斯坦", "993"],
	["突尼斯", "216"],
	["托克劳", "690"],
	["图瓦卢", "688"],
	["瓦利斯和富图纳", "681"],
	["瓦努阿图", "678"],
	["危地马拉", "502"],
	["委内瑞拉", "58"],
	["文莱", "673"],
	["乌干达", "256"],
	["乌克兰", "380"],
	["乌拉圭", "598"],
	["乌兹别克斯坦", "998"],
	["香港", "852"],
	["西班牙", "34"],
	["希腊", "30"],
	["新加坡", "65"],
	["新喀里多尼亚", "687"],
	["新西兰", "64"],
	["匈牙利", "36"],
	["西撒哈拉", "212"],
	["叙利亚", "963"],
	["牙买加", "1-876"],
	["亚美尼亚", "374"],
	["也门", "967"],
	["意大利", "39"],
	["伊拉克", "964"],
	["伊朗", "98"],
	["印度", "91"],
	["印度尼西亚", "62"],
	["英国", "44"],
	["英属维尔京群岛", "1-284"],
	["英属印度洋领地", "246"],
	["以色列", "972"],
	["约旦", "962"],
	["越南", "84"],
	["赞比亚", "260"],
	["泽西", "44"],
	["乍得", "235"],
	["直布罗陀", "350"],
	["智利", "56"],
	["中非", "236"],
	["中华人民共和国", "86"],

]
