
export default {
	"CATLY | Official Website": "CATLY | Site officiel",
	"description": "Des chats de compagnie AAA jamais vus auparavant. Un voyage hyperréaliste plein d'aventures, d'artisanat, de mode, d'amusement, de neige, de vitesse, de robots... Tout cela grâce à des chats.",
	"Email Address / Phone Number": "Adresse e-mail / Numéro de téléphone",
	"Register Now": "Inscrivez-vous dès maintenant",
	"English": "English",
	"简体中文": "简体中文",
	"繁體中文": "繁體中文",
	"日本語": "日本語",
	"한국어": "한국어",
	"Русский": "Русский",
	"Español": "Español",
	"Português": "Português",
	"Français": "Français",
	"Deutsch": "Deutsch",
	"Italiano": "Italiano",
	"Registered Successfully": "Inscription ronrondement menée !",
	"Confident": "Puissance",
	"Strides": "Vitesse",
	"Brave": "Élégance",
	"and Boundless": "Grâce",
	"Elegant": "Amusement",
	"Nimble": "Plaisir",
	"Temperamental": "Caractériel",
	"by Nature": "Chic",
	"Fun and Lively": "Petite peluche",
	"Confident Strides": "Puissance et vitesse",
	"Brave Sprit": "Élégance et grâce",
	"Elegant Nimble": "Plaisir de l'amusement",
	"Temperamental Chic": "Caractériel et chic",
	"Fun and Lively detail": "Petite peluche",
	"In Their Eyes, Worlds Unfold": "Le monde se dévoile aux yeux des chats",
	"Meta Essentials": "Chic",
	"Discover All Accessories": "Voir tout",
	"Sunglasses": "Lunettes de soleil",
	"Hat": "Chapeau",
	"Earrings": "Boucles d'oreille",
	"Necklace": "Collier",
	"Bag": "Sac",
	"Products Accessories": "Produits",
	"All": "Tous",
	"Sunglasses detail": "Lunettes de soleil",
	"Hat detail": "Chapeau",
	"Necklace detail": "Boucles d'oreille",
	"Earrings detail": "Collier",
	"Bag detail": "Sac",
	"Colored Sunglasses": "Lunettes de soleil colorées",
	"Big-ear Hat": "Chapeau à grandes oreilles",
	"Gold Sunglasses": "Lunettes de soleil dorées",
	"Gold Shell Earrings": "Boucles d'oreille coquillage doré",
	"Macaron Bag": "Sac macaron",
	"Purple Shell Earphone": "Boucles d'oreille coquillage violet",
	"Pink Lady Necklace": "Collier lady rose",
	"A Canvas of Stories, Painted in Fur": "Une toile d'histoires peintes avec de la fourrure",
	"A world of whispers, wonder, and infinite grace.": "Un monde de chats plein de murmures, d'émerveillement et de grâce infinie.",
	"Style Collection": "Collection de styles",
	"Discover All fashion": "Voir tout",
	"Blossom": "Floraison",
	"Winterwarrior": "Guerrier hivernal",
	"Snowshield": "Pare-neige",
	"Macaron": "Macaron",
	"Combat": "Combat",
	"Products": "Produits",
	"Season 1": "Saison 1",
	"Blossom Coat": "Manteau floraison",
	"Winterwarrior Suit": "Combinaison guerrier hivernal",
	"Snowshield Suit": "Combinaison pare-neige",
	"Blossom Tops": "Haut floraison",
	"Macaron Coat": "Manteau macaron",
	"Combat Coat": "Manteau de combat",
	"Adventure Sports Suit": "Combinaison de sports d'aventure",
	"Futuristic Jacket": "Veste futuriste",
	"Born of Moonlight, Bound by Mystery.": "Né au clair de lune, lié par le mystère.",
	"Music by Akira Senju": "Musiques par Akira Senju",
	"Privacy Policy": "Politique de confidentialité",
	"NEWSLETTER": "NEWSLETTER",
	"Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.": "Inscrivez-vous aux e-mails de Catly pour recevoir les dernières actualités, dont des événements de prélancement en ligne exclusifs et les nouvelles collections.",
	"FOLLOW US": "NOUS SUIVRE",
	"Email address": "Adresse e-mail",
	"OK": "OK",
	"Followed Successfully": "Suivi avec succès",
	"Power click": "Puissance",
	"Elegance click": "Élégance",
	"Toy click": "Amusement",
	"Temperamental click": "Caractériel",
	"Petite click": "Petite",
	"null": "Le numéro de téléphone ou l'adresse e-mail ne peuvent pas être vides.",
	"The Game Awards 2024": "The Game Awards 2024",
	"Showcase Game": "Jeu en présentation",

}
