import cnZh from "./cn-zh"
import zh from "./zh"
import en from "./en"
import ja from "./ja"
import ko from "./ko"
import ru from "./ru"
import es from "./es"
import pt from "./pt"
import fr from "./fr"
import de from "./de"
import it from "./it"


const dists: Record<string, any[]> = {
    "zh-CN": cnZh,
    "zh": zh,
    "en": en,
    "ja": ja,
    "ko": ko,
    "ru": ru,
    "es": es,
    "pt": pt,
    "fr": fr,
    "de": de,
    "it": it,
}

const getDict = (language: string) => {
    const dict = dists[language] as any[]
    return dict
}


export default {
    getDict
}