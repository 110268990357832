
import styles from "./Header.module.scss";

import logoSvg from "../../assets/images/logo.svg"
import logoPng from "./assets/images/logo.png"
import languageSvg from "./assets/images/language.png"
import languageActivateSvg from "./assets/images/language_hover.png"
import soundOnSvg from "./assets/images/soundon.png"
import soundOffSvg from "./assets/images/soundoff_d.png"
import { CSSProperties, PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { HomepageContext } from "../../pages/homepage/Homepage";
import backPng from "./assets/images/back.png"
import { useNavigate } from "react-router-dom";
import languageOptions from "../../api/languageOptions";
import { LanguageContext } from "../../context/useLanguageContext";
import { BgmContext } from "../../context/useBgmContext";

export default (props: {white?: boolean, className?: String, goback?: boolean, bottom?: boolean}) => {

    const {step} = useContext(HomepageContext)
    const {
        white,
        className: exClassName,
        goback,
        bottom
    } = props
    let navigate = useNavigate();


    return (
        <div className={[styles['header-wrapper']].join(" ")}>
            <div className={[styles['header'], (white || step>0)?styles['header_white']:'', step > 0?styles['header_hidden']:'', bottom?styles['header_bottom']:''].join(" ")}>
                {
                    goback
                    ?
                    // <div className={[styles['header__goback']].join(" ")}>
                    //     <div className={styles['header__goback__img-wrapper']}>
                    //             <img src={backPng} className={[styles['header__goback__img']].join(" ")} onClick={() => navigate(-1)}></img>
                    //         </div>
                    // </div>
                    <></>
                    :
                    <></>
                }
                <img className={styles['header__logo']} src={logoSvg} onClick={() => {navigate("/")}}/>
                <div className={styles['header__btns-wrapper']}>
                    <div className={styles['header__btns']}>
                        <LanguageBtn/>
                        <SoundBtn />
                    </div>                
                </div>
            </div>  
        </div>
    )
}

const LanguageBtn = () => {

    const {step} = useContext(HomepageContext)
    const [activate, setActivate] = useState<boolean>(false)

    const getStyle = ():CSSProperties => {
        return {
            cursor: step == 0? "pointer": "unset"
        }
    }
    
    return (
        <LanguageSelection onActivate={(activate) => {
            if(step == 0){
                setActivate(activate)
            }
            
        }}>
            <div className={styles['header__btns_language-wrapper']} style={getStyle()}>
                <img src={activate?languageActivateSvg:languageSvg} className={styles['header__btns_language']}></img>
            </div>
        </LanguageSelection>
    )
}

const SoundBtn = () => {
    const {playing, play, pause} = useContext(BgmContext)
    const {step} = useContext(HomepageContext)

    const getStyle = ():CSSProperties => {
        return {
            cursor: step == 0? "pointer": "unset"
        }
    }

    const changeActivate = () => {
        if(step == 0){
            playing?pause():play()
        }
    }

    return (
        <img src={playing?soundOnSvg:soundOffSvg} className={styles['header__btns_sound']} style={getStyle()} onClick={changeActivate}></img>
    )
}

const LanguageSelection = (props: PropsWithChildren<{onActivate: (activate: boolean) => void}>) => {

    const {translate: t, language, setLanguage} = useContext(LanguageContext)
    const [activate, setActivate] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement>(null)
    const [positionStyle, setPositionStyle] = useState<CSSProperties>({})

    const clickHandle = () => {
        const _activate = !activate
        console.log("clickHandle change language")
        setActivate(_activate)
        props.onActivate(_activate)
    }

    useEffect(() => {
        const rect = ref.current?.getBoundingClientRect()
        rect && setPositionStyle({
            right: -(window.innerWidth - rect.right),
            top: rect.bottom
        })
    }, [])

    return (
        <div className={styles["language-selection"]} onClick={clickHandle}  ref={ref}>
            <div className={styles['language-selection__parent']} >{props.children}</div>
            <div 
                className={[styles["language-selection__ul-wrapper"], !activate?styles["language-selection__ul-wrapper_hidden"]:''].join(" ")}
                style={positionStyle}
            >
                <div className={styles["language-selection__ul"]}>
                    {
                        languageOptions.map((lan, idx) => {
                            return (
                                <div key={idx}
                                onClick={() => setLanguage(lan.value)}
                                className={[styles["language-selection__li"], language==lan.value?styles["language-selection__li_select"]:""].join(" ")}>{t(lan.label)}</div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
    
}
