
export default {
	"CATLY | Official Website": "CATLY | Official Website",
	"description": "Redefining companionship with AAA CATs. A hyper-realistic journey of adventure, craft, fashion, fun, snow, speed, robots — all with and via CATs.",
	"Email Address / Phone Number": "Email Address / Phone Number",
	"Register Now": "Register Now",
	"English": "English",
	"简体中文": "简体中文",
	"繁體中文": "繁體中文",
	"日本語": "日本語",
	"한국어": "한국어",
	"Русский": "Русский",
	"Español": "Español",
	"Português": "Português",
	"Français": "Français",
	"Deutsch": "Deutsch",
	"Italiano": "Italiano",
	"Registered Successfully": "Purrfectly Registered!",
	"Confident": "Power",
	"Strides": "Strides",
	"Brave": "Elegance",
	"and Boundless": "Grace",
	"Elegant": "Toy",
	"Nimble": "Delight",
	"Temperamental": "Temperamental",
	"by Nature": "Chic",
	"Fun and Lively": "Petite Plush",
	"Confident Strides": "Power Strides",
	"Brave Sprit": "Elegance Grace",
	"Elegant Nimble": "Toy Delight",
	"Temperamental Chic": "Temperamental Chic",
	"Fun and Lively detail": "Petite Plush",
	"In Their Eyes, Worlds Unfold": "In Cat's Eyes, Worlds Unfold",
	"Meta Essentials": "Chic",
	"Discover All Accessories": "Discover All",
	"Sunglasses": "Sunglasses",
	"Hat": "Hat",
	"Earrings": "Earrings",
	"Necklace": "Necklace",
	"Bag": "Bag",
	"Products Accessories": "Products",
	"All": "All",
	"Sunglasses detail": "Sunglasses",
	"Hat detail": "Hat",
	"Necklace detail": "Necklace",
	"Earrings detail": "Earrings",
	"Bag detail": "Bag",
	"Colored Sunglasses": "Colored Sunglasses",
	"Big-ear Hat": "Big-ear Hat",
	"Gold Sunglasses": "Gold Sunglasses",
	"Gold Shell Earrings": "Gold Shell Earrings",
	"Macaron Bag": "Macaron Bag",
	"Purple Shell Earphone": "Purple Shell Earphone",
	"Pink Lady Necklace": "Pink Lady Necklace",
	"A Canvas of Stories, Painted in Fur": "A Canvas of Stories, Painted in Fur",
	"A world of whispers, wonder, and infinite grace.": "A cat world of whispers, wonder, and infinite grace.",
	"Style Collection": "Style Collection",
	"Discover All fashion": "Discover All",
	"Blossom": "Blossom",
	"Winterwarrior": "Winterwarrior",
	"Snowshield": "Snowshield",
	"Macaron": "Macaron",
	"Combat": "Combat",
	"Products": "Products",
	"Season 1": "Fall-Winter Collection",
	"Blossom Coat": "Blossom Coat",
	"Winterwarrior Suit": "Winterwarrior Suit",
	"Snowshield Suit": "Snowshield Suit",
	"Blossom Tops": "Blossom Tops",
	"Macaron Coat": "Macaron Coat",
	"Combat Coat": "Combat Coat",
	"Adventure Sports Suit": "Adventure Sports Suit",
	"Futuristic Jacket": "Futuristic Jacket",
	"Born of Moonlight, Bound by Mystery.": "Born of Moonlight, Bound by Mystery.",
	"Music by Akira Senju": "Music by Akira Senju",
	"Privacy Policy": "Privacy Policy",
	"NEWSLETTER": "NEWSLETTER",
	"Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.": "Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.",
	"FOLLOW US": "FOLLOW US",
	"Email address": "Email address",
	"OK": "OK",
	"Followed Successfully": "Followed Successfully",
	"Power click": "Power",
	"Elegance click": "Elegance",
	"Toy click": "Toy",
	"Temperamental click": "Temperamental",
	"Petite click": "Petite",
	"null": "Phone number or email cannot be empty.",
	"The Game Awards 2024": "The Game Awards 2024",
	"Showcase Game": "Showcase Game",

}
