import styles from "./Trailer.module.scss"

import { CSSProperties, useContext, useEffect, useRef, useState } from "react"

import TrailerVideo from './assets/videos/trailer.mp4'
import TrailerCover from './assets/images/trailer_cover.png'
import { HomepageContext } from "../homepage/Homepage"
import { LanguageContext } from "../../context/useLanguageContext"

export default () => {
    const {step} = useContext(HomepageContext)
    const {translate: t} = useContext(LanguageContext)

    useEffect(() => {
        let video = document.getElementById("trailer_video") as HTMLVideoElement
        if (step === 1) {
            // if (!video.paused) {
                video.play()
            // }
        } else {
            if (!video.paused) {
                video.pause()
            }
        }

    }, [step])

    return (
        <div className={styles['trailer']}>
            <video
                controls
                controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
                id="trailer_video"
                className={styles['trailer__video']}
                loop
                muted
                playsInline
                disablePictureInPicture
                src={TrailerVideo}
                poster={TrailerCover}
            ></video>
            <div className={styles['trailer__text-wrapper']}>
                <div className={styles['trailer__text']}>
                    <span className={styles['trailer__text__title']}>{t('The Game Awards 2024')}</span>
                    <span className={styles['trailer__text__desc']}>{t('Showcase Game')}</span>
                </div>
            </div>
        </div>
    )
}


