import styles from "./Trailer.module.scss"

import { CSSProperties, useContext, useEffect, useRef, useState } from "react"

import TrailerVideo from './assets/videos/trailer.mp4'
import TrailerCover from './assets/images/trailer_cover.jpg'
import { HomepageContext } from "../homepage/Homepage"
import { LanguageContext } from "../../context/useLanguageContext"

export default () => {
    const {step} = useContext(HomepageContext)
    const {translate: t} = useContext(LanguageContext)
    const [textShow, setTextShow] = useState<boolean>(true)
    const videoRef = useRef(null)
    useEffect(() => {
        let video = document.getElementById("trailer_video") as HTMLVideoElement
        if (step === 1) {
            console.log('预告开始播放')
            // if (!video.paused) {
                video?.play()
                .catch(function(err) {
                    //alert('p2播放出错' + err)
                })
            // }
            //setIsAutoPlay(true)
        } else {
            if (!video.paused) {
                console.log('预告停止播放')
                video.pause()
            }
        }

        var progress = document.getElementById("progress");
        video.addEventListener('timeupdate', function() {
            setTextShow(video.currentTime < 48)

            // var value = (100 / video.duration) * video.currentTime;
            // requestAnimationFrame(() => {
            //         if (progress) {
            //         progress.style.width = value + "%";
            //         }
            //     })
                // progress.innerHTML = Math.round(video.currentTime) + "% played";
        });

    }, [step])

    const getStyle = ():CSSProperties => {
        if (textShow) {
            return {
                opacity: 1,
            }
        }
        return {
            opacity: 0,
            transition: `opacity .8s ease-out`
        }
    }

    return (
        <div data-step="2" className={styles['trailer']}>
            <video
                // aria-controls=""
                controls
                controlsList="nodownload nofullscreen noremoteplayback"
                id="trailer_video"
                className={styles['trailer__video']}
                loop
                muted
                preload="auto"
                playsInline
                webkit-playsinline
                x5-video-player-type="h5" // 启用H5播放器,是微信安卓版特性
                x5-playsinline="true"     // 安卓微信浏览器支持小窗内播放
                x-webkit-airplay="allow" 
                disablePictureInPicture
                poster={TrailerCover}
                src={TrailerVideo}
                >
            </video>
            {/* <div className={styles['trailer__background']}></div> */}
            {/* <div className={styles['trailer__progressBar']} id="progressBar">
                <div className={styles['trailer__progressBar__progress']} id="progress"></div>
            </div> */}
            <div className={styles['trailer__text-wrapper']} style={getStyle()}>
                <span className={styles['trailer__text__title']}>{t('The Game Awards 2024')}</span>
                <span className={styles['trailer__text__desc']}>{t('Showcase Game')}</span>
            </div>
        </div>
    )
}


