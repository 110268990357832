
export default {
	"CATLY | Official Website": "CATLY | Site Oficial",
	"description": "Redefinindo o companheirismo com GATOs AAA. Uma jornada hiper-realista de aventura, arte, moda, diversão, neve, velocidade, robôs - tudo com tecnologia GATOs.",
	"Email Address / Phone Number": "Endereço de e-mail/Número de telefone",
	"Register Now": "Registre já",
	"English": "English",
	"简体中文": "简体中文",
	"繁體中文": "繁體中文",
	"日本語": "日本語",
	"한국어": "한국어",
	"Русский": "Русский",
	"Español": "Español",
	"Português": "Português",
	"Français": "Français",
	"Deutsch": "Deutsch",
	"Italiano": "Italiano",
	"Registered Successfully": "Registrou miauravilhosamente!",
	"Confident": "Poder",
	"Strides": "Passadas",
	"Brave": "Elegância",
	"and Boundless": "Graça",
	"Elegant": "Brinquedo",
	"Nimble": "Deleite",
	"Temperamental": "Temperamental",
	"by Nature": "Chique",
	"Fun and Lively": "Pelúcia Pequena",
	"Confident Strides": "Passadas Poderosas",
	"Brave Sprit": "Graça Elegante",
	"Elegant Nimble": "Deleite de Brinquedo",
	"Temperamental Chic": "Chique Temperamental",
	"Fun and Lively detail": "Pelúcia Pequena",
	"In Their Eyes, Worlds Unfold": "Aos olhos dos gatos, mundos se abrem",
	"Meta Essentials": "Chique",
	"Discover All Accessories": "Descobrir Tudo",
	"Sunglasses": "Óculos de Sol",
	"Hat": "Chapéu",
	"Earrings": "Brincos",
	"Necklace": "Colar",
	"Bag": "Bolsa",
	"Products Accessories": "Produtos",
	"All": "Tudo",
	"Sunglasses detail": "Óculos de Sol",
	"Hat detail": "Chapéu",
	"Necklace detail": "Brincos",
	"Earrings detail": "Colar",
	"Bag detail": "Bolsa",
	"Colored Sunglasses": "Óculos de Sol Coloridos",
	"Big-ear Hat": "Chapéu Orelhudo",
	"Gold Sunglasses": "Óculos de Sol Dourados",
	"Gold Shell Earrings": "Brincos de Concha Dourada",
	"Macaron Bag": "Bolsa de Macaron",
	"Purple Shell Earphone": "Fone de Concha Roxa",
	"Pink Lady Necklace": "Colar de Dama Rosa",
	"A Canvas of Stories, Painted in Fur": "Uma tela cheia de histórias, pintadas com pelo",
	"A world of whispers, wonder, and infinite grace.": "Um mundo de gato cheio de sussurros, maravilhas e graça infinita.",
	"Style Collection": "Coleção de Estilos",
	"Discover All fashion": "Descobrir Tudo",
	"Blossom": "Florescente",
	"Winterwarrior": "Guerreiro Invernal",
	"Snowshield": "Escudo Nevado",
	"Macaron": "Macaron",
	"Combat": "Combate",
	"Products": "Produtos",
	"Season 1": "1ª Temporada",
	"Blossom Coat": "Casaco Florescente",
	"Winterwarrior Suit": "Traje de Guerreiro Invernal",
	"Snowshield Suit": "Traje de Escudo Nevado",
	"Blossom Tops": "Blusa Florescente",
	"Macaron Coat": "Casaco de Macaron",
	"Combat Coat": "Casaco de Combate",
	"Adventure Sports Suit": "Traje Esportivo Aventureiro",
	"Futuristic Jacket": "Jaqueta Futurística",
	"Born of Moonlight, Bound by Mystery.": "Nascido na luz da lua, envolto em mistério.",
	"Music by Akira Senju": "Música por Akira Senju",
	"Privacy Policy": "Política de Privacidade",
	"NEWSLETTER": "BOLETIM DE NOTÍCIAS",
	"Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.": "Inscreva-se nos e-mails de Catly e receba as últimas notícias, incluindo pré-lançamentos online exclusivos e novas coleções.",
	"FOLLOW US": "SIGA-NOS",
	"Email address": "Endereço de e-mail",
	"OK": "OK",
	"Followed Successfully": "Seguiu com sucesso",
	"Power click": "Poder",
	"Elegance click": "Elegância",
	"Toy click": "Brinquedo",
	"Temperamental click": "Temperamental",
	"Petite click": "Pelúcia",
	"null": "O número de telefone ou o e-mail não podem estar vazios.",
	"The Game Awards 2024": "Jogo Destaque no",
	"Showcase Game": "The Game Awards 2024",

}
