import { useContext } from "react"
import styles from "./P8.module.scss"

import BackgroundImage from './assets/images/p8-h.png'
import { LanguageContext } from "../../context/useLanguageContext"
export default () => {
    const {translate: t} = useContext(LanguageContext)
    return (
        <div data-step="8" className={styles['p8']}>
            <img src={BackgroundImage} className={styles['p8-img']}/>
            <div className={styles['p8-content']}>
                <div className={styles['p8-content-text']}>{t('Born of Moonlight, Bound by Mystery.')}</div>
            </div>
        </div>
    )
}


