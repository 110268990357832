import styles from "./Footer.module.scss"

import logoSvg from "./assets/images/logo.svg"
import steamSvg from "./assets/images/steam.svg"
import iwatchSvg from "./assets/images/iwatch.svg"
import switchSvg from "./assets/images/switch.svg"
import xSvg from "./assets/images/x.svg"
import underline from "./assets/images/underline.svg"
import { useNavigate } from "react-router-dom"
import { useContext, useState } from "react"
import { LanguageContext } from "../../context/useLanguageContext"

import Api, { RegisterRequest } from "../../api/api"
import Maidian, { MaidianEventType } from "../../api/maidian"

import {STEAM_URL, X_URL, IWATCH_URL} from "../../constants"

export default () => {

    const {
        maidianEventSend,
      } = Maidian

    const {translate: t} = useContext(LanguageContext)

    let navigate = useNavigate();

    const iconClick = (type: string, url: string) => {
        maidianEventSend(MaidianEventType.ICON_CLICK, type)
        window.open(url, "_href")
    }
    return (
        <div className={styles['footer']}>
            <div className={styles['footer-logo']}>
                <img src={logoSvg}/>
            </div>
            <div className={styles['footer-2']}>
                <span className={styles['footer-2-text']}>{t('Music by Akira Senju')}</span>
                <div className={styles['footer-2-icons']}>
                    <img className={styles['footer-2-icons-item']} src={steamSvg}
                        onClick={() => {iconClick('STEAM', STEAM_URL)}}
                    />
                    <img className={styles['footer-2-icons-item']} src={iwatchSvg}
                        onClick={() => {iconClick('IWATCH', IWATCH_URL)}}
                    />
                    <img className={styles['footer-2-icons-item']} src={switchSvg}/>
                </div>
            </div>
            <div className={styles['footer-desc']}>
                <span className={styles['footer-desc-title']}>{t('NEWSLETTER')}</span>
                <span className={styles['footer-desc-content']}>
                    {t('Sign up for Catly emails and receive the latest news, including exclusive online pre-launches and new collections.')}
                </span>
            </div>
            <div className={styles['footer-contact']}>
                <div className={styles['footer-contact-panel']}>
                    <span className={styles['footer-contact-panel-follow']}>{t('FOLLOW US')}</span>
                    <div className={styles['footer-contact-panel-items']}>
                        <img className={styles['footer-contact-panel-items-icon']} src={xSvg}/>
                        <Follow/>
                    </div>
                </div>
                <span className={styles['footer-contact-pp']} onClick={() => {navigate("/privacyPolicy"), maidianEventSend(MaidianEventType.ENTER_SUB_PAGE, 'privacyPolicy')}}>
                    {t('Privacy Policy')}
                </span>
            </div>
        </div>
    )
}

export const Follow = () => {
    const {
        follow,
    } = Api
    const {
        maidianFollow,
    } = Maidian
    const {translate: t} = useContext(LanguageContext)
    const [followed, setFollowed] = useState<boolean>(false)
    // const [followed, setFollowed] = useState<boolean>(true)
    const [email, setEmail] = useState<string>("")

    const doFollow = () => {
        if (!email) {
            //alert('邮箱不能为空')
            return
        }
        setFollowed(true)

        maidianFollow()

        follow({email: email})
        .then(res => {
            console.log('订阅成功')
        }).catch(err => {
            console.error('订阅失败')
        })
    }

    if (followed) {
        return (
            <div className={styles['followed']}>
                <span className={styles['followed__text']}>{t('Followed Successfully')}</span>
            </div>
        )
    }
    return (
        <div className={styles['follow']}>
            <div className={styles['follow__input-wrapper']}>
                <input id="followInput" value={email} onChange={(e) => setEmail(e.target.value)} className={styles['follow__input']} placeholder={t("Email address")}/>
                <div className={styles['follow__btn-wrapper']} onClick={() => doFollow()}>
                    <div className={styles['follow__btn']}>{t('OK')}</div>
                </div>
            </div>
        </div>
    )
}

